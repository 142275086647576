import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { CustomFormHandlerService } from '@app/common/services/custom-form-handler.service';
import { TOAST_STATUSES } from '@app/common/constants/util.constant';
import { ObservableHelperService } from '@app/common/services/observable-helper.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {

  @Output() state = new EventEmitter<any>();

  form: any;
  constructor(private formService: CustomFormHandlerService)  { }

  ngOnInit() {
    this.formService.resetPasswordFormInputsConfig.forEach(each => {
      if (each.isShowpassword) {
        each.isShowpassword = false;
        each.type = 'password';
        each.value = '';
      } else if (each.isShowpassword === false) {
        each.type = 'password';
      }
    });
    this.form = this.formService.initForm(this.formService.formTypes.RESETPASSWORD);
    this.form.formGroup.controls.reset.valueChanges.subscribe(
      x => this.form.formGroup.controls.confirm.updateValueAndValidity()
    );
  }

  btnClickEvent(event) {
    console.log(event);
    this.state.emit('login');
    this.toggleScroll();
  }
  toggleScroll() {
    document.body.style.overflow = 'scroll';
  }
}
