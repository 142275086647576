import { Component, OnInit, Input, Output, EventEmitter, HostListener, ChangeDetectorRef, SimpleChanges} from '@angular/core';

@Component({
  selector: 'app-step-indicator',
  templateUrl: './step-indicator.component.html',
  styleUrls: ['./step-indicator.component.scss']
})
export class StepIndicatorComponent implements OnInit {
  userLogedIn: any = false;



  @Output() stepIndexClicked: EventEmitter<any> = new EventEmitter();
  @Output() mobileStepIndexClicked: EventEmitter<any> = new EventEmitter();

  /**
   * step detail provide an array of step and there respective states
   * except data abstarct
   * --------------------
   * [
   *  {
   *     label:"some name",
   *     id:"some_id",
   *     visited:false,
   *     notVisisted:true,
   *     completed:false,
   *     onPage:false
   *   }
   * ]
   * -------------------- to find the example obj GOTO :-> new-patient-wrapper.component.ts
   */
  @Input() stepDetails;

  /**
   * Boolean to set the stepper in horizontal or
   * vertical UI
   */
  @Input() isHorizontal: boolean = true;

  @Input() set currentStepIndex(value: any) {
    this.stepIndicatorMobileIndex = value;
  }

  @Output() sendStepIndex: EventEmitter<any> = new EventEmitter();
  @Output() backClicked: EventEmitter<any> = new EventEmitter();
  @Output() forwardClicked: EventEmitter<any> = new EventEmitter();
  isMobile: boolean = false;
  stepIndicatorMobileIndex = 0;

  constructor(private cd: ChangeDetectorRef) {
    this.userLogedIn = JSON.parse(window.localStorage.getItem('isLogedIn'));
  }

  ngOnInit() {
    this.isMobile = window.innerWidth < 1024 ? true : false;
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.isMobile = window.innerWidth < 1024 ? true : false;
  }

  ngOnChanges(changes: SimpleChanges): void {
    // Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    // Add '${implements OnChanges}' to the class.
    console.log('child input', this.stepDetails);
  }

  setStepState(index) {
    if (this.stepDetails[index].onPage) {
      if (this.stepDetails[index + 1 >= this.stepDetails.length ? index : index + 1].visited) {
        return 'onpage';
      } else {
        return 'onpage no-right-line';
      }
    } else if (this.stepDetails[index].visited && !this.stepDetails[index].completed) {
      return 'visited';
    } else if (this.stepDetails[index].notVisisted) {
      return 'not_visited';
    } else if (this.stepDetails[index].completed) {
      return 'complete';
    }
    // this.cd.detectChanges();
  }

  stepClicked(index) {
    this.stepIndexClicked.emit(index);
  }

  getProgressWidth() {
    let singleUnit = (100 / this.stepDetails.length);
    return `${(this.stepIndicatorMobileIndex + 1) * singleUnit}%`;
  }

  back() {
    this.backClicked.emit();
  }

  forward() {
    this.forwardClicked.emit();
  }

}
