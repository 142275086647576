import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { Router, NavigationEnd } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class ObservableHelperService {
  isLogedIn = new BehaviorSubject(false);
  isLoading = new BehaviorSubject(false);
  // isHeavyLoading = new BehaviorSubject(false);
  documentClickedTarget: Subject<HTMLElement> = new Subject<HTMLElement>();
  isToastShown = new BehaviorSubject({ visibility: false });
  isConfirmationShown = new BehaviorSubject({ visibility: false });
  linkMapped = new Subject();
  showScrollToTop = new BehaviorSubject(false);
  currentPage = new BehaviorSubject('home');
  currentUrl: string;
  previousUrl: string;
  addPatientClicked = new BehaviorSubject(false);
  appointmentAddPatient = new BehaviorSubject(false);
  isShowBanner = new BehaviorSubject(true);
  generateInvoice = new BehaviorSubject(false);
  submitInvoice = new BehaviorSubject(true);
  constructor(private router: Router) {
    this.currentUrl = this.router.url;
    router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.previousUrl = this.currentUrl;
        this.currentUrl = event.url;
      }
    });
  }
  getPreviousUrl() {
    return this.previousUrl;
  }
  setBannerImage(isShow) {
    this.isShowBanner.next(isShow);
  }
  getBanerImage() {
    return this.isShowBanner;
  }
  setLogin(isAccess) {
    this.isLogedIn.next(isAccess);
  }

  toggleThrobber(visibility = false) {
    this.isLoading.next(visibility);
  }

  // toggleMessage(visibility = false) {
  //   this.isHeavyLoading.next(visibility);
  // }

  setAddPatientClicked(newPatient) {
    this.addPatientClicked.next(newPatient);
  }

  setAppointmentAddPatient(clicked) {
    this.appointmentAddPatient.next(clicked);
  }

  setInvoiceGenerate(isInvoice) {
    this.generateInvoice.next(isInvoice);
  }

  setInvoiceSubmit(isInvoice) {
    this.submitInvoice.next(isInvoice);
  }

  getInvoiceSubmit() {
    return this.submitInvoice;
  }

  getInvoiceGenerate() {
    return this.generateInvoice;
  }

  getAppointmentAddPatient() {
    return this.appointmentAddPatient;
  }

  getAddPatientClicked() {
    return this.addPatientClicked;
  }

  showConfirmation(status, data) {
    let visible = {
      visibility: true,
      status,
      data
    };
    this.isConfirmationShown.next(visible);
    setTimeout(this.hideConfirmation.bind(this), 10000);
  }

  hideConfirmation() {
    this.isConfirmationShown.next({ visibility: false });
  }

  showToast(status, data) {
    let visibilityDetails = {
      visibility: true,
      status,
      data
    };
    this.isToastShown.next(visibilityDetails);
    setTimeout(this.hideToast.bind(this), 7000);
    }

  getLoginValue() {
    return this.isLogedIn;
  }
  getThrobberVisibility() {
    return this.isLoading;
  }

  hideToast() {
    this.isToastShown.next({ visibility: false });
  }

  getConfirmationShown() {
    return this.isConfirmationShown;
  }
  getToastVisibility() {
    return this.isToastShown;
  }

  getHtmlElement(target) {
    this.documentClickedTarget.next(target);
  }

  // To handle link mapping response
  getLinkMapped() {
    return this.linkMapped;
  }

  setLinkMapped(data) {
    this.linkMapped.next(data);
  }

  isScrollToTopEnabled() {
    return this.showScrollToTop;
  }

  enableScrollToTop() {
    this.showScrollToTop.next(true);
  }

  checkRecentlyVisitedPage() {
    let previouslyVisited = localStorage.getItem('previouslyVisited');
    this.currentPage.next(previouslyVisited ? previouslyVisited : 'home');
  }

  setCurrentPage(page) {
    localStorage.setItem('previouslyVisited', page);
    this.currentPage.next(page);
  }

  getCurrentPage() {
    return this.currentPage;
  }

}
