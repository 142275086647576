import { Component, OnInit } from '@angular/core';
import { ObservableHelperService } from '@app/common/services/observable-helper.service';

@Component({
  selector: 'app-throbber',
  templateUrl: './throbber.component.html',
  styleUrls: ['./throbber.component.scss']
})
export class ThrobberComponent implements OnInit {
  showThrobber; // showMessage;
  constructor(private obsHelper: ObservableHelperService) { }

  ngOnInit() {
    this.obsHelper.isLoading.subscribe(val => {
      this.showThrobber = val;
    });
    // this.obsHelper.isHeavyLoading.subscribe(val => {
    //   this.showMessage = val;
    // });
  }

}
