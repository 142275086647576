import { Component, OnInit } from '@angular/core';
import { TOAST_STATUSES } from '@app/common/constants/util.constant';
import { ObservableHelperService } from '@app/common/services/observable-helper.service';

@Component({
  selector: 'app-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss']
})
export class ToastComponent implements OnInit {

  toastData = {
    visibility: false,
    status: '',
    data: ''
  };
  successSvg = 'slection';
  warningSvg = 'slection-warning';
  errorSvg = 'slection-error';
  statuses = TOAST_STATUSES;
  constructor(private observable: ObservableHelperService) { }

  ngOnInit() {
    this.observable.getToastVisibility().subscribe((data: any = { visibility: false }) => {
      this.toastData = data;
      console.log('toastData', this.toastData);
    });
  }

  addSVg(e) {
    let svgIcon;
    if (e.status === 'success') {
      svgIcon = this.successSvg;
    } else if (e.status === 'warning') {
      svgIcon = this.warningSvg;
    } else {
      svgIcon = this.errorSvg;
    }
    return {
      ...e, svg: svgIcon
    };
  }
  close(event) {
    this.toastData.visibility = event;
  }
}
