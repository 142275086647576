import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-empty-list-data',
  templateUrl: './empty-list-data.component.html',
  styleUrls: ['./empty-list-data.component.scss']
})
export class EmptyListDataComponent implements OnInit {
  // Empty messages data
  @Input() emptyMessageData: any = {
    className: '',
    icon: '',
    message: ''
  };

  constructor() { }

  ngOnInit() {
  }

}
