import { Component, OnInit, Input, EventEmitter, Output, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CognitoService } from '@app/common/services/cognito.service';
import { Validators, FormControl, FormBuilder, FormGroup } from '@angular/forms';
import { CustomFormHandlerService } from '@app/common/services/custom-form-handler.service';
import { HttpService } from '@app/common/services/http.service';
import { ObservableHelperService } from '@app/common/services/observable-helper.service';
import { ViewChild } from '@angular/core';
import { TOAST_STATUSES } from '@app/common/constants/util.constant';

@Component({
  selector: 'app-multiple-uploads',
  templateUrl: './multiple-uploads.component.html',
  styleUrls: ['./multiple-uploads.component.scss']
})
export class MultipleUploadsComponent implements OnInit {

  // Dates validation message
  datesValidationState = false;
  filterFormControls;
  doctorList: any;
  treatmentList: any;
  locationList: any;
  clearCheckBoxes = false;
  filterClear = false;
  setValuesToForm = [];
  formGroup: any;
  formInputs: any;
  formSubmitAttempt = false;
  patientName: any;
  patientDOB: any;
  uploadFormType: any;
  @ViewChild('fileUpload', { static: false })
  myInputVariable: ElementRef;
  @Output() uploads: EventEmitter<any> = new EventEmitter();
  userDetails: any;
  uploadCount: any;
  @Input() set fileTypes(value: any) {
    this.patientName = value.patientName;
    this.patientDOB = value.patientDOB;
    this.uploadFormType = value.uploadFormType;
    this.uploadCount = value.updatedFilesCount;
    //this.extType = value.extType ? value.extType : null;
  }
  //extType: any;

  constructor(private formBuilder: FormBuilder, private formService: CustomFormHandlerService, private HelperService: ObservableHelperService, private http: HttpService, private cognitoService: CognitoService) { }

  // This will do form submission
  ngOnInit() {
    this.initForm(this.formService.uploadConfig);
    this.formInputs = this.formService.uploadConfig;
    this.cognitoService.getUser().then(data => {
      this.userDetails = data;
    });
  }

  focusFunction(event) {

  }
  focusOutFunction(event) {

  }
  imageUpload(event, field, i) {
    let fileSplit: any;
    fileSplit = event.target.files && event.target.files[0] ? event.target.files[0]['name'].split('.')[0] : null;
    // tslint:disable-next-line:whitespace
    let regex: any =/[!@#$%^&*()+\-=\[\]{};':"\\|,.`~<>\/?]+/;
    let fileNameContainsSpecialChar = regex.test(fileSplit);
    if (fileSplit && fileNameContainsSpecialChar) {
      this.HelperService.showToast(TOAST_STATUSES.ERROR, 'Filename should not contain special characters. Please upload with a different name.');
      this.formGroup.controls[field.key].value = '';
      this.formGroup.controls[field.key].updateValueAndValidity();
    } else {
      field.showImage = false;
      let fileType = '';
      if (event.target.files && event.target.files[0]) {
        const reader = new FileReader();
        var file = event.target.files[0];
        var fileName = file.name.replace(/\s/g, '');
        let splitFilename = fileName.split('.');
        fileType = splitFilename.pop().toLowerCase();
        splitFilename[0] = this.accentedtoNormalFun(splitFilename[0]);
        var uploadFileName = splitFilename[0].replace(/[^a-zA-Z0-9_-]/g, '') +  '.' + fileType;
        reader.readAsDataURL(file);
        reader.onload = () => {
          field.value = file;
        };
        try {

          if (this.formGroup.controls[field.key].valid) {
            let uploadfilesCount = this.uploadCount + 1;
            let fileKey = 'upload' + uploadfilesCount;
            let type;
            if (!file.type) {
              type = fileType;
            }
            let getUploadUrls = {
              type: 'POST',
              url: 'getUploadUrl',
              isDeveloper: true,
              body: {
                fileNames: [{ key: fileKey, name: uploadFileName, contentType: file.type || type }],
                userId: this.userDetails.userId,
                userName: `temp-new-tele-doc/${this.patientName && this.patientName !== '' ? this.accentedtoNormalFun(this.patientName) : ''}`,
                patientName: `${this.patientName && this.patientName !== '' ? this.accentedtoNormalFun(this.patientName) + '__' : ''}${this.patientDOB && this.patientDOB !== '' ? this.patientDOB : ''}/uploads`
              },
            };
            this.http.makeHttpRequest(getUploadUrls).subscribe(res => {
              let uploadPayload = {
                type: 'PUT',
                url: res[fileKey],
                body: file,
                isExternal: true,
                contentType: file.type || type
              };
              this.http.makeHttpRequest(uploadPayload).subscribe(response => {
                field.value = res[fileKey];
                this.uploadCount = this.uploadCount + 1;
                this.uploads.emit(field.value);
                this.myInputVariable.nativeElement.value = '';
              });

            });
          } else {

          }
        } catch (err) {
          field.value = '';
        }
      }
    }
  }
  initForm(formType) {
    // LoginForm
    this.formGroup = this.toReactiveForm(formType);
    this.formInputs = formType;
  }
  /**
   * this function is used to check error for a given input control
   * @param control {FormControl} - form control to be validated
   * @param formSubmitted {boolean} - to validate the form on form submission
   */
  isInvalid(control, formSubmitted) {
    return ((control.invalid && formSubmitted && (control.touched || control.dirty)) ||
      (formSubmitted && control.invalid)
    );
  }

  /**
  * To convert form data to reactive form
  // tslint:disable-next-line: jsdoc-format
  * @param inputs  - array of input fields
  */
  toReactiveForm(inputs: Array<{}>) {
    let group = {};
    Array.prototype.forEach.call(inputs, (input, i) => {
      if (input.type !== 'header') {
        if (input.controlType === 'formGroup') {
          group[input.key] = this.toReactiveForm(input.formInputs);
        } else if (input.controlType === 'submitButton') {
          // Don't add to form Group
        } else {
          group[input.key] = [(input.value === undefined || input.value === null) ? '' : input.value, input.validators];
        }
      }
    });
    return this.formBuilder.group(group);
  }
  taskSelect(data) {

  }
  /* for accented letter change convention function */
  accentedtoNormalFun(name) {
    let originalText = name;
    let result = originalText.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
    return result;
  }
}
