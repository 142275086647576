import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { DataService } from '@app/common/services/data.service';
import { ObservableHelperService } from '@app/common/services/observable-helper.service';
import { ROUTE_PATHS } from '@app/common/constants/routing.constant';
import { TOAST_STATUSES, appTypeEnums, locationIdEnums, HTTPMethods } from '@app/common/constants/util.constant';
import { IPatientBlockedTreatments } from '@app/common/constants/typeInterfaces.constants';
import { TOAST_MESSAGES } from '@app/common/constants/toast-messages.constant';
import { HttpService } from '@app/common/services/http.service';

@Component({
  selector: 'app-patient-card',
  templateUrl: './patient-card.component.html',
  styleUrls: ['./patient-card.component.scss']
})
export class PatientCardComponent implements OnInit {
  @Input() patientDetails;
  @Input() patientTotalLocations;
  showModify = false;
  makeAsPaidPopupShowHide: boolean = false;
  isTMS: boolean = true;
  displayDocumentsRequestPopup: boolean = false;
  displayDocumentsRequest: boolean = false;
  @ViewChild('op', { static: false }) overlay;
  pendingTherapistReq: boolean = false;
  blockedAllServices: boolean = false;
  documentsRequest: any[];
  patientId: any;
  blockBookNow: boolean = false;
  constructor(private router: Router, private dataService: DataService, private observableHelperService: ObservableHelperService, private http: HttpService,) { }

  ngOnInit() {
    // this.getDuration(this.patientDetails.lastUpdate);
    if(this.patientDetails){
      this.patientDetails['lastAction'] = this.massageLastAction(this.patientDetails['lastAction']);
      this.checkTherapistInfoRequest();
      this.patientDetails.pendingDocumentRequest.forEach(perm => {
        if (perm.documentIds == "[23]") {
          this.blockBookNow = true;
        }
        else{
          this.blockBookNow = false;
        }
        });
      this.blockedAllServices = this.patientDetails.blockedServices.length >= this.patientTotalLocations;
    }
    // this.patientDetails['disable'] = this.sixMonthPatient(this.patientDetails);
    // this.patientDetails['longTimeUpdate'] = true;
    // console.log(this.patientDetails);
  }
  sixMonthPatient(patient) {
    if (patient && typeof (patient) === 'object') {
      let lastUpdated = new Date(patient.lastUpdate);
      let lastSixMonthDate = this.addMonths(new Date(), -6); // six months before now
      if (lastUpdated <= lastSixMonthDate) {
        return true;
      } else {
        return false;
      }
    }
  }
  closeDocumentRequestPopup(status) {
    this.displayDocumentsRequest = status.isPopup;
  }
  documentsRequestPopupStatusFun(status) {
    this.displayDocumentsRequest = status.cancelpopup;
  }
  docuementRequest(){
    this.getDocumentRequest();
  }
  getDocumentRequest() {
    this.documentsRequest = [];
    const payloadData = {
      type: HTTPMethods.GET,
      url: 'patientRequestDocuments',
      isDeveloper: true,
      pathVariables: ['DOCUMENT_REQUEST']
    };
    this.http.makeHttpRequest(payloadData).subscribe((res) => {
      if (res) {
        this.documentsRequest = res;
        this.patientId =this.patientDetails.patientId;
        this.displayDocumentsRequest = true;
      }
    });
  }
  addMonths(date, months) {
    date.setMonth(date.getMonth() + months);
    return date;
  }
  massageLastAction(data) {
    let action = data;
    switch (action) {
      case 'CREATE':
        action = 'Appt. Created';
        break;
      case 'RESCHEDULE':
        action = 'Appt. Rescheduled';
        break;
      case 'CANCEL':
        action = 'Appt. Canceled';
        break;
      case 'Create Patient':
        action = 'Patient Created';
        break;
      case 'Update Patient':
        action = 'Updated Details';
        break;
      default: {
        action = '--';
        break;
      }
    }
    return action;
  }
  viewOrEditMode(id, mode) {
    if (mode === 'view' && !this.patientDetails.isInactive && !this.patientDetails.longTimeUpdate) {
      this.router.navigate([`/new-patient`], { queryParams: { id, view: true } });
    } else if (mode === 'edit' && this.patientDetails.longTimeUpdate  && this.patientDetails.isInactive) {
      // Both 2 years and 8 months update are required.
      this.router.navigate([`/${ROUTE_PATHS.NEW_PATIENT}`], { queryParams: { id, updateProfile: true } });
    } else if (mode === 'edit' && this.patientDetails.isInactive) {
      // 8 months update.
      this.router.navigate([`/${ROUTE_PATHS.NEW_PATIENT}`], { queryParams: { id, modifyProfile: true} });
    } else if (mode === 'edit' && this.patientDetails.longTimeUpdate) {
      this.router.navigate([`/new-patient`], { queryParams: { id, modify: true } });
    } else if (mode === 'edit' && !this.patientDetails.longTimeUpdate) {
      this.router.navigate([`/new-patient`], { queryParams: { id, edit: true } });
    } else {
      this.router.navigate([`/new-patient`], { queryParams: { id, update: true } });
    }
  }

  bookAppointment(patient) {
    if(!this.patientDetails.patientStateValid){
      this.observableHelperService.showToast(TOAST_STATUSES.ERROR, TOAST_MESSAGES.VALID_STATE);
    }
    if (patient) {
      if (!patient.disable) {
        this.dataService.patientDetails = patient.patientId;
        this.router.navigate([`/appointment`]);
      } else {
        this.observableHelperService.showToast(TOAST_STATUSES.WARNING, 'Hello, please update your patient form before booking a new appointment.');
      }

    }
  }
  // getting duration based on time - need to enable whenever this function is useful rightnow this is taken care from backend
  /* getDuration(updtdTime) {
    updtdTime = new Date(updtdTime);
    let ynew = new Date().getFullYear();
    let mnew = new Date().getMonth();
    let dnew = new Date().getDate();
    let yold = updtdTime.getFullYear();
    let mold = updtdTime.getMonth();
    let dold = updtdTime.getDate();
    let diff = ynew - yold;
    if (mold > mnew) { diff--; } else {
      if (mold === mnew) {
        if (dold > dnew) { diff--; }
      }
    }
    if (diff >= 2) {
      this.showModify = true;
    }
  } */
  // displaying popup when no is delected
  patientUpdateDetails() {
    this.makeAsPaidPopupShowHide = true;
  }
  // popup yes/no buttons
  popUpClick(id: any, status: boolean) {
    this.makeAsPaidPopupShowHide = false;
    // tslint:disable-next-line:no-unused-expression
    status ? this.updateCurrentDate(id) : null;
  }
  // This is closing for make as paid popup
  makeAsPaidPopupShowHideStatusFun(status) {
    this.makeAsPaidPopupShowHide = status.cancelpopup;
  }
  // updating the current date to patient last update
  updateCurrentDate(id) {
    console.log(id);
  }

  // for navigating to TMS Forms
  navigateTmsForms(tmsPending) {
    if (tmsPending) {
      this.router.navigate([`${ROUTE_PATHS.APPOINTMENT}/pending/${ROUTE_PATHS.TMS_FORMS}/${this.patientDetails.patientId}`]);
    } else {
      this.router.navigate([`${ROUTE_PATHS.APPOINTMENT}/${ROUTE_PATHS.TMS_FORMS}/${this.patientDetails.patientId}`]);
    }
  }
  // For navigating request details submition page
  navigateDocumentReqestPage(patientData) {
    this.router.navigate([`${ROUTE_PATHS.APPOINTMENT}/${ROUTE_PATHS.REQUEST_DOCUMENT_DETAILS}/${patientData.pendingDocumentRequest[patientData.pendingDocumentRequest.length - 1].requestId}`]);
  }

  // For displaying document request popup
  multipleDocumentRequestPopup(status) {
    this.displayDocumentsRequestPopup = status.cancelpopup;
  }

  // for viewing multiple request popup
  viewMoreRequestPopup() {
    this.displayDocumentsRequestPopup = true;
  }

  // Navigating to the patient form for editing the pharmacy details
  navigatePatientForm(patientData: Record<string, any>, type: string) {
    if (type === 'pharmacy') {
      this.router.navigate([`${ROUTE_PATHS.NEW_PATIENT}`], { queryParams: { id: patientData.patientId, edit: true, isPharmacy: true } });
    } else {
      this.router.navigate([`${ROUTE_PATHS.NEW_PATIENT}`], { queryParams: { id: patientData.patientId, edit: true, isInsurance: true } });
    }
  }

  /**
   * 
   * @param patientData :Selected patient data
   */
  navigateADHDQuestionnaire(patientData){
    this.router.navigate([ROUTE_PATHS.APPOINTMENT,ROUTE_PATHS.ADHD_QUESTIONNAIRE],{queryParams:{requestId:this.patientDetails.pendingDocumentRequest[0].requestId}});
  }
  navigatePHQQuestionnaire(patientData){
    this.router.navigate([ROUTE_PATHS.APPOINTMENT,ROUTE_PATHS.PHQ_QUESTIONNAIRE],{queryParams:{requestId:this.patientDetails.pendingDocumentRequest[0].requestId}});
  }

  moreDisplay(e, op) {
    op.show(e);
  }

  navigateToTherapistInfoRequest(patientData){
    this.router.navigate([ROUTE_PATHS.APPOINTMENT,ROUTE_PATHS.REQUEST_THERAPIST_DETAILS],{queryParams:{requestId:this.patientDetails.pendingDocumentRequest[0].requestId}});
  }
  navigateToINFNAPPT(patientData){
    this.router.navigate([ROUTE_PATHS.APPOINTMENT,ROUTE_PATHS.INFORMATION_REQUEST],{queryParams:{requestId:this.patientDetails.pendingDocumentRequest[0].requestId}});
  }

  /**
   * check if the patient has therapist info request and block booking option.
   */
  checkTherapistInfoRequest(){
    this.patientDetails.pendingDocumentRequest.forEach(element => {
      if(element.documentIds == '[9]'){
        this.pendingTherapistReq = true;
      }
    });
  }

}
