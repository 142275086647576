import { Injectable } from '@angular/core';
import { Auth } from 'aws-amplify';
import Amplify from 'aws-amplify';
import { Router } from '@angular/router';
import { poolData } from '@src/environments/environment';
import { ObservableHelperService } from '@app/common/services/observable-helper.service';
import { HTTPMethods, TOAST_STATUSES } from '@app/common/constants/util.constant';
import * as AWS from 'aws-sdk';
import { TOAST_MESSAGES } from '@app/common/constants/toast-messages.constant';
import { ResendConfirmationCodeRequest } from 'aws-sdk/clients/cognitoidentityserviceprovider';
import { ROUTE_PATHS } from '../constants/routing.constant';
import { API_URLS } from '../constants/api-urls.constant';



Amplify.configure({
    Auth: {

        // REQUIRED - Amazon Cognito Region
        // region: 'XX-XXXX-X',

        // OPTIONAL - Amazon Cognito User Pool ID
        userPoolId: poolData.UserPoolId,

        // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
        userPoolWebClientId: poolData.ClientId

        // cookieStorage: {
        //     // OPTIONAL - Cookie expiration in days
        //     expires: 1
        // }
    }
});

@Injectable({
    providedIn: 'root'
})
export class CognitoService {
    username;
    password;
    user: any;
    constructor(
        private router: Router,
        private observableHelperService: ObservableHelperService,
    ) {
    }


    async signUp(signUpData: any, callBack) {
        let username = signUpData.emailId;
        let email = signUpData.emailId;
        let password = signUpData.newPassword;
        let firstname = signUpData.firstName;
        let lastname = signUpData.lastName;
        try {
            this.observableHelperService.toggleThrobber(true);

            const user = await Auth.signUp({
                username,
                password,
                attributes: {
                    email,
                    'custom:firstname': firstname,
                    'custom:lastname': lastname,
                }
            });
            callBack();
            this.observableHelperService.toggleThrobber(false);
            this.observableHelperService.showToast(TOAST_STATUSES.SUCCESS, 'Registered successfully and Verification link to sent to your mail');

            console.log({ user });
        } catch (error) {
            console.log('error signing up:', error);
            this.observableHelperService.toggleThrobber(false);
            let errorMsg = error && error.code === 'UsernameExistsException' ? error.message : 'Failed to Register';
            this.observableHelperService.showToast(TOAST_STATUSES.ERROR, errorMsg);

        }
    }

    async confirmSignUp(data, callback) {
        try {
            this.observableHelperService.toggleThrobber(true);

            await Auth.confirmSignUp(data.username.toLowerCase(), data.code);
            callback();
            this.observableHelperService.toggleThrobber(false);

            this.observableHelperService.showToast(TOAST_STATUSES.SUCCESS, 'Successfully verified.Login to continue');
        } catch (error) {
            this.observableHelperService.toggleThrobber(false);

            console.log('error confirming sign up', error);
        }
    }


    async SignIn(signInData, callback, emailVerify?) {
        try {
            let username = signInData.emailId.toLowerCase();
            let password = signInData.password;
            const user = await Auth.signIn(username, password);
            callback(user);
            console.log('loggin details', user);
        } catch (error) {
            this.observableHelperService.toggleThrobber(false);

            if (error.code === 'NotAuthorizedException') {
                if (emailVerify) {
                    this.observableHelperService.showToast(TOAST_STATUSES.ERROR, 'You\'re existing user to New Tele Doc. Please login..');
                    this.router.navigate(['login']);

                } else {
                    if (error.message.includes('disabled')) {
                        this.observableHelperService.showToast(TOAST_STATUSES.ERROR, 'We are facing some issues with your account. Please contact NewTele Doc team for more details');
                    } else {
                        this.observableHelperService.showToast(TOAST_STATUSES.ERROR, 'Username or Password is incorrect');
                    }
                }
            } else if (error.code === 'UserNotConfirmedException') {
                this.observableHelperService.showToast(TOAST_STATUSES.ERROR, 'Please verify your email ');
            } else {
                this.observableHelperService.showToast(TOAST_STATUSES.ERROR, 'Falied to Login');
            }
            console.log('error signing in', error);
        }
    }


    async signOut(callback) {
        try {
            await Auth.signOut();
            this.user = '';
            this.username = '';
            this.password = '';
            callback();
        } catch (error) {
            console.log('error signing out: ', error);
            this.observableHelperService.showToast(TOAST_STATUSES.ERROR, 'Falied to Logout');

        }
    }


    async forgotPasswordSubmit(data, callback) {
        try {
            this.observableHelperService.toggleThrobber(true);

            const userData = await Auth.forgotPasswordSubmit(data.userName, data.code, data.newConfirmPassword);
            console.log(userData);
            this.observableHelperService.showToast(TOAST_STATUSES.SUCCESS, 'Successfully set a new password. Try login.');
            callback();
            this.observableHelperService.toggleThrobber(false);

        } catch (error) {
            if (error.code === 'InvalidLambdaResponseException') {
                this.observableHelperService.showToast(TOAST_STATUSES.SUCCESS, 'Successfully set a new password. Try login.');

                callback();
            } else {
                this.observableHelperService.showToast(TOAST_STATUSES.ERROR, 'Invalid Link');

            }
            this.observableHelperService.toggleThrobber(false);

            console.log('error sending code: ', error);
        }
    }


    async changePassword(data, callback, msg?) {
        if (data.oldPassword === data.newPassword) {
            this.observableHelperService.showToast(TOAST_STATUSES.ERROR, 'Old password and New password can\'t be same');
            return false;
        }
        try {
            this.observableHelperService.toggleThrobber(true);

            let user = await Auth.currentAuthenticatedUser();
            await Auth.changePassword(user, data.oldPassword, data.newPassword);
            this.observableHelperService.toggleThrobber(false);

            this.observableHelperService.showToast(TOAST_STATUSES.SUCCESS, msg ? msg : 'Password changed Successfully');
            callback();
        } catch (error) {
            this.observableHelperService.showToast(TOAST_STATUSES.ERROR, error.message);

            this.observableHelperService.toggleThrobber(false);

        }
    }


    async getUser() {
        try {
            if (!(this.user && this.user.userId)) {
                let data = await Auth.currentAuthenticatedUser();
                console.log(data, 'user details');
                this.user = {
                    firstName: data.attributes['custom:firstname'],
                    lastName: data.attributes['custom:lastname'],
                    email: data.attributes['email'],
                    userId: data.attributes['sub'],
                    admin: (data.attributes['custom:admin']) ? Number(data.attributes['custom:admin']) : 0
                };
            }
            if (!(this.user.lastName && this.user.firstName)) {
                if (JSON.parse(window.localStorage.getItem('isLogedIn'))) {
                    this.router.navigate(['profile']);
                }
            }
            return this.user;
        } catch (error) {
            return error;
        }

    }

    async confirmEmailChange(data, callback) {
        try {
            let res = await Auth.verifyCurrentUserAttributeSubmit('email', data);
            callback();
            this.observableHelperService.showToast(TOAST_STATUSES.SUCCESS, 'Your email is verified successfully');
        } catch (err) {
            console.log(err);
        }
    }



    async updateUser(userDetails, callback) {
        try {
            this.observableHelperService.toggleThrobber(true);

            let user = await Auth.currentAuthenticatedUser();
            await Auth.updateUserAttributes(user, {
                email: userDetails.email,
                'custom:firstname': userDetails.firstName,
                'custom:lastname': userDetails.lastName
            });
            this.observableHelperService.toggleThrobber(false);
            callback();
            if (this.user.email !== userDetails.email) {
                await Auth.signOut();
                window.localStorage.setItem('isLogedIn', JSON.stringify(false));
                this.router.navigate(['login']);
                this.user = '';
                this.username = '';
                this.password = '';
                this.observableHelperService.showToast(TOAST_STATUSES.SUCCESS, 'Updated your profile. Please login after email is verified');

            } else {
                this.observableHelperService.showToast(TOAST_STATUSES.SUCCESS, 'Successfully updated your profile');

                this.user.firstName = userDetails.firstName;
                this.user.lastName = userDetails.lastName;
            }


        } catch (error) {
            this.observableHelperService.toggleThrobber(false);
            if (error.code === 'AliasExistsException') {
                this.observableHelperService.showToast(TOAST_STATUSES.ERROR, error.message);

            } else {
                this.observableHelperService.showToast(TOAST_STATUSES.ERROR, 'Unable to update user');
            }


        }
    }
    getPassword() {
        var pass = '';
        var str = 'abcdefghijklmnopqrstuvwxyz';
        var cap = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
        var num = '0123456789';
        var splchar = '@*$';
        let i;
        for (i = 1; i <= 5; i++) {
            var char = Math.floor(Math.random()
                * str.length);

            pass += str.charAt(char);
        }
        char = Math.floor(Math.random()
            * cap.length);
        pass += cap.charAt(char);
        char = Math.floor(Math.random()
            * num.length);
        pass += num.charAt(char);
        char = Math.floor(Math.random()
            * splchar.length);
        pass += splchar.charAt(char);

        return pass;
    }


    async resendEmaiVerification(callback) {
        Auth.verifyCurrentUserAttribute('email').then((res) => {
            callback(true);
        }).catch(err => {
            callback(false, err.message);
          });
    }


}
