import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'customNumber'
})
export class CustomNumberPipe implements PipeTransform {

  transform(value: any): any {
    if (typeof (value) === 'string' && value.indexOf('%') !== -1) {
      var numbers = value.split('%')[0];
      // tslint:disable-next-line: radix
      if (numbers !== '0' && parseInt(numbers) < 10) {
        return numbers = '0' + numbers + '%';
      } else {
        return numbers + '%';
      }
    } else if (typeof (value) === 'string' && value.indexOf('%') === -1) {
      // tslint:disable-next-line: radix
      if (value !== '0' && parseInt(value) < 10) {
        return value = '0' + value;
      } else {
        return value;
      }
    } else {
      if (value !== '0' && value < 10) {
        return value = '0' + value;
      } else {
        return value;
      }
    }
  }

}
