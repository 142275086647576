import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { ROUTE_PATHS } from '@app/common/constants/routing.constant';
import { CognitoService } from '@app/common/services/cognito.service';
import { HttpService } from '@app/common/services/http.service';
import { ObservableHelperService } from '@app/common/services/observable-helper.service';

@Component({
  selector: 'app-multiple-document-request-view',
  templateUrl: './multiple-document-request-view.component.html',
  styleUrls: ['./multiple-document-request-view.component.scss']
})
export class MultipleDocumentRequestViewComponent implements OnInit {
  @Output() showHideMyAccountPopup = new EventEmitter();
  requestList: any = [];
  @Input() set requestDocumentsList(value: any) {
    if (value && Array.isArray(value)) {
      this.requestList = value;
    }
  }
  showHideMyAccountPopupStatus: boolean = true;
  constructor(private router: Router, private observableHelperService: ObservableHelperService, private http: HttpService) { }

  ngOnInit() {
  }

  // show/hide the the document request popup
  showHideMyAccountPopupStatusChange() {
    this.showHideMyAccountPopupStatus = !this.showHideMyAccountPopupStatus;
    this.showHideMyAccountPopup.emit(this.showHideMyAccountPopupStatus);
  }

  // Redirecting to document request details page
  viewDocumentDetailsPage(card) {
    this.showHideMyAccountPopupStatusChange();
    if (card.documentIds == '[1]') {
      this.router.navigate([ROUTE_PATHS.APPOINTMENT, ROUTE_PATHS.ADHD_QUESTIONNAIRE], { queryParams: { requestId: card.requestId } });
    } else if (card.documentIds == '[9]') {
      this.router.navigate([ROUTE_PATHS.APPOINTMENT, ROUTE_PATHS.REQUEST_THERAPIST_DETAILS], { queryParams: { requestId: card.requestId } });
    } else if (card.documentIds == '[14]') {
      this.router.navigate([ROUTE_PATHS.APPOINTMENT, ROUTE_PATHS.MEDICATION_FEEDBACK], { queryParams: { requestId: card.requestId } });
    } else if (card.documentIds == '[15]') {
      this.router.navigate([ROUTE_PATHS.APPOINTMENT, ROUTE_PATHS.INFORMATION_REQUEST], { queryParams: { requestId: card.requestId, } });
    } else if (card.documentIds == '[16]') {
      this.router.navigate([ROUTE_PATHS.APPOINTMENT, ROUTE_PATHS.PHQ_QUESTIONNAIRE], { queryParams: { requestId: card.requestId } });
    } 
    else if (card.documentIds == '[23]') {
      this.router.navigate([ROUTE_PATHS.APPOINTMENT, ROUTE_PATHS.Subsentence_Wrapper], { queryParams: { requestId: card.requestId } });
    }
    else {
      this.router.navigate([`${ROUTE_PATHS.APPOINTMENT}/${ROUTE_PATHS.REQUEST_DOCUMENT_DETAILS}/${card.requestId}`]);
    }
  }
}
