import { Component, Input, OnInit } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { filter } from 'rxjs/operators';
import { ObservableHelperService } from '@app/common/services/observable-helper.service';
@Component({
  selector: 'app-common-footer',
  templateUrl: './common-footer.component.html',
  styleUrls: ['./common-footer.component.scss']
})
export class CommonFooterComponent implements OnInit {
  headerNavItems = [
    {
      label: 'SERVICES',
      id: 'services',
      selected: false
    },
    {
      label: 'TREATMENTS',
      id: 'treatments',
      selected: false
    },
    {
      label: 'ABOUT US',
      id: 'aboutus',
      selected: false
    },
    {
      label: 'WRITE TO US',
      id: 'write_to_us',
      selected: false
    },
    {
      label: 'BLOG',
      id: 'blog',
      selected: false
    },
    {
      label: 'FAQS',
      id: 'faqs',
      selected: false
    },
    {
      label: 'PRIVACY POLICY',
      id: 'privacy',
      selected: false
    },
    {
      label: 'TERMS & CONDITIONS',
      id: 'terms',
      selected: false
    }
  ];
  cancelPopupShowHide: boolean = false;
  @Input() footerShow = true;
  constructor(private router: Router, private observable: ObservableHelperService) { }

  ngOnInit() {
  }

  footerLinkClicked(i) {
    this.observable.setCurrentPage(this.headerNavItems[i].id);
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
    if (this.headerNavItems[i].id === 'services') {
      this.router.navigate(['services']);
    } else if (this.headerNavItems[i].id === 'treatments') {
      this.router.navigate(['treatments']);
    } else if (this.headerNavItems[i].id === 'aboutus') {
      this.router.navigate(['aboutus']);
    } else if (this.headerNavItems[i].id === 'blog') {
      window.location.href = 'https://blogqa.newteledoc.com';
    } else if (this.headerNavItems[i].id === 'faqs') {
      this.router.navigate(['faqs']);
    } else if (this.headerNavItems[i].id === 'privacy') {
      this.router.navigate(['privacy']);
    } else if (this.headerNavItems[i].id === 'terms') {
      this.router.navigate(['terms']);
    } else if (this.headerNavItems[i].id === 'write_to_us') {
      this.cancelPopupShowHide = true;
    } else {
      this.router.navigate(['coming-soon']);
    }

  }
  cancelPopupShowHideStatusFun(status) {
    this.cancelPopupShowHide = status.cancelpopup;
  }
  ngAfterViewChecked(): void {
    // Called after every check of the component's view. Applies to components only.
    // Add 'implements AfterViewChecked' to the class.
    if (this.cancelPopupShowHide) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }
}
