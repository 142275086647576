import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'customTime'
})
export class CustomTimePipe implements PipeTransform {

  transform(value: any, ...args: any[]): any {
    let from;
    from = value.split(':');
    if (from[0] < 12) {
      // tslint:disable-next-line: radix
      return `${parseInt(from[0])}:${from[1]} AM`;
    } else {
      if (from[0] === '12') {
        return `${from[0]}:${from[1]} PM`;
      } else {
        return `${from[0] - 12}:${from[1]} PM`;
      }
    }
  }
}
