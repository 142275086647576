import { Component, OnInit, Input, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-svg',
  templateUrl: './svg.component.html',
  styleUrls: ['./svg.component.scss']
})
export class SvgComponent implements OnInit {

  @Input() name: string;
  @Input() title: string;
  @Input() imageLabel:string;

  xname: string;
  imagePath: string;
  circleDesc: string = "A red circle";
  constructor() { }

  ngOnInit() {
    this.xname = '#' + this.name;
    this.imagePath = this.imageLabel;
  }

  ngOnChanges(changes: SimpleChanges) {
    this.xname = '#' + changes.name.currentValue;
  }

}
