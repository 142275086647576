import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ScrollServiceService {

  constructor() { }
    private scrollSubject: Subject<string> = new Subject<string>();
  
    scrollToSection(sectionId: string) {
      console.log("service")
      this.scrollSubject.next(sectionId);
    }
  
    getScrollSubject() {
      return this.scrollSubject.asObservable();
    }
  
}
