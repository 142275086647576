import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'dateFormat'
})
export class DateFormatPipe implements PipeTransform {
    months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

    constructor() { }
    transform(date: any): any {
        if (date) {
            date = new Date(date);
            let suffix = 'th';
            let day = date.getUTCDate().toLocaleString();
            if (day === '1' || day === '21' || day === '31') {
                suffix = 'st';
            } else if (day === '2' || day === '22') {
                suffix = 'nd';
            } else if (day === '3' || day === '23') {
                suffix = 'rd';
            }
            return ` ${this.months[date.getUTCMonth()]} ${day}, ${date.getUTCFullYear()}`;
        } else {
            return '---';
        }
    }
}
