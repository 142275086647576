import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { CustomFormHandlerService } from '@app/common/services/custom-form-handler.service';
import { Validators, FormControl, FormBuilder, FormGroup } from '@angular/forms';
import { HttpService } from '@app/common/services/http.service';
import { CognitoService } from '@app/common/services/cognito.service';
import { FaLayersCounterComponent } from '@fortawesome/angular-fontawesome';
import { ObservableHelperService } from '@app/common/services/observable-helper.service';
import { stateDropDownList, TOAST_STATUSES, validationState } from '@app/common/constants/util.constant';
import { formatDate } from '@angular/common';
import { each, event } from 'jquery';
import { ActivatedRoute, Router } from '@angular/router';
import { FORM_KEYS, KETAMINE_ENROLLMENT_KEYS, TMS_CONSENT_FORM, KETAMINE_CONSENT_FORM, REQUEST_THERAPIST_DETAILS_KEYS, INTAKE_FORM_KEYS, MM_CONSENT_FORM } from '../../../common/constants/form-keys.constant';
import { FileUpload } from 'primeng';
import { TOAST_MESSAGES } from '@app/common/constants/toast-messages.constant';


@Component({
  selector: 'app-custom-form',
  templateUrl: './custom-form.component.html',
  styleUrls: ['./custom-form.component.scss']
})
export class CustomFormComponent implements OnInit {
  faRate: any;
  // tslint:disable-next-line: ban-types
  valu: Number;
  formInputs: any = [];
  formGroup: any = new FormGroup({});
  formconfig: any = {};
  formName: any;
  tableName: any;
  fileName: any = '';
  file: any = null;
  active = false;
  submitted = false;
  checkedList: any = [];
  validation = true;
  myDate: any;
  addNewIndex: any = 0;
  formTags: any = ['BASICINFO', 'INSURANCEDETAIL', 'MEDICALHISTORY', 'MISCELLAENOUS', 'NEWPATIENTSUBMIT', 'MEDICAL_SUMMARY','TMS_NOTES'];
  form: any = [];
  psyciatricConfigArray = [];
  showImage = true;
  showPassword = false;
  imageUrl: string | ArrayBuffer;
  resetCalenderInput = false;
  profileUrl: any;
  isProfileImg = true;
  // tslint:disable-next-line: ban-types
  isSelected: Boolean = true;
  formPreviousValidState = false;
  fieldIndex: any;
  editViewFormData: any;
  monthSelector: any;
  yearSelector: any;
  minDOB = new Date();
  isBlur: boolean = false;
  fileToUpload: File = null;
  selectedAutoSearchItem: any;
  uploadFilesData = {
    patientName: '',
    patientDOB: '',
    updatedFilesCount: 0,
  };
  uploadsDocumentsList = [];
  forceFieldBlur = false;
  integerValueFields: Array<string> = ['heightFt', 'heightInch','weight']
  @Input() filteredSearchList: any[];
  uploadedFiles: Array<any> = [];
  documentUrls: Record<string,Array<any>> = {};
  zipCodeRange: any[];
  newControlCounter:any = 0;
  // --inputs
  @Input() set formData(value: any) {
    if (value) {
      this.formGroup = value.formGroup;
      this.formInputs = value.formInputs;
      this.formName = value.formName;
      this.formconfig = {
        formData: this.formGroup,
        formInputs: this.formInputs
      };
      this.setDataForUpload();
    }
  }

  @Input() set checkFormInfo(value: any) {
    if (value) {
      this.actionField();
    }
  }
  @Input() set editViewData(value: any) {
    if (value) {
      this.setEditViewData(value);
    }
  }
  @Input() adminTypeForm;


  // --outputs
  @Output() submitEvent: EventEmitter<any> = new EventEmitter();
  @Output() inValidSubmitEvent: EventEmitter<any> = new EventEmitter();
  @Output() cancelFormEvent: EventEmitter<any> = new EventEmitter();
  @Output() cancelEvent: EventEmitter<any> = new EventEmitter();
  @Output() dropdownSelectionEvent: EventEmitter<any> = new EventEmitter();
  @Output() checkboxSelectionEvent: EventEmitter<any> = new EventEmitter();
  @Output() fileUploadEvent: EventEmitter<any> = new EventEmitter();
  @Output() forgotPasswordClicked: EventEmitter<any> = new EventEmitter();
  @Output() dataSelectionChangeEvent: EventEmitter<any> = new EventEmitter();
  @Output() addNewClicked: EventEmitter<any> = new EventEmitter();
  @Output() clickedRadio: EventEmitter<any> = new EventEmitter();
  @Output() getDropValueList: EventEmitter<any> = new EventEmitter();
  @Output() addNewPatient: EventEmitter<any> = new EventEmitter();
  @Output() viewMode: EventEmitter<any> = new EventEmitter();
  @Output() focusInput: EventEmitter<any> = new EventEmitter();
  @Output() focusOutInput: EventEmitter<any> = new EventEmitter();
  @Output() detailsUploads: EventEmitter<any> = new EventEmitter();
  @Output() searchResult: EventEmitter<any> = new EventEmitter();
  @Output() searchTextClick: EventEmitter<any> = new EventEmitter();
  @Output() searchTe: EventEmitter<any> = new EventEmitter();
  @Output() sliderValue: EventEmitter<any> = new EventEmitter();
  @Output() calenderSelectedValue: EventEmitter<any> = new EventEmitter();
  @Output() timeDropDownValue: EventEmitter<any> = new EventEmitter();
  @Output() employeeUploads: EventEmitter<any> = new EventEmitter();
  @Output() profile: EventEmitter<any> = new EventEmitter();
  @Output() isFileUploadPending: EventEmitter<boolean> = new EventEmitter();
  @Output() pMaskOutput: EventEmitter<boolean> = new EventEmitter();
  @Output() showDocs: EventEmitter<Array<string>> = new EventEmitter();
  @Output() selectedPatientID = new EventEmitter<any>();
  formSubmitAttempt = false;
  todayDate: any;
  userDetails: any;
  nextFourtyDays: any;


  constructor(
    private formService: CustomFormHandlerService,
    private fb: FormBuilder,
    private http: HttpService,
    private cognitoService: CognitoService,
    private observableHelperService: ObservableHelperService,
    private route: ActivatedRoute,
    private router: Router,

  ) {
    this.todayDate = new Date();
    this.nextFourtyDays = new Date();
    this.nextFourtyDays.setDate(this.todayDate.getDate() + 39);

  }

  focusFunction(event) {
    this.focusInput.emit(event);
  }
  focusOutFunction(event) {
    this.focusOutInput.emit(event);
  }
  onBlurMethod(field) {
    field.blur = true;
  }

  ngOnInit() {
    this.cognitoService.getUser().then(data => {
      this.userDetails = data;
    });

    this.minDOB.setFullYear(this.minDOB.getFullYear() - 2);
    this.monthSelector = document.getElementsByClassName('ui-datepicker-month')[0];
    this.yearSelector = document.getElementsByClassName('ui-datepicker-year')[0];
    if (this.monthSelector && this.yearSelector) {
      this.yearSelector.setAttribute('onfocus', 'this.size = 4');
      this.yearSelector.setAttribute('onblur', 'this.size = 1');
      this.yearSelector.setAttribute('onchange', 'this.size = 1');
      this.monthSelector.setAttribute('onfocus', 'this.size = 4');
      this.monthSelector.setAttribute('onblur', 'this.size = 1');
      this.monthSelector.setAttribute('onchange', 'this.size = 1');
    }
    if (this.formInputs && Array.isArray(this.formInputs)) {
      this.setCallBackFunctions(this.formInputs);
    }
    // this is used to define the type of right toggle selected- basic infor, insurance dets, medical history or misc, or a new user
    // it is here linking the previously empty form[] to the formTypes of custom form service
    this.formTags.forEach((element, index) => {
      this.form.push(this.formService.initForm(this.formService.formTypes[element]));
    });
  }


  ngAfterContentChecked(): void {
    // here is the setting for calendar- month year and adjustments are made for the mobile view
    this.yearSelector = document.getElementsByClassName('ui-datepicker-year')[0];
    this.monthSelector = document.getElementsByClassName('ui-datepicker-month')[0];
    let isMobile = window.innerWidth < 1024 ? true : false;
    if (this.monthSelector && this.yearSelector) {
      this.yearSelector.setAttribute('onfocus', 'this.size = 10');
      this.yearSelector.setAttribute('onblur', 'this.size = 1');
      this.yearSelector.setAttribute('onchange', 'this.size = 1');
      this.monthSelector.setAttribute('onfocus', 'this.size = 10');
      this.monthSelector.setAttribute('onblur', 'this.size = 1');
      this.monthSelector.setAttribute('onchange', 'this.size = 1');
    }
  }


  handleChange(event, key) {
    this.active = !this.active;
    this.formGroup.controls[key].value = this.active;
  }
  /**
   * On form field changes
   */
  ngOnChanges() {
    this.setCallBackFunctions(this.formInputs);
  }

  setEditViewData(data) {

  }

  onCheckboxChange(list, event, changeEvent, index) {
    if (changeEvent) {
      this.checkboxSelectionEvent.emit({ e: event, data: list });
      if (event) {
        this.checkedList.push(list);
      } else {
        for (var i = 0; i < this.checkedList.length; i++) {
          if (this.checkedList[i] === list) {
            this.checkedList.splice(i, 1);
          }
        }
      }
      if (this.checkedList.length < 1) {
        this.formGroup.controls['mappingSepecification'].status = 'INVALID';
        this.formGroup.patchValue({
          valid: false
        });
        this.validation = true;
      } else {
        this.formGroup.controls['mappingSepecification'].status = 'VALID';
        this.formGroup.patchValue({
          valid: true
        });
        this.validation = false;
      }
    }
  }

  checkboxSelectAll(list) {
    if (this.checkedList.length < list.length) {
      this.checkedList = [];
      list.forEach(element => {
        element.selected = true;
        this.checkedList.push(element);
        this.checkboxSelectionEvent.emit({ e: true, data: element });
      });
      this.validation = false;
    } else if (this.checkedList.length >= list.length) {
      list.forEach(element => {
        element.selected = false;
        this.checkboxSelectionEvent.emit({ e: false, data: element });
      });
      this.checkedList = [];
      this.validation = true;
    }
  }
  setCallBackFunctions(formInputs) {
    if (formInputs && Array.isArray(formInputs)) {
      formInputs.forEach(input => {
        input.callback = '';
        if (input.controlType === 'submitButton') {
          input.callback = this.submit.bind(this);
        } else if (input.controlType === 'cancelButton') {
          input.callback = this.cancel.bind(this);
        }

      });
    }
  }

  /**
   *
   * @param event calender outputs that is  selected dates
   * calender functionality starts here
   */
  selectedDate(event, i, key) {
    if (event) {
      this.formInputs[i].value = event;
      this.formGroup.controls[key].value = event;
      this.formGroup.controls[key].status = 'VALID';
    }
  }

  shouldResetCalenderInput() {
    this.resetCalenderInput = false;
  }

  getRatingValue(event) {
    if (event) {
    }
  }

  onInputChange(field){
    this.pMaskOutput.emit(field);
  }
  checkboxchange(field, index) {
    // console.log(this.formService.basicInformationFormConfig, 'info config');
    let checkboxObj = {
      checkbox: field,
      index
    };
    // console.log(checkboxObj);
    if (checkboxObj.checkbox.key === 'sameAsPatient') {
      if (checkboxObj.checkbox.selected === true) {
        if (this.adminTypeForm === 'offlinePatient' || this.adminTypeForm === undefined) {
          this.formInputs[7].value = this.formService.newUserBasicInformationFormConfig[2].value + ' ' + this.formService.newUserBasicInformationFormConfig[3].value;
          this.formInputs[8].value = this.formService.newUserBasicInformationFormConfig[5].value;
        } else if (this.adminTypeForm === 'newUser') {
          this.formInputs[8].value = this.formService.basicInformationFormConfig[2].value + ' ' + this.formService.basicInformationFormConfig[3].value;
          this.formInputs[9].value = this.formService.basicInformationFormConfig[5].value;
        } else if (this.adminTypeForm === 'existingUser') {
          this.formInputs[7].value = this.formService.existingUserBasicInformationFormConfig[2].value + ' ' + this.formService.existingUserBasicInformationFormConfig[3].value;
          this.formInputs[8].value = this.formService.existingUserBasicInformationFormConfig[5].value;
        }
      } else {
        this.formInputs[7].value = '';
        this.formInputs[8].value = '';
      }
    }
  }
  checkboxchangeOne(field, index) {
    
    // this condition is only for Eval note and mednote in medications management treatment
    // Same diagnosis can't be selected for primary or secondary diagnosis For Axis One sections on Eval and Monthlty medication management note
    this.formInputs.forEach( (eachField, index) => {
      if(eachField.labeltext === field.labeltext ){
        if((eachField.value && eachField.hasOwnProperty('axis') && eachField.axis == "Primary" && field.axis === 'Secondary') ) {
          field.showValidationMsg= true
          setTimeout(() => {
            field.value = false
            field.showValidationMsg= false
          }, 1500);
        } 
        if((eachField.value && eachField.hasOwnProperty('axis') && eachField.axis == "Secondary" && field.axis === 'Primary') ) {
          this.formInputs[index].showValidationMsg= true
          setTimeout(() => {
            this.formInputs[index].value = false
            this.formInputs[index].showValidationMsg= false
          }, 1500);
        } 
      }
    });
    // only one checkbox can be selected in primary or secondary diagnosis For Axis One sections on Eval and Monthlt medication management note
    let primarySubheader, secondarySubheader, count=0;
    if ( field.axis === 'Primary' || field.axis === 'Secondary') { 
      this.formInputs.forEach( (eachField, index) => {
        if(eachField.label === `Primary Diagnosis:`) { primarySubheader = index}
        if(eachField.label === `Secondary Diagnosis:`) { secondarySubheader = index}
        if(eachField.axis === field.axis && eachField.value ){
          eachField.value = (eachField.labeltext === field.labeltext) ? true : false
          count++
          if(field.axis === 'Primary' && count === 2){ this.formInputs[primarySubheader].showMessage = true}
          if(field.axis === 'Secondary' && count === 2){ this.formInputs[secondarySubheader].showMessage = true}
          setTimeout(() => {
            if(field.axis === 'Primary' && count === 2){ this.formInputs[primarySubheader].showMessage = false}
            if(field.axis === 'Secondary' && count === 2){ this.formInputs[secondarySubheader].showMessage = false}
          }, 2000);
        }
      })
    }

    // let checkboxObj = {
    //   checkbox: field,
    //   index
    // };
    // console.log(checkboxObj);
    // if (checkboxObj.checkbox.key === 'sameAsPatient') {
    //   if (checkboxObj.checkbox.selected === true) {
    //     if (this.adminTypeForm === 'offlinePatient' || this.adminTypeForm === undefined) {
    //       this.formInputs[7].value = this.formService.newUserBasicInformationFormConfig[2].value + ' ' + this.formService.newUserBasicInformationFormConfig[3].value;
    //       this.formInputs[8].value = this.formService.newUserBasicInformationFormConfig[5].value;

    //     } else if (this.adminTypeForm === 'newUser') {
    //       this.formInputs[7].value = this.formService.basicInformationFormConfig[2].value + ' ' + this.formService.basicInformationFormConfig[3].value;
    //       this.formInputs[8].value = this.formService.basicInformationFormConfig[5].value;
    //     } else if (this.adminTypeForm === 'existingUser') {
    //       this.formInputs[7].value = this.formService.existingUserBasicInformationFormConfig[2].value + ' ' + this.formService.existingUserBasicInformationFormConfig[3].value;
    //       this.formInputs[8].value = this.formService.existingUserBasicInformationFormConfig[5].value;
    //     }
    //   } else {
    //     this.formInputs[7].value = '';
    //     this.formInputs[8].value = '';
    //   }
    // }
  }
  // tslint:disable-next-line: no-shadowed-variable
  /**
   * 
   * @param slider selected slider vlaue info
   * @param index index of that field
   * @param inputs form inputs
   * @param group form group
   * This Function is for showing/hiding some fields based on slider value and setting/removing validation of that fields
   */
  getSliderValue(slider, index, inputs?, group?) {
    let sliderObj = {
      slider,
      index
    };
    this.formInputs = (inputs !== undefined) ? inputs : this.formInputs;
    this.formGroup = (group !== undefined) ? group : this.formGroup;
    let sliderIndex = sliderObj.index;
    let sliderObjValue = sliderObj.slider.value;
    this.sliderValue.emit(sliderObj);
    if (sliderObj.slider.key === 'inTherapy') {
      for (let i = sliderIndex + 1; ; i++) {
        if (this.formInputs[i].key !== 'psychiatricSlider') {
          if (sliderObjValue === true) {
            this.formInputs[i].showontoggle = false;
            this.formInputs[i].value = '';
            if (this.formInputs[i].validators) {
              this.formGroup.controls[this.formInputs[i].key].clearValidators();
              this.formGroup.controls[this.formInputs[i].key].updateValueAndValidity();
              this.formInputs[i].validators = [];
            }
          } else {
            this.formInputs[i].showontoggle = true;
            if (this.formInputs[i].validators) {
              this.setValidators(this.formInputs[i]);
            }
          }
          let therapistDocsValue = this.formInputs.find(ele => ele.key === INTAKE_FORM_KEYS.IS_THERAPIST_DOCS).value
          let therapistEmailVal = this.formInputs.find(ele => ele.key === FORM_KEYS.IS_THERAPIST_EMAIL_ID).value
          if (this.formInputs[i] && this.formInputs[i].key === INTAKE_FORM_KEYS.THERAPIST_DOCS && sliderObjValue === false ) {
            if(!therapistDocsValue){
              this.formInputs[i].showontoggle = true;
              if (this.formInputs[i].validators) {
                this.setValidators(this.formInputs[i]);
              }
            } else {
              this.formInputs[i].showontoggle = false;
              this.formInputs[i].value = [];
              if (this.formInputs[i].validators) {
                this.formGroup.controls[this.formInputs[i].key].clearValidators();
                this.formGroup.controls[this.formInputs[i].key].updateValueAndValidity();
                this.formInputs[i].validators = [];
              }
            }
            
        } else if(this.formInputs[i] && this.formInputs[i].key === INTAKE_FORM_KEYS.THERAPIST_DOCS && sliderObjValue === true ){
          this.formGroup.controls[this.formInputs[i].key].value = [];
        } else if (this.formInputs[i] && this.formInputs[i].key === INTAKE_FORM_KEYS.THERAPIST_EMAIL && sliderObjValue === false) {
          if (!therapistEmailVal) {
            this.formInputs[i].showontoggle = true;
            if (this.formInputs[i].validators) {
              this.setValidators(this.formInputs[i]);
            }
          } else {
            this.formInputs[i].showontoggle = false;
            this.formInputs[i].value = [];
            if (this.formInputs[i].validators) {
              this.formGroup.controls[this.formInputs[i].key].clearValidators();
              this.formGroup.controls[this.formInputs[i].key].updateValueAndValidity();
              this.formInputs[i].validators = [];
            }
          }
        }
        if (this.formInputs[i] && this.formInputs[i].key === INTAKE_FORM_KEYS.SEND_THERAPIST && sliderObjValue === false ) {
          if(therapistDocsValue){
            this.formInputs[i].showontoggle = true;
            if (this.formInputs[i].validators) {
              this.setValidators(this.formInputs[i]);
            }
          } else {
            this.formInputs[i].showontoggle = false;
            this.formInputs[i].value = "";
            if (this.formInputs[i].validators) {
              this.formGroup.controls[this.formInputs[i].key].clearValidators();
              this.formGroup.controls[this.formInputs[i].key].updateValueAndValidity();
              this.formInputs[i].validators = [];
            }
          }
          
      }
        } else {
          break;
        }
      }
    } else if (sliderObj.slider.key === 'psychiatricSlider') {
      for (let i = sliderIndex + 1; ; i++) {
        if (this.formInputs[i].key !== 'benzodiazepinesSlider') {
          if (sliderObjValue === true) {
            this.formInputs[i].showontoggle = false;
            this.formInputs[i].value = '';
            if (this.formGroup.get(this.formInputs[i].key).controls) {
              // tslint:disable-next-line: no-shadowed-variable
              this.formGroup.get(this.formInputs[i].key).controls.forEach(group => {
                // tslint:disable-next-line: forin
                for (let control in group.controls) {
                  group.controls[control].clearValidators();
                  group.controls[control].updateValueAndValidity();
                }
              });
            } else {
              this.formGroup.controls[this.formInputs[i].key].clearValidators();
              this.formGroup.controls[this.formInputs[i].key].updateValueAndValidity();
              this.formInputs[i].validators = [];
            }
            if (this.formInputs[i].formInputs) {
              this.formInputs[i].formInputs.forEach(data => {
                data.forEach(input => {
                  if (input.validators) {
                    input.value = '';
                    input.count = '150 remaining';
                    input.validators = [];
                  }
                });
              });
            } else {
              this.formInputs[i].validators = [];
            }
          } else {
            if (this.formInputs[i].key === 'medications' || this.formInputs[i].key === 'psychiatricAddNew') {
              this.formInputs[i].showontoggle = true;
              if (this.formInputs[i].controlType === 'formArray') {
                this.formInputs[i].formInputs.splice(1);
              }
              if (this.formInputs[i].formInputs) {
                this.formInputs[i].formInputs.forEach((data, arrayIndex) => {
                  data.forEach((input, innerIndex) => {
                    if (input.validators) {
                      this.setInnerFieldValidators(this.formInputs[i], input, arrayIndex, innerIndex);
                    }
                  });
                });
              }
            }
          }

        } else {
          break;
        }
      }
    } else if (sliderObj.slider.key === 'benzodiazepinesSlider') {
      for (let i = sliderIndex + 1; ; i++) {
        if (this.formInputs[i].key !== 'medicaladderallstimulants') {
          if (sliderObjValue === true) {
            this.formInputs[i].showontoggle = false;
            this.formInputs[i].value = '';
            if (this.formGroup.get(this.formInputs[i].key).controls) {
              // tslint:disable-next-line: no-shadowed-variable
              this.formGroup.get(this.formInputs[i].key).controls.forEach(group => {
                // tslint:disable-next-line: forin
                for (let control in group.controls) {
                  group.controls[control].clearValidators();
                  group.controls[control].updateValueAndValidity();
                }
              });
            } else {
              this.formGroup.controls[this.formInputs[i].key].clearValidators();
              this.formGroup.controls[this.formInputs[i].key].updateValueAndValidity();
              this.formInputs[i].validators = [];
            }
            if (this.formInputs[i].formInputs) {
              this.formInputs[i].formInputs.forEach(data => {
                data.forEach(input => {
                  if (input.validators) {
                    input.value = '';
                    input.count = '150 remaining';
                    input.validators = [];
                  }
                });
              });
            } else {
              this.formInputs[i].validators = [];
            }
          } else {
            if (this.formInputs[i].key === 'benzodiazepines' || this.formInputs[i].key === 'benzodiazepinesaddnew') {
              this.formInputs[i].showontoggle = true;
              if (this.formInputs[i].controlType === 'formArray') {
                this.formInputs[i].formInputs.splice(1);
              }
              if (this.formInputs[i].formInputs) {
                this.formInputs[i].formInputs.forEach((data, arrayIndex) => {
                  data.forEach((input, innerIndex) => {
                    if (input.validators) {
                      this.setInnerFieldValidators(this.formInputs[i], input, arrayIndex, innerIndex);
                    }
                  });
                });
              }
            } else {
              this.removeOnToggle(this.formInputs[i], 'MEDICALHISTORY', 'benzodiazepinesaddnew');
            }
          }
        } else {
          break;
        }
      }
    } else if (sliderObj.slider.key === 'medicaladderallstimulants') {
      for (let i = sliderIndex + 1; ; i++) {
        if (this.formInputs[i].key !== 'LabWorkDocsUpload') {
          if (sliderObjValue === true) {
            this.formInputs[i].showontoggle = false;
            this.formInputs[i].value = '';
            if (this.formGroup.get(this.formInputs[i].key).controls) {
              // tslint:disable-next-line: no-shadowed-variable
              this.formGroup.get(this.formInputs[i].key).controls.forEach(group => {
                // tslint:disable-next-line: forin
                for (let control in group.controls) {
                  group.controls[control].clearValidators();
                  group.controls[control].updateValueAndValidity();
                }
              });
            } else {
              this.formGroup.controls[this.formInputs[i].key].clearValidators();
              this.formGroup.controls[this.formInputs[i].key].updateValueAndValidity();
              this.formInputs[i].validators = [];
            }
            if (this.formInputs[i].formInputs) {
              this.formInputs[i].formInputs.forEach(data => {
                data.forEach(input => {
                  if (input.validators) {
                    input.value = '';
                    input.count = '150 remaining';
                    input.validators = [];
                  }
                });
              });
            } else {
              this.formInputs[i].validators = [];
            }
          } else {
            if (this.formInputs[i].key === 'medicaladderal' || this.formInputs[i].key === 'medicalAdderallAddNew') {
              this.formInputs[i].showontoggle = true;
              if (this.formInputs[i].controlType === 'formArray') {
                this.formInputs[i].formInputs.splice(1);
              }
              if (this.formInputs[i].formInputs) {
                this.formInputs[i].formInputs.forEach((data, arrayIndex) => {
                  data.forEach((input, innerIndex) => {
                    if (input.validators) {
                      this.setInnerFieldValidators(this.formInputs[i], input, arrayIndex, innerIndex);
                    }
                  });
                });
              }
            } else {
              this.removeOnToggle(this.formInputs[i], 'MEDICALHISTORY', 'medicaladderalladdnew');
              break;
            }
          }
        } else {
          break;
        }
      }
    } else if (sliderObj.slider.key === 'legalIssues') {
      for (let i = sliderIndex + 1; ; i++) {
        if (this.formInputs[i].key !== 'currentPharmacyHeader') {
          if (sliderObjValue === true) {
            this.formInputs[i].showontoggle = false;
            this.formInputs[i].value = '';
            this.formInputs[i].count = '150 remaining';
            if (this.formInputs[i].validators) {
              this.formGroup.controls[this.formInputs[i].key].clearValidators();
              this.formGroup.controls[this.formInputs[i].key].updateValueAndValidity();
              this.formInputs[i].validators = [];
            }
          } else {
            this.formInputs[i].showontoggle = true;
            if (this.formInputs[i].validators) {
              this.setValidators(this.formInputs[i]);
            }
          }
        } else {
          break;
        }
      }
    } else if (sliderObj.slider.key === 'sideeffects_from_meds') {
      for (let i = sliderIndex + 1; ; i++) {
        if (this.formInputs[i].key !== 'email_communicate') {
          if (sliderObjValue === true) {
            this.formInputs[i].showontoggle = false;
            this.formInputs[i].value = '';
            if (this.formInputs[i].validators) {
              this.formGroup.controls[this.formInputs[i].key].clearValidators();
              this.formGroup.controls[this.formInputs[i].key].updateValueAndValidity();
              this.formInputs[i].validators = [];
            }
          } else {
            this.formInputs[i].showontoggle = true;
            if (this.formInputs[i].validators) {
              this.setValidators(this.formInputs[i]);
            }
          }
        } else {
          break;
        }
      }
    } else if (sliderObj.slider.key === 'isFaintingSpell') {
      for (let i = sliderIndex + 1; ; i++) {
        if (this.formInputs[i].key !== 'isSevereTrauma') {
          if (sliderObjValue === true) {
            this.formInputs[i].showontoggle = false;
            this.formInputs[i].value = '';
            this.formInputs[i].count = '150 remaining';
            this.formGroup.controls[this.formInputs[i].key].value = '';
            this.formGroup.controls[this.formInputs[i].key].clearValidators();
            this.formGroup.controls[this.formInputs[i].key].updateValueAndValidity();
            this.formInputs[i].validators = [];
          } else {
            this.formInputs[i].showontoggle = true;
            this.setValidators(this.formInputs[i]);
          }
        } else {
          break;
        }
      }
    } else if (sliderObj.slider.key === 'isPsychiatricOrNeuroactive') {
      for (let i = sliderIndex + 1; ; i++) {
        if (this.formInputs[i].key !== 'isEverUndergoTMS') {
          if (sliderObjValue === true) {
            this.formInputs[i].showontoggle = false;
            this.formInputs[i].value = '';
            this.formInputs[i].count = '150 remaining';
            this.formGroup.controls[this.formInputs[i].key].value = '';
            this.formGroup.controls[this.formInputs[i].key].clearValidators();
            this.formGroup.controls[this.formInputs[i].key].updateValueAndValidity();
            this.formInputs[i].validators = [];
          } else {
            this.formInputs[i].showontoggle = true;
            this.setValidators(this.formInputs[i]);
          }
        } else {
          break;
        }
      }
    } else if ((sliderObj.slider.key === 'medications' || sliderObj.slider.key === 'benzodiazepines') && sliderObj.slider.formtag === 'NEWUSER_MEDICALHISTORY') {
      let fieldKey = sliderObj.slider.key;
      let fieldTag = sliderObj.slider.formtag;
      let ind = sliderObj.index;
      let form = sliderObj.slider.formInputs[ind];
      if (fieldKey === 'medications' && fieldTag === 'NEWUSER_MEDICALHISTORY') {
        form.forEach((obj1, i) => {
          if (obj1.key === 'discontinued' && obj1.value === true) {
            form.forEach(obj2 => {
              if (obj2.key === 'discontinuedReasion') {
                obj2.required = false;
                return false;
              }
            });
          }
          if (obj1.key === 'discontinued' && obj1.value === false) {
            form.forEach(obj2 => {
              if (obj2.key === 'discontinuedReasion') {
                obj2.required = true;
                return false;
              }
            });
          }
        });
      }
      /**
       * benzodiazepines:it is a key value of that particular form field
       * NEWUSER_MEDICALHISTORY: it is a formTag like we have individual formTags for new user,exisiting user and offline patient
       * In Benzodiazepines medication we have few form fields in that we have one form field called Have you discontinued it? 
       * for that field key value is discontinued and based on that slide value we setting Reason form field required or not
       */
      if (fieldKey === 'benzodiazepines' && fieldTag === 'NEWUSER_MEDICALHISTORY') {
        form.forEach((obj1, i) => {
          if (obj1.key === 'discontinued' && obj1.value === true) {
            form.forEach(obj2 => {
              if (obj2.key === 'discontinuedReasion') {
                obj2.required = false;
                return false;
              }
            });
          }
          if (obj1.key === 'discontinued' && obj1.value === false) {
            form.forEach(obj2 => {
              if (obj2.key === 'discontinuedReasion') {
                obj2.required = true;
                return false;
              }
            });
          }
        });
      }
      this.form[2] = this.formService.dynamicInitForm(this.formService.newUserMedicalHistoryFormConfig, this.formService.formTypes[fieldTag]);
      this.formInputs = this.form[2].formInputs;
      this.formGroup = this.form[2].formGroup;
    }
    else if (sliderObj.slider.key === FORM_KEYS.SIDEEFFECTS_DURING_TMS) {
      for (let i = sliderIndex + 1; ; i++) {
        if (this.formInputs[i].key !== FORM_KEYS.SIDEEFFECTS_AFTER_TMS) {
          if (sliderObjValue === true) {
            this.formInputs[i].showontoggle = false;
            this.formInputs[i].value = '';
            if (this.formInputs[i].validators) {
              this.formGroup.controls[this.formInputs[i].key].clearValidators();
              this.formGroup.controls[this.formInputs[i].key].updateValueAndValidity();
              this.formInputs[i].validators = [];
            }
          } else {
            this.formInputs[i].showontoggle = true;
            if (this.formInputs[i].validators) {
              this.setValidators(this.formInputs[i]);
            }
          }
        } else {
          break;
        }
      }
    } 
    else if (sliderObj.slider.key === FORM_KEYS.SIDEEFFECTS_AFTER_TMS) {
      for (let i = sliderIndex + 1; ; i++) {
        if (this.formInputs[i].key !== FORM_KEYS.MONITORED_THROUGHOUT_SESSION) {
          if (sliderObjValue === true) {
            this.formInputs[i].showontoggle = false;
            this.formInputs[i].value = '';
            if (this.formInputs[i].validators) {
              this.formGroup.controls[this.formInputs[i].key].clearValidators();
              this.formGroup.controls[this.formInputs[i].key].updateValueAndValidity();
              this.formInputs[i].validators = [];
            }
          } else {
            this.formInputs[i].showontoggle = true;
            if (this.formInputs[i].validators) {
              this.setValidators(this.formInputs[i]);
            }
          }
        } else {
          break;
        }
      }
    }
    else if (sliderObj.slider.key === FORM_KEYS.SIDEEFFECTS_DURING_KETAMINE) {
      for (let i = sliderIndex + 1; ; i++) {
        if (this.formInputs[i].key !== FORM_KEYS.SIDEEFFECTS_AFTER_KETAMINE) {
          if (sliderObjValue === true) {
            this.formInputs[i].showontoggle = false;
            this.formInputs[i].value = '';
            if (this.formInputs[i].validators) {
              this.formGroup.controls[this.formInputs[i].key].clearValidators();
              this.formGroup.controls[this.formInputs[i].key].updateValueAndValidity();
              this.formInputs[i].validators = [];
            }
          } else {
            this.formInputs[i].showontoggle = true;
            if (this.formInputs[i].validators) {
              this.setValidators(this.formInputs[i]);
            }
          }
        } else {
          break;
        }
      }
    } 
    else if (sliderObj.slider.key === FORM_KEYS.SIDEEFFECTS_AFTER_KETAMINE) {
      for (let i = sliderIndex + 1; ; i++) {
        if (this.formInputs[i].key !== FORM_KEYS.MONITORED_THROUGHOUT_SESSION) {
          if (sliderObjValue === true) {
            this.formInputs[i].showontoggle = false;
            this.formInputs[i].value = '';
            if (this.formInputs[i].validators) {
              this.formGroup.controls[this.formInputs[i].key].clearValidators();
              this.formGroup.controls[this.formInputs[i].key].updateValueAndValidity();
              this.formInputs[i].validators = [];
            }
          } else {
            this.formInputs[i].showontoggle = true;
            if (this.formInputs[i].validators) {
              this.setValidators(this.formInputs[i]);
            }
          }
        } else {
          break;
        }
      }
    } 
    else if (sliderObj.slider.key === FORM_KEYS.LITERATURE_INFORMATION) {
      for (let i = sliderIndex + 1; ; i++) {
        if (this.formInputs[i].key !== FORM_KEYS.FULLY_UNDERSTAND) {
          if (sliderObjValue === true) {
            this.formInputs[i].showontoggle = false;
            this.formInputs[i].value = '';
            if (this.formInputs[i].validators) {
              this.formGroup.controls[this.formInputs[i].key].clearValidators();
              this.formGroup.controls[this.formInputs[i].key].updateValueAndValidity();
              this.formInputs[i].validators = [];
            }
          } else {
            this.formInputs[i].showontoggle = true;
            if (this.formInputs[i].validators) {
              this.setValidators(this.formInputs[i]);
            }
          }
        } else {
          break;
        }
      }
    } 
    else if (sliderObj.slider.key === FORM_KEYS.MONITORED_THROUGHOUT_SESSION) {
      for (let i = sliderIndex + 1; ; i++) {
        if (this.formInputs[i].key !== FORM_KEYS.FEEL_SATISFIED) {
          if (sliderObjValue === true) {
            this.formInputs[i].showontoggle = false;
            this.formInputs[i].value = '';
            if (this.formInputs[i].validators) {
              this.formGroup.controls[this.formInputs[i].key].clearValidators();
              this.formGroup.controls[this.formInputs[i].key].updateValueAndValidity();
              this.formInputs[i].validators = [];
            }
          } else {
            this.formInputs[i].showontoggle = true;
            if (this.formInputs[i].validators) {
              this.setValidators(this.formInputs[i]);
            }
          }
        } else {
          break;
        }
      }
    } 
    else if (sliderObj.slider.key === FORM_KEYS.MONITORED_THROUGHOUT_SESSION) {
      for (let i = sliderIndex + 1; ; i++) {
        if (this.formInputs[i].key !== FORM_KEYS.FEEL_SATISFIED) {
          if (sliderObjValue === true) {
            this.formInputs[i].showontoggle = false;
            this.formInputs[i].value = '';
            if (this.formInputs[i].validators) {
              this.formGroup.controls[this.formInputs[i].key].clearValidators();
              this.formGroup.controls[this.formInputs[i].key].updateValueAndValidity();
              this.formInputs[i].validators = [];
            }
          } else {
            this.formInputs[i].showontoggle = true;
            if (this.formInputs[i].validators) {
              this.setValidators(this.formInputs[i]);
            }
          }
        } else {
          break;
        }
      }
    } else if ((sliderObj.slider.key === KETAMINE_ENROLLMENT_KEYS.KETAMINE_ENR_MEDICATION) && sliderObj.slider.formtag ===  KETAMINE_ENROLLMENT_KEYS.KETAMINE_FORMTAG) {
      let fieldKey = sliderObj.slider.key;
      let fieldTag = sliderObj.slider.formtag;
      let ind = sliderObj.index;
      let form = sliderObj.slider.formInputs[ind];
      form.forEach((obj1, i) => {
        if (obj1.key === KETAMINE_ENROLLMENT_KEYS.KETAMINE_ENR_DISCONTINUED && obj1.value === true) {
          form.forEach(obj2 => {
            if (obj2.key === KETAMINE_ENROLLMENT_KEYS.KETAMINE_ENR_DISCONTINUED_RSN) {
              obj2.required = false;
              return false;
            }
          });
        }
        if (obj1.key === KETAMINE_ENROLLMENT_KEYS.KETAMINE_ENR_DISCONTINUED && obj1.value === false) {
          form.forEach(obj2 => {
            if (obj2.key === KETAMINE_ENROLLMENT_KEYS.KETAMINE_ENR_DISCONTINUED_RSN) {
              obj2.required = true;
              return false;
            }
          });
        }
      });
      this.form[2] = this.formService.dynamicInitForm(this.formService.KETAMINEEnrollmentFormConfig, this.formService.formTypes[fieldTag]);
      this.formInputs = this.form[2].formInputs;
      this.formGroup = this.form[2].formGroup;
    } else if (sliderObj.slider.key === INTAKE_FORM_KEYS.MEDICAL_DISORDERS) {
      for (let i = sliderIndex + 1; ; i++) {
        if (this.formInputs[i].key !== INTAKE_FORM_KEYS.PAST_HISTORY) {
          if (sliderObjValue === true) {
            this.formInputs[i].showontoggle = false;
            this.formInputs[i].value = '';
            if (this.formInputs[i].validators) {
              this.formGroup.controls[this.formInputs[i].key].clearValidators();
              this.formGroup.controls[this.formInputs[i].key].updateValueAndValidity();
              this.formInputs[i].validators = [];
            }
          } else {
            this.formInputs[i].showontoggle = true;
            if (this.formInputs[i].validators) {
              this.setValidators(this.formInputs[i]);
            }
          }
        } else {
          break;
        }
      }
    }
    else if (sliderObj.slider.key === TMS_CONSENT_FORM.TMS_CONSENT_HOSPITALIZED_BEFORE) {
      for (let i = sliderIndex + 1; ; i++) {
        if (this.formInputs[i].key !== TMS_CONSENT_FORM.TMS_CONSENT_DATE) {
          if (sliderObjValue === true) {
            this.formInputs[i].showontoggle = false;
            this.formInputs[i].value = '';
            if (this.formInputs[i].validators) {
              this.formGroup.controls[this.formInputs[i].key].clearValidators();
              this.formGroup.controls[this.formInputs[i].key].updateValueAndValidity();
              this.formInputs[i].validators = [];
            }
          } else {
            this.formInputs[i].showontoggle = true;
            if (this.formInputs[i].validators) {
              this.setValidators(this.formInputs[i]);
            }
          }
        } else {
          break;
        }
      }
    }
    else if (sliderObj.slider.key === MM_CONSENT_FORM.MM_CONSENT_HOSPITALIZED_BEFORE) {
      for (let i = sliderIndex + 1; ; i++) {
        if (this.formInputs[i].key !== 'medication_signature') {
          if (sliderObjValue === true) {
            this.formInputs[i].showontoggle = false;
            this.formInputs[i].value = '';
             if (this.formInputs[i].validators) {
              this.formGroup.controls[this.formInputs[i].key].clearValidators();
              this.formGroup.controls[this.formInputs[i].key].updateValueAndValidity();
              this.formInputs[i].validators = [];
            }
          } else {
            this.formInputs[i].showontoggle = true;
            if (this.formInputs[i].validators) {
              this.setValidators(this.formInputs[i]);
            }
          }
        } else {
          break;
        }
      }
    }
    else if (sliderObj.slider.key === INTAKE_FORM_KEYS.PAST_HISTORY) {
      for (let i = sliderIndex + 1; ; i++) {
        if (this.formInputs[i].key !== INTAKE_FORM_KEYS.NON_PSYCH_MEDS) {
          if (sliderObjValue === true) {
            this.formInputs[i].showontoggle = false;
            this.formInputs[i].value = '';
            if (this.formInputs[i].validators) {
              this.formGroup.controls[this.formInputs[i].key].clearValidators();
              this.formGroup.controls[this.formInputs[i].key].updateValueAndValidity();
              this.formInputs[i].validators = [];
            }
          } else {
            this.formInputs[i].showontoggle = true;
            if (this.formInputs[i].validators) {
              this.setValidators(this.formInputs[i]);
            }
          }
        } else {
          break;
        }
      }
    }
    else if (sliderObj.slider.key === KETAMINE_CONSENT_FORM.KETAMINE_CONSENT_HOSPITALIZED_BEFORE) {
      for (let i = sliderIndex + 1; ; i++) {
        if (this.formInputs[i].key !== KETAMINE_CONSENT_FORM.KETAMINE_CONSENT_DATE) {
          if (sliderObjValue === true) {
            this.formInputs[i].showontoggle = false;
            this.formInputs[i].value = '';
            if (this.formInputs[i].validators) {
              this.formGroup.controls[this.formInputs[i].key].clearValidators();
              this.formGroup.controls[this.formInputs[i].key].updateValueAndValidity();
              this.formInputs[i].validators = [];
            }
          } else {
            this.formInputs[i].showontoggle = true;
            if (this.formInputs[i].validators) {
              this.setValidators(this.formInputs[i]);
            }
          }
        } else {
          break;
        }
      }
    }
    else if (sliderObj.slider.key === INTAKE_FORM_KEYS.NON_PSYCH_MEDS) {
      for (let i = sliderIndex + 1; ; i++) {
        if (this.formInputs[i].key !== INTAKE_FORM_KEYS.ANY_OPIOIDS) {
          if (sliderObjValue === true) {
            this.formInputs[i].showontoggle = false;
            this.formInputs[i].value = '';
            if (this.formInputs[i].validators) {
              this.formGroup.controls[this.formInputs[i].key].clearValidators();
              this.formGroup.controls[this.formInputs[i].key].updateValueAndValidity();
              this.formInputs[i].validators = [];
            }
          } else {
            this.formInputs[i].showontoggle = true;
            if (this.formInputs[i].validators) {
              this.setValidators(this.formInputs[i]);
            }
          }
        } else {
          break;
        }
      }
    }
    else if (sliderObj.slider.key === INTAKE_FORM_KEYS.ANY_OPIOIDS) {
      for (let i = sliderIndex + 1; ; i++) {
        if (this.formInputs[i].key !== INTAKE_FORM_KEYS.HEADER) {
          if (sliderObjValue === true) {
            this.formInputs[i].showontoggle = false;
            this.formInputs[i].value = '';
            if (this.formInputs[i].validators) {
              this.formGroup.controls[this.formInputs[i].key].clearValidators();
              this.formGroup.controls[this.formInputs[i].key].updateValueAndValidity();
              this.formInputs[i].validators = [];
            }
          } else {
            this.formInputs[i].showontoggle = true;
            if (this.formInputs[i].validators) {
              this.setValidators(this.formInputs[i]);
            }
          }
        } else {
          break;
        }
      }
    }
    else if (sliderObj.slider.key === INTAKE_FORM_KEYS.PREVIOUS_PRESC) {
      for (let i = sliderIndex + 1; ; i++) {

        if (this.formInputs[i].key !== INTAKE_FORM_KEYS.HOSPITALIZED_BEFORE && this.formInputs[i].key !== 'note') {
          if (sliderObjValue === true) {
            this.formInputs[i].showontoggle = false;
            this.formInputs[i].value = '';
            if (this.formInputs[i].validators) {
              this.formGroup.controls[this.formInputs[i].key].clearValidators();
              this.formGroup.controls[this.formInputs[i].key].updateValueAndValidity();
              this.formInputs[i].validators = [];
            }
          } else {
            this.formInputs[i].showontoggle = true;
            if (this.formInputs[i].validators) {
              this.setValidators(this.formInputs[i]);
            }
          }
          let therapistDocsValue = this.formInputs.find(ele => ele.key === INTAKE_FORM_KEYS.IS_PRESCRIBER_DOCS).value
          if (this.formInputs[i] && this.formInputs[i].key === INTAKE_FORM_KEYS.PRESCRIBER_DOCS && sliderObjValue === false ) {
            if(!therapistDocsValue){
              this.formInputs[i].showontoggle = true;
              if (this.formInputs[i].validators) {
                this.setValidators(this.formInputs[i]);
              }
            } else {
              this.formInputs[i].showontoggle = false;
              this.formInputs[i].value = [];
              if (this.formInputs[i].validators) {
                this.formGroup.controls[this.formInputs[i].key].clearValidators();
                this.formGroup.controls[this.formInputs[i].key].updateValueAndValidity();
                this.formInputs[i].validators = [];
              }
            }
            
        } else if(this.formInputs[i] && this.formInputs[i].key === INTAKE_FORM_KEYS.PRESCRIBER_DOCS && sliderObjValue === true ){
          this.formGroup.controls[this.formInputs[i].key].value = [];
        }

        if (this.formInputs[i] && this.formInputs[i].key === INTAKE_FORM_KEYS.SEND_PRESCRIBER && sliderObjValue === false ) {
          if(therapistDocsValue){
            this.formInputs[i].showontoggle = true;
            if (this.formInputs[i].validators) {
              this.setValidators(this.formInputs[i]);
            }
          } else {
            this.formInputs[i].showontoggle = false;
            this.formInputs[i].value = "";
            if (this.formInputs[i].validators) {
              this.formGroup.controls[this.formInputs[i].key].clearValidators();
              this.formGroup.controls[this.formInputs[i].key].updateValueAndValidity();
              this.formInputs[i].validators = [];
            }
          }
          
      }
        } else {
          break;
        }
      }
    }
    else if (sliderObj.slider.key === INTAKE_FORM_KEYS.HOSPITALIZED_BEFORE) {
      for (let i = sliderIndex + 1; ; i++) {
        if (this.formInputs[i] && this.formInputs[i].key !== 'legalIssues') {
          if (sliderObjValue === true) {
            this.formInputs[i].showontoggle = false;
            this.formInputs[i].value = '';
            if (this.formInputs[i].validators) {
              this.formGroup.controls[this.formInputs[i].key].clearValidators();
              this.formGroup.controls[this.formInputs[i].key].updateValueAndValidity();
              this.formInputs[i].validators = [];
            }
          } else {
            this.formInputs[i].showontoggle = true;
            if (this.formInputs[i].validators) {
              this.setValidators(this.formInputs[i]);
            }
          }
        } else {
          break;
        }
      }
    }
    else if (sliderObj.slider.key === INTAKE_FORM_KEYS.IS_THERAPIST_DOCS) {
      for (let i = sliderIndex + 1; this.formInputs[i] ; i++) {
        if (this.formInputs[i] && this.formInputs[i].key === INTAKE_FORM_KEYS.THERAPIST_DOCS) {
          this.formGroup.controls[this.formInputs[i].key].value = this.formInputs[i].value;
          if (sliderObjValue === true) {
            this.formInputs[i].showontoggle = false;
            this.formGroup.controls[this.formInputs[i].key].value = (this.formGroup.controls[this.formInputs[i].key].value !='') ? this.formGroup.controls[this.formInputs[i].key].value.filter(ele => ele.indexOf('temp-new-tele-doc') === -1) : '';
            // this.formInputs[i].value = '';
            if (this.formInputs[i].validators) {
              this.formGroup.controls[this.formInputs[i].key].clearValidators();
              this.formGroup.controls[this.formInputs[i].key].updateValueAndValidity();
              this.formInputs[i].validators = [];
            }
          } else {
            this.formInputs[i].showontoggle = true;
            if (this.formInputs[i].validators) {
              this.setValidators(this.formInputs[i]);
            }
          }
        }
        if (this.formInputs[i] && this.formInputs[i].key === INTAKE_FORM_KEYS.SEND_THERAPIST) {
          let inTherapy = this.formInputs.find(ele => ele.key == 'inTherapy')
          if (sliderObjValue === false || inTherapy.value) {
            this.formInputs[i].showontoggle = false;
            this.formInputs[i].value = "";
            if (this.formInputs[i].validators) {
              this.formGroup.controls[this.formInputs[i].key].clearValidators();
              this.formGroup.controls[this.formInputs[i].key].updateValueAndValidity();
              this.formInputs[i].validators = [];
            }
          } else {
            this.formInputs[i].showontoggle = true;
            if (this.formInputs[i].validators) {
              this.setValidators(this.formInputs[i]);
            }
          }
        }
      }
    }
    else if (sliderObj.slider.key === INTAKE_FORM_KEYS.IS_PRESCRIBER_DOCS) {
      for (let i = sliderIndex + 1; this.formInputs[i] ; i++) {
        if (this.formInputs[i] && this.formInputs[i].key === INTAKE_FORM_KEYS.PRESCRIBER_DOCS) {
          this.formGroup.controls[this.formInputs[i].key].value = this.formInputs[i].value;
          if (sliderObjValue === true) {
            this.formInputs[i].showontoggle = false;
            // this.formInputs[i].value = '';
            this.formGroup.controls[this.formInputs[i].key].value = (this.formGroup.controls[this.formInputs[i].key].value !='') ? this.formGroup.controls[this.formInputs[i].key].value.filter(ele => ele.indexOf('temp-new-tele-doc') === -1) : '';
            if (this.formInputs[i].validators) {
              this.formGroup.controls[this.formInputs[i].key].clearValidators();
              this.formGroup.controls[this.formInputs[i].key].updateValueAndValidity();
              this.formInputs[i].validators = [];
            }
          } else {
            this.formInputs[i].showontoggle = true;
            if (this.formInputs[i].validators) {
              this.setValidators(this.formInputs[i]);
            }
          }
        }
        if (this.formInputs[i] && this.formInputs[i].key === INTAKE_FORM_KEYS.SEND_PRESCRIBER) { 
          let inTherapy = this.formInputs.find(ele => ele.key == INTAKE_FORM_KEYS.PREVIOUS_PRESC)
          if (sliderObjValue === false || inTherapy.value) {
            this.formInputs[i].showontoggle = false;
            this.formInputs[i].value = "";
            if (this.formInputs[i].validators) {
              this.formGroup.controls[this.formInputs[i].key].clearValidators();
              this.formGroup.controls[this.formInputs[i].key].updateValueAndValidity();
              this.formInputs[i].validators = [];
            }
          } else {
            this.formInputs[i].showontoggle = true;
            if (this.formInputs[i].validators) {
              this.setValidators(this.formInputs[i]);
            }
          }
        }
      }
    }

    else if (sliderObj.slider.key === FORM_KEYS.IS_THERAPIST_EMAIL_ID) {
      for (let i = sliderIndex + 1; this.formInputs[i] ; i++) {
        if (this.formInputs[i] && this.formInputs[i].key != 'lastTherapyDate') {
          let inTherapy = this.formInputs.find(ele => ele.key == 'inTherapy')
          if (!inTherapy.value ){
            if (sliderObjValue === false || inTherapy.value) {
              this.formInputs[i].showontoggle = true;
  
              if (this.formInputs[i].validators) {
                this.setValidators(this.formInputs[i]);
              }
            } else {
              this.formInputs[i].showontoggle = false;
              this.formInputs[i].value = "";
              if (this.formInputs[i].validators) {
                this.formGroup.controls[this.formInputs[i].key].clearValidators();
                this.formGroup.controls[this.formInputs[i].key].updateValueAndValidity();
                this.formInputs[i].validators = [];
              }
            }
          }
          else{
            this.formInputs[i].showontoggle = false;
            this.formInputs[i].value = "";
            if (this.formInputs[i].validators) {
              this.formGroup.controls[this.formInputs[i].key].clearValidators();
              this.formGroup.controls[this.formInputs[i].key].updateValueAndValidity();
              this.formInputs[i].validators = [];
            }
          }
        } else {
          break;
        }
        
      }
    } //mednotes
    else if(sliderObj.slider.key === "showscorehistory"){
      console.log(sliderObj)
      for (let i = sliderIndex + 1; ; i++) {
        if (this.formInputs[i].key == 'scoreHistoryTable') {
          if (sliderObjValue === true) {
              // remove side Effct fields on toggle no
            this.formInputs[i +1].showontoggle = false;
            this.formInputs[i].showontoggle = false;
            // this.formInputs[i+1].showontoggle = false;
            //this.formInputs[i].value = '';
            // if (this.formInputs[i].validators) {
              for (let j = 1; j< this.formInputs[i].formInputs[0].length ; j++){
              // this.formGroup.controls['scoreHistoryTable'].controls[0].controls[this.formInputs[i].formInputs[0][j].key].clearValidators();
              // this.formGroup.controls['scoreHistoryTable'].controls[0].controls[this.formInputs[i].formInputs[0][j].key].updateValueAndValidity();
              this.formInputs[i].formInputs[0][j].validators = [];
              }
            // }
          } else {
              // show side Effect fields on toggle yes
            this.formInputs[i].showontoggle = true;
            this.formInputs[i +1].showontoggle = true;
            //this.formInputs[i+1].showontoggle = true;
            for (let j = 1; j< this.formInputs[i].formInputs[0].length ; j++) {
              console.log(this.formInputs[i].formInputs[0][j].key,'step',j);
              
              // this.formGroup.controls['scoreHistoryTable'].controls[0].controls[this.formInputs[i].formInputs[0][j].key].setValidators([Validators.required]);
            }
            console.log(this.formInputs[i])
          }
        } else {
          break;
        }
      }
    }
  }
  selectedRadio(radio, field, ind, parent?) {
    let sliderIndex = ind;
    // console.log(radio, field, ind, parent);
    if (radio.label === 'Current') {
      if (parent) {
        parent.formInputs[ind].forEach((input, index) => {
          if (input.key === 'prescriptionEndDate') {
            input.validators = [this.validateEndDate];
            input.editable = false;
            input.value = '';
            this.formGroup.get(parent.key).controls[ind].controls[input.key].clearValidators();
            this.formGroup.get(parent.key).controls[ind].controls[input.key].setValidators([this.validateEndDate]);
            this.formGroup.get(parent.key).controls[ind].controls[input.key].updateValueAndValidity();
          }
        });
      }
    } else {
      if (parent) {

        parent.formInputs[ind].forEach((input, index) => {
          if (input.key === 'prescriptionEndDate') {
            input.validators = [Validators.required, this.validateEndDate];
            input.editable = true;
            this.formGroup.get(parent.key).controls[ind].controls[input.key].clearValidators();
            this.formGroup.get(parent.key).controls[ind].controls[input.key].setValidators([Validators.required, this.validateEndDate]);
            this.formGroup.get(parent.key).controls[ind].controls[input.key].updateValueAndValidity();
          }
        });
      }
    }

    if (radio.value === 3 || radio.value === 4) {
      this.nextFourtyDays = new Date();
      this.nextFourtyDays.setDate(this.todayDate.getDate() + 63);
    } else {
      this.nextFourtyDays.setDate(this.todayDate.getDate() + 39);
    }
    this.clickedRadio.emit({ radio, field });
  }

  validateStartDate(control: FormControl) {
    var startDate = control.value;
    if (startDate === '') {
      return null;
    } else {
      startDate = new Date(startDate);
      var endDate = (control.parent !== undefined) ? control.parent.get('prescriptionEndDate').value : '';
      endDate = new Date(endDate);
      var found = endDate < startDate;
      if (found) {
        if (control.parent !== undefined) {
          control.parent.get('prescriptionEndDate').setErrors({ date: true });
        }
      } else {
        if (control.parent !== undefined) {
          control.parent.get('prescriptionEndDate').setErrors(null);
        }
      }
    }
    return null;
  }
  validateEndDate(control: FormControl): any {
    var endDate = control.value;
    if (endDate === '') {
      return null;
    } else {
      endDate = new Date(endDate);
      var startDate = (control.parent !== undefined) ? control.parent.get('prescriptionStartDate').value : '';
      startDate = (startDate !== '') ? new Date(startDate) : '';
      var found = startDate < endDate;
      return found ? null : { date: true };
    }
  }

  setInnerFieldValidators(field, innerField, arrayIndex, childIndex) {
    if (innerField.type === 'text') {
      this.formGroup.get(field.key).controls[arrayIndex].controls[innerField.key].setValidators([Validators.required, Validators.minLength(1), this.valideTextArea]);
      this.formGroup.get(field.key).controls[arrayIndex].controls[innerField.key].updateValueAndValidity();
    } else if (innerField.type === 'textarea') {
      this.formGroup.get(field.key).controls[arrayIndex].controls[innerField.key].setValidators([Validators.required, Validators.minLength(1), this.valideTextArea]);
      this.formGroup.get(field.key).controls[arrayIndex].controls[innerField.key].updateValueAndValidity();
    } else if (innerField.type === 'calendar') {
      if (innerField.key === 'prescriptionEndDate') {
        this.formGroup.get(field.key).controls[arrayIndex].controls[innerField.key].setValidators([this.validateEndDate]);
      } else if (field.key === 'prescriptionStartDate') {
        return [Validators.required, this.validateStartDate];
      } else {
        this.formGroup.get(field.key).controls[arrayIndex].controls[innerField.key].setValidators([Validators.required]);
      }
      this.formGroup.get(field.key).controls[arrayIndex].controls[innerField.key].updateValueAndValidity();
    } else if (innerField.type === 'radio') {
      this.formGroup.get(field.key).controls[arrayIndex].controls[innerField.key].setValidators([Validators.required]);
      this.formGroup.get(field.key).controls[arrayIndex].controls[innerField.key].updateValueAndValidity();
    }
  }

  clearValidators(field) {
    this.formGroup.controls[field.key].clearValidators();
    this.formGroup.controls[field.key].updateValueAndValidity();
    field.validators = [];
  }
  getDropValue(field, i, inputs?, formGroup?) {
    this.selectedPatientID.emit({patientID: field.value})
    this.timeDropDownValue.emit(field.value);
    this.formInputs = (inputs !== undefined) ? inputs : this.formInputs;
    this.formGroup = (formGroup !== undefined) ? formGroup : this.formGroup;
    let sliderObj = {
      slider: field,
      index: i
    };
    let sliderIndex = sliderObj.index;
    let sliderObjValue = field.value;
    this.addNewPatient.emit(field);
    let locationValue;
    if(field.key === 'Diagnosis'){
      this.getDropValueList.emit(field)
    }
    if (field.key === 'tmsType'){
      this.getDropValueList.emit(field)
    }
    // let stateDropDownList = ["MD","NY","VA","WA"];
    if(field.key == 'primaryholder'){
      field.list.forEach(element => {
        if(element.value == field.value){
          locationValue = element;
        }
      });
      console.log("{field,locationValue}", {field,locationValue});
      
      this.getDropValueList.emit({field,locationValue})
    }
    if(field.key == 'type_of_appointment'){
      field.list.forEach(element => {
        if(element.value == field.value){
          locationValue = element;
        }
      });
      this.getDropValueList.emit({field,locationValue})
    }
    if(field.key == 'preffered_location'){
        field.list.forEach(element => {
          if(element.value == field.value){
            locationValue = element;
          }
        });
        this.getDropValueList.emit({field,locationValue})
    }
    if(field.key == 'state'){
      if(stateDropDownList.includes(field.value)){
        field.list.forEach(element => {
          if(element.value == field.value){
            this.zipCodeRange = [Number(element.zipcodeFrom), Number(element.zipcodeTo)];
          }
        });
        // if(this.zipCodeRange){
        //   this.formGroup.controls['zipCode']['list'] = this.zipCodeRange
        //   if(this.formInputs[i+1].validators.length > 1){
        //     this.formGroup.controls['zipCode'].setValidators([Validators.required, this.formService.validateZIP, this.ValidateZipCode ])
        //   } else {
        //     this.formGroup.controls['zipCode'].setValidators([this.formService.validateZIP, this.ValidateZipCode ])
        //   }
        //   this.formGroup.controls['zipCode'].updateValueAndValidity();
        // }
      }
      else{
        if(this.formInputs[i+1].validators.length > 1){
          this.formGroup.controls['zipCode'].setValidators([Validators.required, this.formService.validateZIP ])
        } else {
          this.formGroup.controls['zipCode'].setValidators([this.formService.validateZIP ])
        }
        this.formGroup.controls['zipCode'].updateValueAndValidity();
      }
    }
    if (sliderObj.slider.key === 'primaryInsuranceProvider') {
      this.resetInsuranceDetails('primary');
      // tslint:disable-next-line: no-shadowed-variable
      for (let i = sliderIndex + 1; ; i++) {
        if (this.formInputs[i].key !== 'secondaryInsuranceProvider') {
          let sliderVisible = sliderObj.slider.list.find(x => x.insuranceId === sliderObjValue);
          let sliderVisibleValue = (sliderVisible !== undefined) ? sliderVisible.category : '';
          if (sliderVisibleValue !== '000' && sliderVisibleValue !== '') {
            this.formInputs[i].showontoggle = false;
            this.formInputs[i].value = '';
            if (this.formInputs[i].validators) {
              this.formGroup.controls[this.formInputs[i].key].clearValidators();
              this.formGroup.controls[this.formInputs[i].key].updateValueAndValidity();
              this.formInputs[i].validators = [];
            }
            this.formInputs.forEach(element => {
              if (element.key === 'policyHoldersUploadFront' || element.key === 'policyHoldersUploadBack' || element.key === 'policyHolderMemberId' || element.key === 'policyHolderName' || element.key === 'policyHolderDOB') {
                element.showontoggle = true;
                if ((this.adminTypeForm !== 'offlinePatient') || (this.route.snapshot.queryParams.edit === 'true' && this.adminTypeForm === 'offlinePatient')) {
                  this.setValidators(element);
                }
              }
            });
            /**
             * this.route.snapshot.queryParams.isOffile === '1' then patient is offline patient
             * this.route.snapshot.queryParams.isOffile === '0' then patient is not offline patient
             * earlier we are setting validation only for offline patients now we changed and setting the upload validations for all the patients
             */
            if ((this.adminTypeForm !== 'offlinePatient') || (this.route.snapshot.queryParams.edit === 'true' && this.adminTypeForm === 'offlinePatient')) {

              this.formGroup.controls['policyHoldersUploadFront'].setValidators([Validators.required, this.validateFileUpload]);
              this.formGroup.controls['policyHoldersUploadFront'].updateValueAndValidity();
              this.formGroup.controls['policyHoldersUploadBack'].setValidators([Validators.required, this.validateFileUpload]);
              this.formGroup.controls['policyHoldersUploadBack'].updateValueAndValidity();
            }
          } else {
            if ((this.adminTypeForm !== 'offlinePatient') || (this.route.snapshot.queryParams.edit === 'true' && this.adminTypeForm === 'offlinePatient')) {

              this.formGroup.controls['policyHoldersUploadFront'].clearValidators();
              this.formGroup.controls['policyHoldersUploadFront'].setValidators([this.validateFileUpload]);
              this.formGroup.controls['policyHoldersUploadFront'].updateValueAndValidity();
              this.formGroup.controls['policyHoldersUploadBack'].clearValidators();
              this.formGroup.controls['policyHoldersUploadBack'].setValidators([this.validateFileUpload]);
              this.formGroup.controls['policyHoldersUploadBack'].updateValueAndValidity();
            }
            if (sliderVisible === undefined && this.adminTypeForm !== undefined) {
              this.formInputs.forEach(element => {
                if (element.key === 'policyHoldersUploadFront' || element.key === 'policyHoldersUploadBack' || element.key === 'policyHolderMemberId' || element.key === 'policyHolderName' || element.key === 'policyHolderDOB') {
                  element.showontoggle = true;
                  element.validators = [];
                  this.formGroup.controls[element.key].clearValidators();
                  this.formGroup.controls[element.key].updateValueAndValidity();
                }
              });
              // console.log(this.formInputs, 'afrer N/A');
              if (this.formInputs[i].validators) {
                this.formGroup.controls[this.formInputs[i].key].clearValidators();
                this.formGroup.controls[this.formInputs[i].key].updateValueAndValidity();
                this.formInputs[i].validators = [];
              }
            } else if (sliderVisible && sliderVisible.insuranceName === 'N/A') {
              this.formInputs[i].showontoggle = false;
              this.formInputs.forEach(element => {
                if (element.key === 'policyHoldersUploadFront' || element.key === 'policyHoldersUploadBack' || element.key === 'policyHolderMemberId') {
                  element.showontoggle = false;
                  element.validators = [];
                  this.formGroup.controls[element.key].clearValidators();
                  this.formGroup.controls[element.key].updateValueAndValidity();
                }
              });
              // console.log(this.formInputs, 'afrer N/A');
              if (this.formInputs[i].validators) {
                this.formGroup.controls[this.formInputs[i].key].clearValidators();
                this.formGroup.controls[this.formInputs[i].key].updateValueAndValidity();
                this.formInputs[i].validators = [];
              }
            } else if (sliderVisible && sliderVisible.insuranceName === 'Other') {
              this.formInputs[i].showontoggle = true;
              this.formInputs[i].value = '';
              /**
               * the following code sets validations for policyHoldersUploadFront and policyHoldersUploadBack 
               * validation rules :
               * - mandatory and 
               * - accept appropriate file upload formats only
               * use cases : 
               * - insurance name is 'Other'
               * - not an offline patient
               * - an offline patient with edit mode
               */
              if ((this.adminTypeForm !== 'offlinePatient') || (this.route.snapshot.queryParams.edit === 'true' && this.adminTypeForm === 'offlinePatient')) {
                this.formGroup.controls['policyHoldersUploadFront'].setValidators([Validators.required, this.validateFileUpload]);
                this.formGroup.controls['policyHoldersUploadFront'].updateValueAndValidity();
                this.formGroup.controls['policyHoldersUploadBack'].setValidators([Validators.required, this.validateFileUpload]);
                this.formGroup.controls['policyHoldersUploadBack'].updateValueAndValidity();
              }
              this.formInputs.forEach(element => {
                if (element.key === 'policyHoldersUploadFront' || element.key === 'policyHoldersUploadBack' || element.key === 'policyHolderMemberId') {
                  element.showontoggle = true;
                  this.formGroup.controls[element.key].setValidators([Validators.required]);
                  this.formGroup.controls[this.formInputs[i].key].updateValueAndValidity();
                  if (element.key === 'policyHolderMemberId') {
                    if ((this.adminTypeForm !== 'offlinePatient') || (this.route.snapshot.queryParams.edit === 'true' && this.adminTypeForm === 'offlinePatient')) {
                      this.formGroup.controls[element.key].setValidators([Validators.required]);
                      this.formGroup.controls[this.formInputs[i].key].updateValueAndValidity();
                    }
                  }
                }
              });
              if (this.formInputs[i].validators) {
                if ((this.adminTypeForm !== 'offlinePatient') || (this.route.snapshot.queryParams.edit === 'true' && this.adminTypeForm === 'offlinePatient')) {
                  this.setValidators(this.formInputs[i]);
                }
              }
            } else if (sliderVisible === undefined && this.adminTypeForm === undefined) {
              this.formInputs.forEach(element => {
                if (element.key === 'policyHoldersUploadFront' || element.key === 'policyHoldersUploadBack' || element.key === 'policyHolderMemberId') {
                  element.showontoggle = true;
                  if ((this.adminTypeForm !== 'offlinePatient') || (this.route.snapshot.queryParams.edit === 'true' && this.adminTypeForm === 'offlinePatient')) {
                    this.setValidators(element);
                  }
                }
              });
            }
          }
          this.hideUnneccesaryFields(sliderObj.slider.list);
          let count = 0;
          this.formInputs.forEach(element => {
            if (element.key === 'primaryInsuranceProvider' || element.key === 'secondaryInsuranceProvider') {
              if (element.value === 39) {
                count = count + 1;
              }
            }
          });
          if (count === 2) {
            this.formInputs[11].showontoggle = false;
          } else {
            this.formInputs[11].showontoggle = true;
          }
        } else {
          break;
        }
      }
    } else if (sliderObj.slider.key === 'secondaryInsuranceProvider') {
      this.resetInsuranceDetails('secondary');
      // tslint:disable-next-line: no-shadowed-variable
      for (let i = sliderIndex + 1; ; i++) {
        if (this.formInputs[i].key !== 'policyHolderHeader') {
          let sliderVisible = sliderObj.slider.list.find(x => x.insuranceId === sliderObjValue);
          let sliderVisibleValue = (sliderVisible !== undefined) ? sliderVisible.category : '';
          if (sliderVisibleValue !== '000' && sliderVisibleValue !== '') {
            this.formInputs[i].showontoggle = false;
            this.formInputs[i].value = '';
            if (this.formInputs[i].validators) {
              this.formGroup.controls[this.formInputs[i].key].clearValidators();
              this.formGroup.controls[this.formInputs[i].key].updateValueAndValidity();
              this.formInputs[i].validators = [];
            }
            this.formInputs.forEach(element => {
              if (element.key === 'secondaryPolicyHoldersUploadFront' || element.key === 'secondaryPolicyHoldersUploadBack' || element.key === 'secondaryPolicyHolderMemberId' || element.key === 'policyHolderName' || element.key === 'policyHolderDOB') {
                element.showontoggle = true;
                if ((this.adminTypeForm !== 'offlinePatient') || (this.route.snapshot.queryParams.edit === 'true' && this.adminTypeForm === 'offlinePatient')) {
                  this.setValidators(element);
                }
              }
            });
            if ((this.adminTypeForm !== 'offlinePatient') || (this.route.snapshot.queryParams.edit === 'true' && this.adminTypeForm === 'offlinePatient')) {
              // if (this.formGroup.controls['primaryInsuranceProvider'].value != 1 && this.formGroup.controls['primaryInsuranceProvider'].value != 'other') {
              this.formGroup.controls['secondaryPolicyHoldersUploadFront'].setValidators([Validators.required, this.validateFileUpload]);
              this.formGroup.controls['secondaryPolicyHoldersUploadFront'].updateValueAndValidity();
              this.formGroup.controls['secondaryPolicyHoldersUploadBack'].setValidators([Validators.required, this.validateFileUpload]);
              this.formGroup.controls['secondaryPolicyHoldersUploadBack'].updateValueAndValidity();
            }
            // }
          } else {
            if ((this.adminTypeForm !== 'offlinePatient') || (this.route.snapshot.queryParams.edit === 'true' && this.adminTypeForm === 'offlinePatient')) {
              this.formGroup.controls['secondaryPolicyHoldersUploadFront'].clearValidators();
              this.formGroup.controls['secondaryPolicyHoldersUploadFront'].setValidators([this.validateFileUpload]);
              this.formGroup.controls['secondaryPolicyHoldersUploadFront'].updateValueAndValidity();
              this.formGroup.controls['secondaryPolicyHoldersUploadBack'].clearValidators();
              this.formGroup.controls['secondaryPolicyHoldersUploadBack'].setValidators([this.validateFileUpload]);
              this.formGroup.controls['secondaryPolicyHoldersUploadBack'].updateValueAndValidity();
            }
            if (sliderVisible === undefined && this.adminTypeForm !== undefined) {
              this.formInputs.forEach(element => {
                if (element.key === 'secondaryPolicyHoldersUploadFront' || element.key === 'secondaryPolicyHoldersUploadBack' || element.key === 'secondaryPolicyHolderMemberId' || element.key === 'policyHolderName' || element.key === 'policyHolderDOB') {
                  element.showontoggle = true;
                  element.validators = [];
                  this.formGroup.controls[element.key].clearValidators();
                  this.formGroup.controls[element.key].updateValueAndValidity();
                }
              });
              // console.log(this.formInputs, 'afrer N/A');
              if (this.formInputs[i].validators) {
                this.formGroup.controls[this.formInputs[i].key].clearValidators();
                this.formGroup.controls[this.formInputs[i].key].updateValueAndValidity();
                this.formInputs[i].validators = [];
              }
            } else if (sliderVisible && sliderVisible.insuranceName === 'N/A') {
              this.formInputs[i].showontoggle = false;
              this.formInputs.forEach(element => {
                if (element.key === 'secondaryPolicyHoldersUploadFront' || element.key === 'secondaryPolicyHoldersUploadBack' || element.key === 'secondaryPolicyHolderMemberId') {
                  element.showontoggle = false;
                  element.validators = [];
                  this.formGroup.controls[element.key].clearValidators();
                  this.formGroup.controls[element.key].updateValueAndValidity();
                }
              });
              // console.log(this.formInputs, 'afrer N/A');
              if (this.formInputs[i].validators) {
                this.formGroup.controls[this.formInputs[i].key].clearValidators();
                this.formGroup.controls[this.formInputs[i].key].updateValueAndValidity();
                this.formInputs[i].validators = [];
              }
            } else if (sliderVisible && sliderVisible.insuranceName === 'Other') {
              this.formInputs[i].showontoggle = true;
              this.formInputs[i].value = '';
              /**
              * the following code sets validations for secondaryPolicyHoldersUploadFront and secondaryPolicyHoldersUploadBack
              * validation rules :
              * - mandatory and 
              * - accept appropriate file upload formats only
              * use cases : 
              * - insurance name is 'Other'
              * - not an offline patient
              * - an offline patient with edit mode
              */
              if ((this.adminTypeForm !== 'offlinePatient') || (this.route.snapshot.queryParams.edit === 'true' && this.adminTypeForm === 'offlinePatient')) {
                this.formGroup.controls['secondaryPolicyHoldersUploadFront'].setValidators([Validators.required, this.validateFileUpload]);
                this.formGroup.controls['secondaryPolicyHoldersUploadFront'].updateValueAndValidity();
                this.formGroup.controls['secondaryPolicyHoldersUploadBack'].setValidators([Validators.required, this.validateFileUpload]);
                this.formGroup.controls['secondaryPolicyHoldersUploadBack'].updateValueAndValidity();
              }
              this.formInputs.forEach(element => {
                if (element.key === 'secondaryPolicyHoldersUploadFront' || element.key === 'secondaryPolicyHoldersUploadBack' || element.key === 'secondaryPolicyHolderMemberId') {
                  element.showontoggle = true;

                  this.formGroup.controls[element.key].setValidators([Validators.required]);
                  this.formGroup.controls[this.formInputs[i].key].updateValueAndValidity();

                  if (element.key === 'secondaryPolicyHolderMemberId') {
                    if ((this.adminTypeForm !== 'offlinePatient') || (this.route.snapshot.queryParams.edit === 'true' && this.adminTypeForm === 'offlinePatient')) {
                      this.formGroup.controls[element.key].setValidators([Validators.required]);
                      this.formGroup.controls[this.formInputs[i].key].updateValueAndValidity();
                    }
                  }
                }
              });
              if (this.formInputs[i].validators) {
                if ((this.adminTypeForm !== 'offlinePatient') || (this.route.snapshot.queryParams.edit === 'true' && this.adminTypeForm === 'offlinePatient')) {
                  this.setValidators(this.formInputs[i]);
                }
              }
            } else if (sliderVisible === undefined && this.adminTypeForm === undefined) {
              this.formInputs.forEach(element => {
                if (element.key === 'secondaryPolicyHoldersUploadFront' || element.key === 'secondaryPolicyHoldersUploadBack' || element.key === 'secondaryPolicyHolderMemberId') {
                  element.showontoggle = true;
                  if ((this.adminTypeForm !== 'offlinePatient') || (this.route.snapshot.queryParams.edit === 'true' && this.adminTypeForm === 'offlinePatient')) {
                    // this.setValidators(element);
                  }
                }
              });
            }
          }
          this.hideUnneccesaryFields(sliderObj.slider.list);
          let count = 0;
          this.formInputs.forEach(element => {
            if (element.key === 'primaryInsuranceProvider' || element.key === 'secondaryInsuranceProvider') {
              if (element.value === 39) {
                count = count + 1;
              }
            }
          });
          if (count === 2) {
            this.formInputs[11].showontoggle = false;
          } else {
            this.formInputs[11].showontoggle = true;
          }
        } else {
          break;
        }
      }
    } else if (sliderObj.slider.key === 'pharmacyName') {
      let formInput = this.formInputs.find(x => x.key === 'pharmacyName');
      let value: any = this.formGroup.controls['pharmacyName'].value || formInput.value;
      value = formInput.list.find(x => x.value === value);
      if (value && value.pharmacyName === 'Other') {
        this.formInputs.some(x => {
          if (x.key === 'pharmacyNameText') {
            x.showontoggle = true;
            return true;
          }

        });
        if ((this.adminTypeForm !== 'offlinePatient') || (this.route.snapshot.queryParams.edit === 'true' && this.adminTypeForm === 'offlinePatient')) {
          this.formGroup.controls['pharmacyNameText'].setValidators(Validators.required);
          this.formGroup.controls['pharmacyNameText'].updateValueAndValidity();
        }
      } else {
        if ((this.adminTypeForm !== 'offlinePatient') || (this.route.snapshot.queryParams.edit === 'true' && this.adminTypeForm === 'offlinePatient')) {
          this.formGroup.controls['pharmacyNameText'].value = '';
          this.formGroup.controls['pharmacyNameText'].clearValidators();
          this.formGroup.controls['pharmacyNameText'].updateValueAndValidity();
        }
        this.formInputs.some(x => {
          if (x.key === 'pharmacyNameText') {
            x.value = '';
            x.showontoggle = false;
            return true;
          }

        });
      }
    }

    if (field.key === 'country') {
      this.residingInFields(field.value);
    }
  }
  residingInFields(country) {
    console.log(this.formGroup.controls);
    if (country.toLowerCase() === 'india') {
      this.formGroup.controls['aadhar'].value = '';
      this.formGroup.controls['pan'].value = '';
      // tslint:disable-next-line:no-unused-expression
      this.formGroup.controls['ssn'] !== undefined ? this.formGroup.controls['ssn'].value = '' : null;
      this.formGroup.controls['drivingLicense'].value = '';
      this.formGroup.controls['zip'].value = '';
      // tslint:disable-next-line:no-unused-expression
      this.formGroup.controls['ssn'] !== undefined ? this.formGroup.controls['ssn'].setErrors(null) : null;
      this.formGroup.controls['drivingLicense'].setErrors(null);
      this.formGroup.controls['aadhar'].setValidators([Validators.required]);
      this.formGroup.controls['pan'].setValidators([Validators.required, this.formService.validatePan]);
      this.formGroup.controls['aadhar'].updateValueAndValidity();
      this.formGroup.controls['pan'].updateValueAndValidity();
      this.formGroup.controls['zip'].clearValidators();
      this.formGroup.controls['zip'].setValidators([Validators.required, this.formService.validatePIN]);
      this.formGroup.controls['zip'].updateValueAndValidity();
      this.formInputs.forEach(eachField => {
        if (eachField.key === 'aadhar') {
          eachField.showontoggle = true;
          eachField.value = '';
        }
        if (eachField.key === 'ssn') {
          eachField.showontoggle = false;
          eachField.value = '';
        }
        if (eachField.key === 'drivingLicense') {
          eachField.showontoggle = false;
          eachField.value = '';
        }
        if (eachField.key === 'pan') {
          eachField.showontoggle = true;
          eachField.value = '';
        }
        if (eachField.key === 'zip') {
          eachField.value = '';
          eachField.label = 'PIN Code';
          eachField.validators = [Validators.required, this.formService.validatePIN];
          eachField.emptyValueMessage = 'Please enter pin code';
          eachField.validationValue = 'Please enter valid 6 digit pin code';
        }
      });
    } else {
      this.formGroup.controls['aadhar'].value = '';
      this.formGroup.controls['pan'].value = '';
      this.formGroup.controls['zip'].value = '';
      // tslint:disable-next-line:no-unused-expression
      this.formGroup.controls['ssn'] !== undefined ? this.formGroup.controls['ssn'].value = '' : null;
      this.formGroup.controls['drivingLicense'].value = '';
      this.formGroup.controls['aadhar'].setErrors(null);
      this.formGroup.controls['pan'].setErrors(null);
      // tslint:disable-next-line:no-unused-expression
      this.formGroup.controls['ssn'] !== undefined ? this.formGroup.controls['ssn'].setValidators([Validators.required]) : null;
      this.formGroup.controls['drivingLicense'].setValidators([Validators.required, this.formService.validateAplhaNumeric]);
      this.formGroup.controls['zip'].clearValidators();
      this.formGroup.controls['zip'].setValidators([Validators.required, this.formService.validateZIP]);
      this.formGroup.controls['zip'].updateValueAndValidity();
      this.formInputs.forEach(eachField => {
        if (eachField.key === 'aadhar') {
          eachField.showontoggle = false;
          eachField.value = '';
        }
        if (eachField.key === 'ssn') {
          eachField.showontoggle = true;
          eachField.value = '';
        }
        if (eachField.key === 'drivingLicense') {
          eachField.showontoggle = true;
          eachField.value = '';
        }
        if (eachField.key === 'pan') {
          eachField.showontoggle = false;
          eachField.value = '';
        }
        if (eachField.key === 'zip') {
          eachField.value = '';
          eachField.label = 'ZIP Code';
          eachField.validators = [Validators.required, this.formService.validateZIP];
          eachField.emptyValueMessage = 'Please enter zip code';
          eachField.validationValue = 'Please enter valid 5 digit zip code';
        }
      });
    }
  }
  hideUnneccesaryFields(list) {
    let unneccesaryFields = ['policyHolderGroupNumber', 'policyHolderMentalHealthNumber'];
    let primaryInsuranceProviderForminput = this.formInputs.find(x => x.key === 'primaryInsuranceProvider');
    let secondaryInsuranceProviderrForminput = this.formInputs.find(x => x.key === 'secondaryInsuranceProvider');

    let primaryInsuranceProvider: any = this.formGroup.controls['primaryInsuranceProvider'].value || (primaryInsuranceProviderForminput ? primaryInsuranceProviderForminput.value : undefined);
    let secondaryInsuranceProvider: any = this.formGroup.controls['secondaryInsuranceProvider'].value || (secondaryInsuranceProviderrForminput ? secondaryInsuranceProviderrForminput.value : undefined);
    primaryInsuranceProvider = list.find(x => x.insuranceId === primaryInsuranceProvider);
    secondaryInsuranceProvider = list.find(x => x.insuranceId === secondaryInsuranceProvider);
    let hide;
    if (primaryInsuranceProvider && secondaryInsuranceProvider && primaryInsuranceProvider.insuranceName === 'N/A' && secondaryInsuranceProvider.insuranceName === 'N/A') {
      hide = true;
    } else {
      hide = false;
    }
    if (hide) {
      unneccesaryFields.forEach(field => {
        this.formGroup.controls[field].clearValidators();
        this.formGroup.controls[field].updateValueAndValidity();
      });
      this.formInputs.forEach(element => {
        if (unneccesaryFields.indexOf(element.key) !== -1) {
          element.showontoggle = false;
          element.validators = [];
        }
      });
    } else {
      unneccesaryFields.forEach(field => {
        if (field !== 'policyHolderGroupNumber') {
          if (primaryInsuranceProvider !== undefined && secondaryInsuranceProvider !== undefined) {
            if ((this.adminTypeForm !== 'offlinePatient') || (this.route.snapshot.queryParams.edit === 'true' && this.adminTypeForm === 'offlinePatient')) {
              this.formGroup.controls[field].setValidators([Validators.required]);
              this.formGroup.controls[field].updateValueAndValidity();
            }
          }
        }
      });
      this.formInputs.forEach(element => {
        if (unneccesaryFields.indexOf(element.key) !== -1) {
          element.showontoggle = true;
          if (element.key !== 'policyHolderGroupNumber') {
            if (primaryInsuranceProvider !== undefined && secondaryInsuranceProvider !== undefined) {
              if ((this.adminTypeForm !== 'offlinePatient') || (this.route.snapshot.queryParams.edit === 'true' && this.adminTypeForm === 'offlinePatient')) {
                element.validators = [Validators.required];
              }
            }
          }
        }
      });
    }
  }

  resetInsuranceDetails(insurance) {
    let updateFields;
    let uploadFields;
    if (insurance === 'primary') {
      updateFields = ['policyHolderDOB', 'policyHolderEmployer', 'policyHolderGroupNumber', 'policyHolderMemberId', 'policyHolderMentalHealthNumber', 'policyHolderName'];
      uploadFields = ['policyHoldersUploadBack', 'policyHoldersUploadFront'];
    } else {
      updateFields = ['policyHolderDOB', 'policyHolderEmployer', 'policyHolderGroupNumber', 'secondaryPolicyHolderMemberId', 'policyHolderMentalHealthNumber', 'policyHolderName'];
      uploadFields = ['secondaryPolicyHoldersUploadBack', 'secondaryPolicyHoldersUploadFront'];
    }
    this.formInputs.forEach(field => {
      if (uploadFields.indexOf(field.key) !== -1) {
        field.value = '';
        this.formGroup.controls[field.key].setValue('');
      }
    });
    updateFields.forEach(field => {
      this.formGroup.controls[field].setValue('');
    });
    this.formGroup.controls['sameAsPatient'].setValue(false);
    let sameAsPatientFieldIndex;
    let sameAsPatientField = this.formInputs.find((x, index) => {
      if (x.key === 'sameAsPatient') {
        sameAsPatientFieldIndex = index;
        return true;
      }
    });
    sameAsPatientField.selected = false;
    this.checkboxchange(sameAsPatientField, sameAsPatientFieldIndex);
  }

  // validation for file Upload
  validateFileUpload(control: FormControl): any {
    if (control.value !== '' && control.value !== undefined && control.value != null) {
      let name = control.value;
      if (control.value.indexOf('https') !== -1 && control.value.indexOf('AWSAccessKeyId') !== -1) {
        name = decodeURIComponent(name);
        name = name.substring(
          name.lastIndexOf('__name__') + 8,
          name.lastIndexOf('?')
        );
      }
      var allowedExtensions = new Array('jpg', 'png', 'gif', 'jpeg', 'tiff', 'psd', 'pdf', 'esp', 'ai', 'indd', 'raw', 'heic', 'heif');
      var fileExtension = name.split('.').pop().toLowerCase(); // split function will split the filename by dot(.), and pop function will pop the last element from the array which will give you the extension as well. If there will be no extension then it will return the filename.
      var valid = allowedExtensions.includes(fileExtension);
      return valid ? null : { file: true };
    } else {
      return null;
    }
  }

  setValidators(field) {
    if (field.type === 'text' && (field.key === INTAKE_FORM_KEYS.PREV_PRESCRIBER_EMAIL || field.key === INTAKE_FORM_KEYS.THERAPIST_EMAIL)) {
      this.formGroup.controls[field.key].setValidators([Validators.required, this.formService.validateEmail, this.formService.validateTherapistEmailValue(this.formService.therapistEmailList)]);
    } else if (field.type === 'text' && field.key === INTAKE_FORM_KEYS.THERAPIST_NAME) {
      this.formGroup.controls[field.key].setValidators([Validators.required, this.formService.validateTherapistName]);
    } else if (field.type === 'text') {
      this.formGroup.controls[field.key].setValidators([Validators.required, this.valideTextArea]);
    } else if (field.type === 'textarea') {
      this.formGroup.controls[field.key].setValidators([Validators.required, Validators.minLength(1), this.valideTextArea]);
    } else if (field.type === 'calendar') {
      this.formGroup.controls[field.key].setValidators([Validators.required]);
    } else if (field.type === 'upload-image') {
      this.formGroup.controls[field.key].setValidators([Validators.required, this.formService.validateFileUpload]);
    } else if (field.type === 'documentUpload' && field.required) {
      this.formGroup.controls[field.key].setValidators([this.formService.validateFilesUpload]);
    } else if (field.type === 'checkbox') {
      this.formGroup.controls[field.key].setValidators([Validators.required,this.formService.validateCheckBox]);
    } else {
      this.formGroup.controls[field.key].setValidators([Validators.required]);
    }
    this.formGroup.controls[field.key].updateValueAndValidity();
  }



  // validation for Text area
  valideTextArea(control: FormControl): any {
    if (control.value !== '' && control.value !== undefined && control.value !== null) {
      var regex = /^[^.\s]/;
      var found = control.value.length > 0 && regex.test(control.value);
      return found ? null : { name: true };
    } else {
      return null;
    }
  }

  // validation for Name
  validateName(control: FormControl): any {
    if (control.value !== '' && control.value !== undefined) {
      const nameRegexp: RegExp = /[!@#$%^&*()+\-=\[\]{};:"\\|~`,.<>\/?]/;
      // var regex = /^[a-zA-Z0-9 ]{0,}[a-zA-Z0-9 ][a-zA-Z0-9 ]{0,}/g;
      var found = nameRegexp.test(control.value);
      return found ? { name: true } : null;
    } else {
      return null;
    }
  }

  removeOnToggle(field, tag, addnewField) {
    let elementLastIndx;
    let addNewIndx;
    let index = this.formTags.indexOf(tag);
    this.formService.medicalHistoryFormConfig.forEach((element, i) => {
      if (element.key === field.key) {
        elementLastIndx = i;
      }
      if (element.key === addnewField) {
        addNewIndx = i;
        element.showontoggle = true;
        // this.formGroup.controls[element.key].status = 'INVALID';
      }
    });
    // console.log(field,'delete fielddd')
    // console.log('delete element last index', elementLastIndx);
    this.formService.medicalHistoryFormConfig.splice(elementLastIndx, (addNewIndx - elementLastIndx));
    this.form[index] = this.formService.dynamicInitForm(this.formService.medicalHistoryFormConfig, this.formService.formTypes[tag]);
    this.formGroup = this.form[index].formGroup;
    console.log(this.formService.medicalHistoryFormConfig, 'form config dataaaaa');
  }

  // calender functionality ends here


  cancel(event): void {
    this.formGroup.reset();
    this.formSubmitAttempt = false;
    this.cancelFormEvent.emit({ e: event });
  }
  getFieldData(field) {
    // console.log(field);
  }
  cancelForm(event) {
    this.formGroup.reset();
    this.cancelEvent.emit({ e: event });
  }


  /**
   * @description
   * submit This function will submit the form after checking for all the validations
   */
  submit(event): void {
    this.formSubmitAttempt = true;
    let emitForm = true;
    console.log("form submitted")
    if (this.formGroup.valid) {
      this.submitted = false;
      this.resetCalenderInput = true;
      // --code for dropdown
      // --dropdown value comparing checking
      if (this.formGroup.value.sourceMetadataList) {
        if (this.formGroup.value.sourceMetadataList.label === this.formGroup.value.targetMetadataList.label) {
          this.formGroup.controls['sourceMetadataList'].status = 'INVALID';
          this.formGroup.controls['targetMetadataList'].status = 'INVALID';
          emitForm = false;
        }
      }
    } else {
      emitForm = false;
    }

    if (emitForm) {
      this.submitEvent.emit({ e: event, formData: this.formGroup });
      // --reset input field values
      this.formGroup.reset();
      this.formSubmitAttempt = false;
    }
  }

  addForm() {
    this.formSubmitAttempt = true;
    if (this.formGroup.valid) {
      this.formSubmitAttempt = false;
      if (this.formGroup.value.startValue && this.formGroup.value.endValue) {
        // tslint:disable-next-line: radix
        if (parseInt(this.formGroup.value.startValue) < parseInt(this.formGroup.value.endValue)) {
          this.submitEvent.emit(this.formGroup);
        } else {
          this.formInputs[0].updateOn = 'blur';
          this.formInputs[1].updateOn = 'blur';
          this.formGroup.controls['startValue'].status = 'INVALID';
          this.formGroup.controls['endValue'].status = 'INVALID';
        }
      } else {
        this.submitEvent.emit(this.formGroup);
      }
    }
  }

  /**
   * this function is used to check error for a given input control
   * @param control {FormControl} - form control to be validated
   * @param formSubmitted {boolean} - to validate the form on form submission
   */
  isInvalid(control, formSubmitted) {
    return ((control.invalid && (formSubmitted || this.forceFieldBlur) && (control.touched || control.dirty)) ||
      ((formSubmitted || this.forceFieldBlur) && control.invalid)
    );

  }

  /**
   *
   * @param passwordField
   */
  togglePassword(passwordField, index) {
    // console.log(passwordField);
    if (passwordField) {
      passwordField.isShowpassword = !passwordField.isShowpassword;
      this.showPassword = !this.showPassword;
      if (passwordField.type === 'password') {
        passwordField.type = 'text';
      } else {
        passwordField.type = 'password';
      }
    }
  }


  onFileChange(event, uploadEvent) {
    if (event.target.files.length > 0) {
      this.file = event.target.files[0];
      this.fileName = this.file.name;
      if (uploadEvent) {
        this.fileUploadEvent.emit({ e: event, formData: this.formGroup, file: this.file });
      }
    }
  }

  inc(index: string | number, key: string | number) {
    this.formInputs[index].value += 1;
    this.formGroup.controls[key].value = this.formInputs[index].value;
  }
  dec(index: string | number, key: string | number) {
    if (this.formInputs[index].value > 0) {
      this.formInputs[index].value -= 1;
      this.formGroup.controls[key].value = this.formInputs[index].value;
    }
  }

  taskSelect(event: any) {
    if (event) {
      // console.log(event)
    }
  }

  /**
   * on value change in the input.
   * @param event click event
   */
   onKeyPress(event: any, field) {
     if (field.type === 'number') {
       if ((event.which < 48) || (event.which > 57)) {
         event.preventDefault();
       }
     } else if (field.type === 'text') {
      if ((event.which < 47) || (event.which > 57)) {
        event.preventDefault();
      }
     }
  }

  actionField(event?) {

    this.formSubmitAttempt = true;
    console.log(this.formGroup);
    console.log(this.formGroup.valid);
    this.formGroup.markAllAsTouched();

    if (this.formGroup.valid) {
      this.submitEvent.emit(this.formGroup);
      this.formSubmitAttempt = false;
    } else {
      this.inValidSubmitEvent.emit(this.formGroup);
      this.forceFieldBlur = true;
    }
  }

  gotoForgotPin(path: string) {
    this.forgotPasswordClicked.emit(path);
  }
  imageUpload(event, field, i) {
    let fileName = event.target.files && event.target.files[0] ? event.target.files[0]['name'].split('.')[0] : null;
    // tslint:disable-next-line:whitespace
    let regex: any = /[!@#$%^&*()+\-=\[\]{};':"\\|,.~`<>\/?]+/;
    let status = regex.test(fileName);
    if (fileName && status) {
      this.observableHelperService.showToast(TOAST_STATUSES.ERROR, 'Filename should not contain special characters. Please upload with a different name.');
      this.formGroup.controls[field.key].value = '';
      this.formGroup.controls[field.key].updateValueAndValidity();
    } else {
      field.showImage = false;
      if (this.adminTypeForm === 'offlinePatient') {
        this.formGroup.controls[field.key].setValidators([this.validateFileUpload]);
        this.formGroup.controls[field.key].updateValueAndValidity();
      }
      if (event.target.files && event.target.files[0]) {
        const reader = new FileReader();
        var file = event.target.files[0];
        reader.readAsDataURL(file);
        reader.onload = () => {
          field.value = file;
        };
        field.loading = true;
        let patientData: any = {};
        try {
          console.log(this.formconfig);
          // tslint:disable-next-line: no-shadowed-variable
          this.form[0].formInputs.forEach(field => {
            if (field.key === 'firstName' || field.key === 'lastName' || field.key === 'dob') {
              if (field.key === 'dob') {
                let formattedDate = typeof (field.value) === 'string' && field.value.indexOf('/') !== -1;
                if (!formattedDate) {
                  var d = new Date(field.value);
                  var month = '' + (d.getMonth() + 1);
                  var day = '' + d.getDate();
                  var year = d.getFullYear();

                  if (month.length < 2) {
                    month = '0' + month;
                  }
                  if (day.length < 2) {
                    day = '0' + day;
                  }
                  patientData[field.key] = [year, month, day].join('-');
                } else {
                  let date = field.value.split('/');
                  patientData[field.key] = date[2] + '-' + date[0] + '-' + date[1];
                }
              } else {
                patientData[field.key] = field.value;

              }
            }
          });
          if (this.formGroup.controls[field.key].valid) {
            this.isFileUploadPending.emit(true);
            let type;
            if (!file.type) {
              type = file.name.split('.').pop().toLowerCase();
            }
            let getUploadUrls = {
              type: 'POST',
              url: 'getUploadUrl',
              isDeveloper: true,
              donotShowLoader: true,
              body: {
                fileNames: [{ key: field.key, name: this.accentedtoNormalFun(file.name), contentType: file.type || type }],
                userId: this.userDetails.userId,
                userName: 'temp-new-tele-doc/' + this.accentedtoNormalFun(this.userDetails.firstName) + this.accentedtoNormalFun(this.userDetails.lastName),
                // userName: this.userDetails.firstName + this.userDetails.lastName,
                patientName: this.accentedtoNormalFun(patientData['firstName']) + this.accentedtoNormalFun(patientData['lastName']) + '__' + patientData['dob']
              },
            };

            this.http.makeHttpRequest(getUploadUrls).subscribe(res => {
              let uploadPayload = {
                type: 'PUT',
                url: res[field.key],
                body: file,
                isExternal: true,
                donotShowLoader: true,
                contentType: file.type || type
              };
              this.http.makeHttpRequest(uploadPayload).subscribe(response => {
                console.log('image - ' + response);
                this.isFileUploadPending.emit(false);
                if (response && response.status === false) {
                  let message = 'Failed to upload ' + field.readableLabel + '. Please try again';
                  this.observableHelperService.showToast(TOAST_STATUSES.ERROR, message);
                  field.value = '';
                  this.formGroup.controls[field.key].value = '';
                  field.showImage = true;
                } else {
                  field.value = res[field.key];
                }
                field.loading = false;
              });

            });
          } else {
            field.loading = false;

          }
        } catch (err) {
          this.isFileUploadPending.emit(false);
          field.loading = false;
          field.value = '';
          console.log('file not uploaded');
        }
      }
    }
  }

  // Image upload when uploads have multple in form array group
  imageUploadFormArray(event, field, i, formArrayGroup) {
    let fileName = event.target.files && event.target.files[0] ? event.target.files[0]['name'].split('.')[0] : null;
    // tslint:disable-next-line:whitespace
    let regex: any = /[!@#$%^&*()+\-=\[\]{};':"\\|,.`~<>\/?]+/;
    let status = regex.test(fileName);
    if (fileName && status) {
      this.observableHelperService.showToast(TOAST_STATUSES.ERROR, 'Filename should not contain special characters. Please upload with a different name.');
      this.formGroup.controls[field.key].value = '';
      this.formGroup.controls[field.key].updateValueAndValidity();
    } else {
      field.showImage = true;
      var getUploadUrls;
      let emptyFalg = false;
      if (event.target.files && event.target.files[0]) {
        const reader = new FileReader();
        var file = event.target.files[0];
        reader.readAsDataURL(file);
        reader.onload = () => {
          field.value = file;
        };
        field.loading = true;
        let employee: any = {};
        try {
          // tslint:disable-next-line: no-shadowed-variable
          this.formInputs.forEach(field => {
            if (field.key === 'firstName' || field.key === 'lastName' || field.key === 'dob') {
              if (field.key === 'dob') {
                let formattedDate = typeof (field.value) === 'string' && field.value.indexOf('/') !== -1;
                if (!formattedDate) {
                  var d = new Date(field.value);
                  var month = '' + (d.getMonth() + 1);
                  var day = '' + d.getDate();
                  var year = d.getFullYear();

                  if (month.length < 2) {
                    month = '0' + month;
                  }
                  if (day.length < 2) {
                    day = '0' + day;
                  }
                  employee[field.key] = [year, month, day].join('-');
                } else {
                  let date = field.value.split('/');
                  employee[field.key] = date[2] + '-' + date[0] + '-' + date[1];
                }
              } else {
                employee[field.key] = field.value;
              }
            }
          });
          var allowedExtensions = new Array('jpg', 'png', 'gif', 'jpeg', 'tiff', 'psd', 'pdf', 'esp', 'ai', 'indd', 'raw', 'heic', 'heif');
          var fileExtension = file.type.split('/').pop().toLowerCase(); // split function will split the filename by dot(.), and pop function will pop the last element from the array which will give you the extension as well. If there will be no extension then it will return the filename.
          var valid = allowedExtensions.includes(fileExtension);
          this.formGroup.controls[formArrayGroup.key].setErrors(valid ? null : { file: true });
          if (this.formGroup.controls[formArrayGroup.key].status === 'VALID') {
            this.isFileUploadPending.emit(true);
            let type;
            if (!file.type) {
              type = file.name.split('.').pop().toLowerCase();
            }
            getUploadUrls = {
              type: 'POST',
              url: 'getUploadUrl',
              isDeveloper: true,
              donotShowLoader: true,
              body: {
                fileNames: [{ key: field.key, name: this.accentedtoNormalFun(file.name), contentType: file.type || type }],
                userId: this.userDetails.userId,
                userName: 'temp-new-tele-doc/' + this.accentedtoNormalFun(this.userDetails.firstName) + this.accentedtoNormalFun(this.userDetails.lastName),
                // userName: this.userDetails.firstName + this.userDetails.lastName,
                patientName: this.accentedtoNormalFun(employee['firstName']) + this.accentedtoNormalFun(employee['lastName']) + '__' + employee['dob']
              },
            };
            this.http.makeHttpRequest(getUploadUrls).subscribe(res => {
              let uploadPayload = {
                type: 'PUT',
                url: res[field.key],
                body: file,
                isExternal: true,
                donotShowLoader: false,
                contentType: file.type || type
              };
              this.http.makeHttpRequest(uploadPayload).subscribe(response => {
                this.detailsUploads.emit({ innerField: field, value: res[field.key], type: 'POST' });
                field.loading = false;
                 this.isFileUploadPending.emit(false);
              });
            });
          } else {
            field.loading = false;
             this.isFileUploadPending.emit(false);

          }
        } catch (err) {
          field.loading = false;
           this.isFileUploadPending.emit(false);
          field.value = '';
        }
      }
      if (formArrayGroup.key === 'uploads' && this.formGroup.controls[formArrayGroup.key].status === 'VALID') {
        field.showImage = false;
        let newfield = { ...field };
        newfield.index = newfield.index + 1;
        newfield.key = 'uploadDocument';
        newfield.value = '';
        formArrayGroup.formInputs[0].push(newfield);
        this.addNewCreation(newfield, 'EMPCREATION', '');
      }
    }
  }
  showUpload(event, field, innerField) {
    // console.log('filed - ',field)
    // console.log('innerField - ',innerField)
    if (field.type === 'formArray1' && field.key === 'uploads') {
      let payload = {
        type: 'DELETE',
        url: 'uploadFileDelete',
        body: {
          uploadId: innerField.uploadId,
          uploadType: 'employee',
          refId: this.userDetails.userId,
          docUrl: innerField.value
        },
        isDeveloper: true,
      };
      this.http.makeHttpRequest(payload).subscribe((res) => {
        if (this.http.isSuccessfulResponse(res)) {
          let fieldsArray = field.formInputs[0];
          fieldsArray.splice(innerField.index, 1);
          fieldsArray.forEach((element, index) => {
            element.index = index;
            // tslint:disable-next-line:no-unused-expression
            element.value !== '' ? element.key = 'uploadDocument' + index : null;
            this.addNewCreation(element, 'EMPCREATION', '');
          });
          this.observableHelperService.showToast(TOAST_STATUSES.SUCCESS, res.data);
        } else {
          let message = 'Failed to delete file. Please try again';
          message = (res.error) ? res.error.message : message;
          this.observableHelperService.showToast(TOAST_STATUSES.ERROR, message);
        }
      });
    } else {
      field.value = '';
      this.formGroup.controls[field.key].value = '';
      field.showImage = true;
      if(field.required){
        this.formGroup.controls[field.key].setValidators([Validators.required, this.validateFileUpload]);
      } else {
      this.formGroup.controls[field.key].setValidators([this.validateFileUpload]);
      }
    }
  }
  handleFileInput(files: FileList, key) {


    //  = files.item(0);
    // console.log(files.item(0));
    this.formGroup.controls[key].value = files.item(0);
  }

  onSelectMethod(event, key) {
    if (key === 'toTime' || key === 'fromTime' || key === 'fromDate' || key === 'toDate') {
      let toTime = (typeof (this.formGroup.controls['toTime'].value) === 'object') ? formatDate(new Date(this.formGroup.controls['toTime'].value), 'HH:mm:ss', 'en') : this.formGroup.controls['toTime'].value;
      let fromTime = (typeof (this.formGroup.controls['fromTime'].value) === 'object') ? formatDate(new Date(this.formGroup.controls['fromTime'].value), 'HH:mm:ss', 'en') : this.formGroup.controls['fromTime'].value;
      console.log(toTime, fromTime, 'toTime', 'fromTime');
      if ((formatDate(new Date(this.formGroup.controls['fromDate'].value), 'yyyy-MM-dd', 'en') === formatDate(new Date(this.formGroup.controls['toDate'].value), 'yyyy-MM-dd', 'en')) && (fromTime >= toTime)) {
        this.formGroup.controls['toTime'].setErrors({ time: true });
      } else {
        this.formGroup.controls['toTime'].setErrors();
      }
      if (this.formGroup.controls['fromDate'].value > this.formGroup.controls['toDate'].value) {
        this.formGroup.controls['toDate'].setErrors({ date: true });
      } else {
        this.formGroup.controls['toDate'].setErrors();
      }
    }
    this.calenderSelectedValue.emit({ value: event, field: key });
  }

  textCounter(field, maxlimit, formGroup?, fieldParent?, i?) {
    if (field.showRange) {
      var id;
      var countfield;
      if (formGroup && fieldParent) {
        id = `${fieldParent.key}${field.key}${i}`;
        countfield = document.getElementById(id) as HTMLInputElement;
      } else {
        countfield = document.getElementById(field.key) as HTMLInputElement;
      }
      let valuedata;
      if (formGroup) {
        valuedata = formGroup.get(fieldParent.key).controls[i].controls[field.key].value;
      } else {
        valuedata = this.formGroup.get(field.key).value;
      }
      //  valuedata = formGroup?this.formGroup.get(field.key).value:formGroup.get(fieldParent.key).controls[i].controls[field.key].value;
      let valueLength = valuedata.split('').length;
      if (valueLength > maxlimit) {
        valuedata = valuedata.substring(0, maxlimit);
        return false;
      } else {
        field.count = `${maxlimit - valueLength} remaining`;
        countfield.value = `${maxlimit - valueLength} remaining`;
      }
    }
  }
  closeNew(field, ind) {
    // console.log({ field, ind });
    let elementLastIndx;
    let config = this.formService[this.formService.formTypes[field.formtag].config];
    let index = this.formTags.indexOf(field.formtag);
    config.forEach((element, i) => {
      if (element.key === field.key) {
        element.formInputs.splice(ind, 1);
      }
    });
    this.form[index] = this.formService.dynamicInitForm(config, this.formService.formTypes[field.formtag]);
    this.formInputs = this.form[index].formInputs;
    this.formGroup = this.form[index].formGroup;
    // console.log(this.formGroup, 'close form grouppp');
    // console.log('form group modified', this.formGroup);
  }

   /**
  * This function is to add the textarea dynamically
  * @param field : input field
  */
 addNewTextArea(field, tag) {
   let fieldObj = {
     ...this.formService[this.formService.formTypes[tag].config][0],
     label: 'Question ' + this.formService[this.formService.formTypes[tag].config].length,
     value: '',
     questionId: this.generateUniqueId(),
     key: this.generateUniqueId()
    };
   this.formService.informationRequestFormConfig.splice(-1, 0, fieldObj);
   this.form = this.formService.dynamicInitForm(this.formService.informationRequestFormConfig, this.formService.formTypes.INFORMATION_REQUEST);
   this.formInputs = this.form.formInputs;
   this.formGroup = this.form.formGroup;
   console.log(this.form);
}

/**
 * This function is for generating unique number
 * @returns :32 lenght uniqueId
 */
generateUniqueId() {
  let result = '';
  let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let charactersLength = characters.length;
  for (var i = 0; i < 31; i++) {
    result += characters.charAt(Math.floor(Math.random() *
      charactersLength));
  }
  return 'Q' + result;
}

/**
 * This funciton is to delete the textarea dynamically
 * @param field :Input Field
 */
deleteTextArea(field,ind) {
  this.formService.informationRequestFormConfig.splice(ind, 1);
  if (this.formInputs.length === 1) {
    this.formInputs.forEach(e => {
      e.isDelete = false;
    });
  }
  this.formInputs.forEach((e, i) => {
    if (i !== this.formInputs.length-1) {
      e.label = 'Question ' + ++i;
    }
  });
  this.form = this.formService.dynamicInitForm(this.formService.informationRequestFormConfig, this.formService.formTypes.INFORMATION_REQUEST);
  this.formInputs = this.form.formInputs;
  this.formGroup = this.form.formGroup;
  console.log(this.form);
}

  //This funciton is to delete the textarea(inside form array) dynamically for mednote 25mintues, mednote 45mintues, evaluation
  deleteTextAreaFormArray(field){
    console.log("field", field);
    for (let key in field) {
      if(field[key] === "Eval" ){ 
        this.formService.mednotesEvalConfig.forEach( element => {
          if( element.key === "informationRequestQuestions"){
            let fieldIndex = element.formInputs[0].indexOf(field)
            if (fieldIndex !== -1) {
              element.formInputs[0].splice(fieldIndex, 2);
            }
          }
        })      
        this.form = this.formService.dynamicInitForm(this.formService.mednotesEvalConfig, this.formService.formTypes.MEDNOTES_EVAL);
      } else if (field[key] === "Mednote25"){
        this.formService.mednoteFollowUpTwentyFiveConfig.forEach( element => {
          if( element.key === "informationRequestQuestions"){
            let fieldIndex = element.formInputs[0].indexOf(field)
            if (fieldIndex !== -1) {
              element.formInputs[0].splice(fieldIndex, 2);
            }
          }
        })
        this.form = this.formService.dynamicInitForm(this.formService.mednoteFollowUpTwentyFiveConfig, this.formService.formTypes.MEDNOTES_FOLLOW_UP_TWENTY_FIVE);
      } else if (field[key] === "Mednote45"){
        this.formService.mednoteFollowUpFortyFiveConfig.forEach( element => {
          if( element.key === "informationRequestQuestions"){
            let fieldIndex = element.formInputs[0].indexOf(field)
            if (fieldIndex !== -1) {
              element.formInputs[0].splice(fieldIndex, 2);
            }
          }
        })
        this.form = this.formService.dynamicInitForm(this.formService.mednoteFollowUpFortyFiveConfig, this.formService.formTypes.MEDNOTES_FOLLOW_UP_FORTY_FIVE);
      }
    }
    this.formInputs = this.form.formInputs;
    this.formGroup = this.form.formGroup;
  }
  addNew(field, tag) {
    console.log(tag);

    // this.addNewClicked.emit({
    //   field: field,
    //   tag: tag,
    //   refArray: refArray
    // });
    this.addNewIndex++;
    this.newControlCounter++;
    let index = this.formTags.indexOf(tag);
    let config = this.formService[this.formService.formTypes[tag].config];
    let elementLastIndx;
    let newElem: any;
    config.forEach((element: any, i) => {
      if (element.key === field.key) {
        elementLastIndx = i - 1;
        return false;
      }
    });
    let length = 0;
    config.forEach((newForm, formIndex) => {
      if (formIndex === elementLastIndx) {
        newElem = [...newForm.formInputs[0]];
        newElem = newElem.map(e => {
          return {
            ...e,
            // key:e.key+this.addNewIndex,
            count: '150 remaining',
            editable: true,
            // validators: this.setValidator(e),
            showClose: true,
            value: typeof (e.value) === 'boolean' ? false : '',
            fields: e.fields ? this.changeRadioButtonvalues(e.fields, this.addNewIndex) : [],
          };
        });
        console.log(newElem,'newElemnewElem')
        if(tag=== 'TMS_NOTES'){
          debugger
          newElem[2].value='PHQ-9'
        }
        length = newForm.formInputs.push(newElem);
        return false;
      }
    });
    this.form[index] = this.formService.dynamicInitForm(config, this.formService.formTypes[tag]);
    this.formInputs = this.form[index].formInputs;
    this.formGroup = this.form[index].formGroup;
    console.log(length,'lengthlength');
      const firstInput = document.querySelector(`.medicinename input`) as HTMLInputElement; // Adjust the selector based on your HTML structure
      console.log(firstInput,'firstInputfirstInputfirstInput');
      if (firstInput) {
          firstInput.focus();
      }
      const firstInput1 = document.querySelector(`.benzodiazepinesheadermedicinename input`) as HTMLInputElement; // Adjust the selector based on your HTML structure
      console.log(firstInput1,'firstInputfirstInputfirstInput');
      if (firstInput1) {
        firstInput1.focus();
      }
    console.log(this.formInputs, 'form group modified after', this.formGroup);
  }
  setValidator(field) {
    if (field.type === 'text') {
      return [Validators.required, Validators.minLength(1), this.valideTextArea];
    } else if (field.type === 'textarea') {
      return [Validators.required, Validators.minLength(1), this.valideTextArea];
    } else if (field.type === 'calendar') {
      return [Validators.required];
    } else if (field.type === 'radio') {
      return [Validators.required];
    }
  }
  changeRadioButtonvalues(field, ind) {
    let fields = field.map(element => {
      return {
        ...element,
        value: element.value + ind
      };
    });
    return fields;
  }

  checkFormRuntimeValidation() {
    // if (!this.formGroup.pristine) {
    //   if (this.formGroup.valid && !this.formPreviousValidState) {
    //       this.submitEvent.emit(this.formGroup);
    //       this.formPreviousValidState = true;
    //       // this.formGroup.patchValue({
    //       //   valid: false
    //       // });
    //   }
    //   else {
    //     if (this.formPreviousValidState){
    //       // this.submitEvent.emit(null);
    //       this.formPreviousValidState = false;
    //     }
    //   }
    // }
    return true;
  }



  getFormElementValue(formGroup, field, fieldInner, i) {
    return formGroup.get(field.key).controls[i].controls[fieldInner.key].value;
  }


  getValue(name) {
    name = decodeURIComponent(name);
    let newName = name.includes('__name__') ? name.substring(
      name.lastIndexOf('__name__') + 8,
      name.lastIndexOf('?')
    ) : name.substring(name.lastIndexOf('/') + 1);
    return newName;
  }
  categorySelect(data) {
    // console.log(data);
    let groupList = [];
    this.formInputs[data.i].isSelected = data.event.checked;
    if (data.event.checked) { this.formInputs[data.i].isIntermediate = false; }
    // tslint:disable-next-line:no-shadowed-variable
    this.formInputs[data.i].options = this.formInputs[data.i].options.map((each) => {
      return {
        ...each,
        isSelected: this.formInputs[data.i].isSelected,
      };
    });
    this.formGroup.controls[this.formInputs[data.i].key].setValue(this.setGroupcheck(this.formInputs[data.i]));
  }

  // This will do if option selected or unselected category will be unselected
  optionSelectEvent(data) {
    this.formInputs[data.categoryId].options[data.taskId].isSelected = data.event.checked;
    let optionsCount = this.formInputs[data.categoryId].options.length;
    let count = 0; let indexArr;
    let checked = data.event.checked;
    let key = this.formInputs[data.categoryId].key;
    let formInput = this.formInputs[data.categoryId];
    if (key === 'appointments' || key === 'patients' || key === 'locations' || key === 'employees' || key === 'invoices') {
      if (data.taskId === 0 || data.taskId === 2) {
        if (checked) {
          formInput.options.forEach(option => {
            option.isSelected = true;
          });
        } else if (!checked) {
          indexArr = [0, 2];
          formInput.options.forEach((option, i) => {
            if (indexArr.includes(i)) { option.isSelected = false; }
          });
        }
      }
      if (data.taskId === 1 || data.taskId === 3 || (data.taskId === 4 && key === 'appointments')) {
        if (checked) {
          indexArr = [0, 2];
          formInput.options.forEach((option, i) => {
            if (!indexArr.includes(i)) { option.isSelected = true; }
          });
        } else if (!checked) {
          formInput.options.forEach((option, i) => {
            option.isSelected = false;
          });
        }
      }
    } else if (key === 'templates' || key === 'manageUserAccounts') {
      /**
       * This is for template check box intractions
       * If list is selected details page auto selected vice-versa
       * if edit selected list and details page auto selected
       * if edit is un checked only edit check box un selected
       */
      if (data.taskId === 0 || data.taskId === 2) {
        indexArr = [0, 2];
        if (checked) {
          formInput.options.forEach((option, i) => {
            if (indexArr.includes(i)) { option.isSelected = true; }
          });
        } else if (!checked) {
          formInput.options.forEach((option, i) => {
            option.isSelected = false;
          });
        }
      } else if (data.taskId === 1) {
        if (checked) {
          formInput.options.forEach((option, i) => {
           option.isSelected = true;
          });
        } else if (!checked) {
          formInput.options.forEach((option, i) => {
            if (data.taskId === i) { option.isSelected = false; }
          });
        }
      }
    }
    // tslint:disable-next-line:no-shadowed-variable
    this.formInputs[data.categoryId].options.forEach((each) => {
      if (each.isSelected === true) {
        count++;
      }
    });
    if (count > 0 && count < optionsCount) {
      this.formInputs[data.categoryId].isIntermediate = true;
    } else {
      this.formInputs[data.categoryId].isIntermediate = false;
    }
    if (count === optionsCount) {
      this.formInputs[data.categoryId].isSelected = true;
      this.formInputs[data.categoryId].isIntermediate = false;
    } else {
      this.formInputs[data.categoryId].isSelected = false;
    }
    this.formGroup.controls[this.formInputs[data.categoryId].key].setValue(this.setGroupcheck(this.formInputs[data.categoryId]));
    // console.log(this.formGroup);
  }
  // This set formGroup values which is selected
  setGroupcheck(config) {
    let checkedGroup = [];
    if (config && typeof (config) === 'object') {
      // tslint:disable-next-line:no-shadowed-variable
      config.options.forEach(each => {
        if (each.isSelected === true) {
          checkedGroup.push(each.id);
        }
      });
    }
    return checkedGroup;
  }

  /**
   * 
   * @param i :number
   * @returns 
   */
  counter(i: string) {
    let len = parseInt(i);
    return new Array(len);
  }

  /**
  *
  * @param event data of selected file
  * executed when file selected
  */
  profileUpload(event, field?) {
    let fileName = event.target.files && event.target.files[0] ? event.target.files[0]['name'].split('.')[0] : null;
    // tslint:disable-next-line:whitespace
    let regex: any = /[!@#$%^&*()+\-=\[\]{};':"\\|,.`~<>\/?]+/;
    let status = regex.test(fileName);
    if (fileName && status) {
      this.observableHelperService.showToast(TOAST_STATUSES.ERROR, 'Filename should not contain special characters. Please upload with a different name.');
      this.formGroup.controls[field.key].value = '';
      this.formGroup.controls[field.key].updateValueAndValidity();
    } else {
      this.isFileUploadPending.emit(true);
      field.showImage = false;
      if (event.target.files && event.target.files[0]) {
        var reader = new FileReader();
        var file = event.target.files[0];
        reader.readAsDataURL(event.target.files[0]); // read file as data url
        reader.onload = (e) => { // called once readAsDataURL is completed
          let target: any = e.target; // This (any) will tell compiler to shut up!
          let content: string = target.result;
          field.value = content ? content : file;
          this.formGroup.controls[field.key].setValue(field.value);
          let employee: any = {};
          try {
            // tslint:disable-next-line: no-shadowed-variable
            this.formInputs.forEach(field => {
              if (field.key === 'firstName' || field.key === 'lastName' || field.key === 'dob') {
                if (field.key === 'dob') {
                  let formattedDate = typeof (file) === 'string' && file.indexOf('/') !== -1;
                  if (!formattedDate) {
                    var d = new Date(file);
                    var month = '' + (d.getMonth() + 1);
                    var day = '' + d.getDate();
                    var year = d.getFullYear();

                    if (month.length < 2) {
                      month = '0' + month;
                    }
                    if (day.length < 2) {
                      day = '0' + day;
                    }
                    employee[field.key] = [year, month, day].join('-');
                  } else {
                    let date = file.split('/');
                    employee[field.key] = date[2] + '-' + date[0] + '-' + date[1];
                  }
                } else {
                  employee[field.key] = file;
                }
              }
            });
            if (this.formGroup.controls[field.key].valid) {
              let type;
              if (!file.type) {
                type = file.name.split('.').pop().toLowerCase();
              }
              let firstName = this.accentedtoNormalFun(this.userDetails.firstName);
              let lastName = this.accentedtoNormalFun(this.userDetails.lastName);
              let getUploadUrls = {
                type: 'POST',
                url: 'getUploadUrl',
                isDeveloper: true,
                body: {
                  fileNames: [{ key: field.key, name: this.accentedtoNormalFun(file.name), contentType: file.type || type }],
                  userId: this.userDetails.userId,
                  userName: 'temp-new-tele-doc/' + firstName + lastName,
                  // userName: this.userDetails.firstName + this.userDetails.lastName,
                  patientName: firstName + lastName + '__' + employee['dob'] ? employee['dob'] : '1990/11/1'
                },
              };
              this.http.makeHttpRequest(getUploadUrls).subscribe(res => {
                let uploadPayload = {
                  type: 'PUT',
                  url: res[field.key],
                  body: file,
                  isExternal: true,
                  donotShowLoader: false,
                  contentType: file.type || type
                };
                this.http.makeHttpRequest(uploadPayload).subscribe(response => {
                  this.isFileUploadPending.emit(false);
                  if (res[field.key] !== '') {
                    if (field.isEditAndView) {
                      this.employeeUploads.emit({ type: 'profile', docUrl: res[field.key] });
                    } else {
                      this.detailsUploads.emit({ innerField: field, value: res[field.key], type: 'POST' });
                    }
                  } else {
                    field.value = '';
                    this.observableHelperService.showToast(TOAST_STATUSES.ERROR, 'File upload failed');
                  }

                });

              });
            }
          } catch (err) {
            field.loading = false;
            this.isFileUploadPending.emit(false);
            field.value = '';
          }
        };
      }
    }
  }
  getChckedvalue(formField) {
    let groupCheckBox = [];

    formField.options.forEach(option => {
      if (option.isSelected === true) {
        groupCheckBox.push(option.id);
      }
    });
    formField.value = groupCheckBox;
    this.formGroup.controls[formField.key].patchValue(groupCheckBox);
    if (formField.key === 'treatments') {
      this.formGroup.controls['treatments'].patchValue(formField.value);
    }
  }

  // Create new field fof the employee creation
  addNewCreation(field, tag, uploadType) {
    this.addNewIndex++;
    let elementLastIndx;
    let newElem: any;
    let getFormType: any;
    let renderFormType: any;
    renderFormType = this.formService.employeeCreationConfig;
    renderFormType.forEach((element: any, i) => {
      if (element.key === field.key) {
        elementLastIndx = i - 1;
      }
    });
    renderFormType.forEach((newForm, formIndex) => {
      if (formIndex === elementLastIndx) {
        newElem = [...newForm.formInputs[0]];
        newElem = newElem.map(e => {
          return {
            ...e,
            // key:e.key+this.addNewIndex,
            count: '150 remaining',
            validators: this.setValidator(e),
            showClose: true,
            value: typeof (e.value) === 'boolean' ? false : '',
            fields: e.fields ? this.changeRadioButtonvalues(e.fields, this.addNewIndex) : []
          };
        });
        newForm.formInputs.push(newElem);
      }
    });

    this.form = this.formService.dynamicInitForm(this.formService.employeeCreationConfig, this.formService.formTypes[tag]);
    this.formGroup = this.form.formGroup;
  }


  searchValues(event, key) {
    setTimeout(() => {
      this.searchResult.emit({ event, key });
      this.formGroup.controls[key].setValue('');
    }, 1800);
  }


  getTheSearchedPatientId(data) {
    this.searchTextClick.emit(data.id);
    if (data.status === 'archive') {
      this.selectedAutoSearchItem = '';
    }
  }

  onFocusOnCalender(data) {
    console.log(data);
  }

  // Download file
  downloadFile(url) {
    let fileName = url.split('?')[0].split('/').pop().split('__name__')[1];
    fetch(url).then(resp => resp.blob()).then(res => {
      let anchor = document.createElement('a');
      anchor.href = URL.createObjectURL(res);
      anchor.setAttribute('download', fileName);
      anchor.click();
    });
  }
  uploadFunctionCall(data) {
    // Get all uploads list form API
    let fileName;
    let docObj = {};
    fileName = data.substring(
      data.lastIndexOf('__name__') + 8,
      data.lastIndexOf('?')
    );
    docObj['file'] = this.splitFileName(fileName, data);
    docObj['docUrl'] = data;
    console.log(this.formData);
    this.formInputs.forEach(eachInput => {
      if (eachInput.key === 'uploads') {
        if (eachInput.isEditAndView === true) {
          this.employeeUploads.emit({ type: 'employee', docUrl: data });
        } else {
          eachInput.value.push(docObj);
          this.formGroup.controls['uploads'].setErrors(null);
          this.formGroup.controls['uploads'].patchValue(eachInput.value);
        }
      }
    });
  }
  // Split function for the file name
  splitFileName(file, docURL) {
    let fileObj = {};
    let splitFileName = file.split('.');
    fileObj['name'] = splitFileName[0];
    fileObj['extenstion'] = splitFileName[splitFileName.length - 1];
    fileObj['file'] = docURL;
    return fileObj;
  }

  // Download file
  donwloadFile(url, fileName, flag) {
    let fullFileName = '';
    if (flag === true) {
      fullFileName = fileName.name + '.' + fileName.extenstion;
    } else {
      fullFileName = fileName.file.name + '.' + fileName.file.extenstion;
    }
    // tslint:disable-next-line:only-arrow-functions
    fetch(url).then(function (t) {
      return t.blob().then((b) => {
        var a = document.createElement('a');
        a.href = URL.createObjectURL(b);
        a.setAttribute('download', fullFileName);
        a.click();
      }
      );
    });
  }

  deleteFileUpload(data, editAndViewDataFlag) {
    this.formInputs.forEach(eachInput => {
      if (eachInput.key === 'uploads') {
        eachInput.value.forEach((eachDoc, index) => {
          if (eachDoc.docUrl === data.docUrl) {
            if (editAndViewDataFlag === true) {
              let payload = {
                type: 'DELETE',
                url: 'uploadFileDelete',
                body: {
                  uploadId: data.uploadId,
                  uploadType: 'employee',
                  refId: this.userDetails.userId,
                  docUrl: data.docUrl
                },
                isDeveloper: true,
              };
              this.http.makeHttpRequest(payload).subscribe((res) => {
                if (this.http.isSuccessfulResponse(res)) {
                  eachInput.value.splice(index, 1);
                  this.formGroup.controls['uploads'].patchValue(eachInput.value);
                  this.observableHelperService.showToast(TOAST_STATUSES.SUCCESS, res.data);
                } else {
                  let message = 'Failed to delete file. Please try again';
                  message = (res.error) ? res.error.message : message;
                  this.observableHelperService.showToast(TOAST_STATUSES.ERROR, message);
                }
              });
            } else {
              eachInput.value.splice(index, 1);
              this.formGroup.controls['uploads'].patchValue(eachInput.value);
            }
            if (eachInput.value.length !== 0) {
              this.formGroup.controls['uploads'].setValidators([Validators.required]);
              this.formGroup.controls['uploads'].updateValueAndValidity();
            }
          }
        });

      }
    });
  }
  /* for accented letter change convention function */
  accentedtoNormalFun(name) {
    let originalText = name;
    let result = originalText.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
    return result;
  }

  /**
   * on value change in the input.
   * @param event click event
   */
  onKeyPressField(event: any, field){
    if(field.type === 'number' && this.integerValueFields.includes(field.key)){
      if ( event.key === 'e' || event.key === '.' || event.key === '-' || event.key === '+' || (event.which < 48) || (event.which > 57)) {
        event.preventDefault();
      }
    }
 }


  /**
   * This funciton will do all the file validations like file extensions,file size,file name,maximum files
   * @param event uploaded files
   * @param remove flag for removing files
   */
  onUpload(event, uploader: FileUpload,field, remove?) {
    let isRemove = remove || false;
    this.uploadedFiles = event.currentFiles;
    let extentions = ['jpg', 'png', 'gif', 'jpeg', 'tiff', 'psd', 'pdf', 'esp', 'ai', 'indd', 'raw', 'heic', 'heif'];
    let regex: any = /[!@#$%^&*()+\-=\[\]{};':"\\|,.~`<>\/?]+/;
    /**
     * event.currentFiles.length <= 5:-This if condition checks length of the files <= 5
     */
    if (event.currentFiles.length <= 5) {
      let extentionFlag = this.uploadedFiles.every(element => extentions.includes(element.name.includes('.') ? element.name.split('.').pop().toLowerCase() : 'apk'));
      /**
       * extentionFlag:This if condition will check whether uploaded file is valid extention or not
       */
      if (extentionFlag) {
        let isValidFileName = this.uploadedFiles.every(element => !regex.test((element.name.includes('.') && element.name.split('.').length === 2) ? element.name.split('.')[0] : element.name));
        /**
         * isValidFileName:This if condition will check whether uploaded file names has special characters or not except underscore(_)
         */
        if (isValidFileName || this.uploadedFiles.length === 0) {
          let fileSizeFlag = this.uploadedFiles.every(element => element.size <= 10000000);
          /**
           * fileSizeFlag: This if condition will check wheter uploaded file size less than 10MB or not
           */
          if (fileSizeFlag) {
            this.filesUpload(this.uploadedFiles, field)
          } else {
            // this.fileValidation = false;
            if (!isRemove) {
              this.observableHelperService.showToast(TOAST_STATUSES.ERROR, TOAST_MESSAGES.MAX_FILE_SIZE_ERROR_MSG);
            }
          }
        } else {
          for (let index = event.currentFiles.length - 1; index >= 0; index--) {
            if (event.currentFiles.length && event.currentFiles[index] && event.currentFiles[index].name) {
              if (regex.test((event.currentFiles[index].name.includes('.') && event.currentFiles[index].name.split('.').length === 2) ? event.currentFiles[index].name.split('.')[0] : event.currentFiles[index].name)) {
                uploader.remove(event, index);
              }
            }
          }
          if (!isRemove) {
            this.observableHelperService.showToast(TOAST_STATUSES.ERROR, TOAST_MESSAGES.INVALID_FILENAME_ERROR_MSG);
          }
        }
      } else {
        // this.fileValidation = false;
        if (!isRemove) {
          this.observableHelperService.showToast(TOAST_STATUSES.ERROR, TOAST_MESSAGES.INVALID_EXTENSION_ERROR_MSG);
        }
      }
    } else {
      for (let len = event.currentFiles.length; len > 5; len--) {
        uploader.remove(event, len - 1);
      }
      if (!isRemove) {
        this.observableHelperService.showToast(TOAST_STATUSES.ERROR, TOAST_MESSAGES.MAX_FILE_LIMIT_ERROR_MSG);
      }
    }
    if (!isRemove) {
      if (event.files && event.files.length) {
        let uploadedFiles = [];
        for (let file of event.files) {
          uploadedFiles.push(file);
        }
        let extentionFlag = uploadedFiles.every(element => extentions.includes(element.name.includes('.') ? element.name.split('.').pop().toLowerCase() : 'apk'));
        if (!extentionFlag) {
          this.observableHelperService.showToast(TOAST_STATUSES.ERROR, TOAST_MESSAGES.INVALID_EXTENSION_ERROR_MSG);
        } else if (!uploadedFiles.every(element => element.size <= 10000000)) {
          this.observableHelperService.showToast(TOAST_STATUSES.ERROR, TOAST_MESSAGES.MAX_FILE_SIZE_ERROR_MSG);
        }
      }
    }
  }

  /**
   * This funciton will upload the uploaded all files into s3 bucket
   * @param uploadedFiles 
   * @param field 
   */
  filesUpload(uploadedFiles, field) {
    // this.fileValidation = true;
    let patientData = {};
    this.form[0].formInputs.forEach(field => {
      if (field.key === 'firstName' || field.key === 'lastName' || field.key === 'dob') {
        if (field.key === 'dob') {
          let formattedDate = typeof (field.value) === 'string' && field.value.indexOf('/') !== -1;
          if (!formattedDate) {
            var d = new Date(field.value);
            var month = '' + (d.getMonth() + 1);
            var day = '' + d.getDate();
            var year = d.getFullYear();

            if (month.length < 2) {
              month = '0' + month;
            }
            if (day.length < 2) {
              day = '0' + day;
            }
            patientData[field.key] = [year, month, day].join('-');
          } else {
            let date = field.value.split('/');
            patientData[field.key] = date[2] + '-' + date[0] + '-' + date[1];
          }
        } else {
          patientData[field.key] = field.value;

        }
      }
    });
    var getUploadUrls;
    var filesData = [];
    if(!this.documentUrls[field.key]){
      this.documentUrls[field.key] = [];
    }
    let isUpdatedFiles = uploadedFiles.every(element => this.documentUrls[field.key].some(url => url.indexOf(element.name)));
    if (uploadedFiles.length !== this.documentUrls[field.key].length || !isUpdatedFiles) {
      uploadedFiles.every(element => this.documentUrls[field.key].includes(element.name));
      uploadedFiles.forEach((element, ind) => {
        let obj = {
          key: field.key + ind,
          name: this.accentedtoNormalFun(element.name),
          contentType: element.type || element.name.split('.').pop().toLowerCase()
        };
        filesData.push(obj);
      });
      getUploadUrls = {
        type: 'POST',
        url: 'getUploadUrl',
        isDeveloper: true,
        body: {
          fileNames: filesData,
          userId: this.userDetails.userId,
          userName: 'temp-new-tele-doc/' + this.accentedtoNormalFun(this.userDetails.firstName) + this.accentedtoNormalFun(this.userDetails.lastName),
          patientName: patientData ? (this.accentedtoNormalFun(patientData['firstName']) + this.accentedtoNormalFun(patientData['lastName']) + '__' + patientData['dob']) : ''
        },
      };
      this.http.makeHttpRequest(getUploadUrls).subscribe(res => {
        this.documentUrls[field.key] = [];
        const urls = res;
        if (res && typeof (res) === 'object') {
          for (const key in res) {
            if (Object.prototype.hasOwnProperty.call(res, key)) {
              this.documentUrls[field.key].push(res[key]);
            }
          }
          if(Object.keys(res).length === 0){
            this.documentUrls[field.key] = [];
            this.formGroup.controls[field.key].value = [];
          }
        } else {
          this.documentUrls[field.key] = [];
          this.formGroup.controls[field.key].value = [];
        }
        this.formGroup.controls[field.key].value = this.documentUrls[field.key]
        let fieldIndex = this.formInputs.findIndex(ele => ele.key == field.key)
        if(this.formGroup.controls[field.key].value.length > 0){
          this.formGroup.controls[field.key].status = validationState.valid;
          if (this.formInputs[fieldIndex].validators) {
            this.formGroup.controls[field.key].clearValidators();
            this.formGroup.controls[field.key].updateValueAndValidity();
            this.formInputs[fieldIndex].validators = [];
          }
        } else if(this.formInputs[fieldIndex].validators.required){
          this.formGroup.controls[field.key].status = validationState.invalid;
          this.setValidators(field)
        }
        this.formGroup.controls[field.key].updateValueAndValidity();
        filesData.forEach(eachFile => {
          let file = uploadedFiles.filter(each => this.accentedtoNormalFun(each.name) === eachFile.name);
          let uploadPayload = {
            type: 'PUT',
            url: urls[eachFile.key],
            body: file[0],
            isExternal: true,
            contentType: eachFile.contentType || eachFile.name.split('.').pop().toLowerCase()
          };
          this.http.makeHttpRequest(uploadPayload).subscribe(response => {
          });
        });
      });
    }
  }

   /**
   * This function will do removing of files which are added.
   * @param event remove file
   */
  filesRemoved(event, uploader,field) {
    if (event.file) {
      this.uploadedFiles = this.uploadedFiles.filter(each => each.name !== event.file.name);
    }
    let fileEvent = {
      currentFiles: this.uploadedFiles
    };
    this.onUpload(fileEvent, uploader, field, true);
  }

  /**
   * This function will remove all files which are uploaded.
   * @param event 
   * @param field 
   */
  allFilesCleared(event, field) {
    this.formGroup.controls[field.key].value = '';
  }

   /**
   * File name list.
   * @param list 
   */
  getFileNames(field) {
    let nameList: string[] = [];
    if(!field.value) return [];
    let docList = field.value.filter(ele => ele.indexOf('temp-new-tele-doc') === -1);
    if(docList){
      docList.forEach(element => {
        nameList.push(this.getValue(element))
      });
    }
    return nameList;
  }

  /**
   * To filter the selected documents.
   * @param docList1 
   * @param docList2 
   */
  filterDocs(docList1, docList2) {
    let list = docList1;
    docList2.forEach(element => {
      if(list.every(ele => this.getValue(ele) !== this.getValue(element))){
        list.push(element)
      }
    });
    return list;
  }

  /**
   * Set value for upload field/
   */
  setDataForUpload(){
    this.formInputs.forEach((element, i) => {
      if(element.key === INTAKE_FORM_KEYS.THERAPIST_DOCS || element.key === INTAKE_FORM_KEYS.PRESCRIBER_DOCS || element.key === INTAKE_FORM_KEYS.LAB_WORKS_DOCS){
        this.formGroup.controls[element.key].value = element.value;
      }
    });
  }

  /**
   * To show the uploaded doc list in a popup.
   * @param field 
   */
  showUploadedDocuments(field){
    let list = this.getFileNames(field)
    this.showDocs.emit(list)
  }

  /**
   * Block copy action for confirm fields.
   * @param event 
   * @param field 
   */
  blockCopy(event, field){
    if(field.blockCopy){
      event.preventDefault();
    }
  }

  // validation for file zip code
  ValidateZipCode(control: FormControl): any {
    if (control.value !== '' && control.value !== undefined && control.value != null) {
      var regex = /(^\d{5}$)|(^\d{5}-\d{4}$)/;
      var found = regex.test(control.value);
      if(found){
        let value = Number(control.value)
        console.log(control)
        return value>=control['list'][0] && value<=control['list'][1] ? null : { tname: true };
      } else {
        return {name: true};
      }
    } else {
      return null;
    }
  }
}
