import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'app-list-search',
  templateUrl: './list-search.component.html',
  styleUrls: ['./list-search.component.scss']
})
export class ListSearchComponent implements OnInit {
  // Show hide the seach input flag
  searchInputHideShow = false;
  // Input search model text
  searchInput: string;
  isEmpty: boolean = true;
  // change the value subject
  inputChanged: Subject<string> = new Subject<string>();
  // Input value emiting
  @Output() searchText = new EventEmitter<string>();
  @Input() searchPlaceHolderText: any = 'Search by Patient, Treatment, Doctor';
  defaultSearOpen: boolean = false;
  @Input() set isOpenSearch(value: boolean) {
    if (value) {
      this.defaultSearOpen = value;
      this.searchInputHideShow = true;
    }
  }

  @Input() set defSearchValue(value: string) {
    if (value && value !== '') {
      this.searchInput = value;
      this.defaultSearOpen = true;
      this.searchInputHideShow = true;
      this.isEmpty = false;
    }
  }

  constructor() {
    // Adding debounded time for the input value
    this.inputChanged
      .pipe(debounceTime(1500))
      .pipe(distinctUntilChanged())
      .subscribe(newValue => {
        // this.searchInput = newValue;
        // this.searchText.emit(this.searchInput);
        // this.isEmpty = false;
      });
  }

  ngOnInit() {
  }
  // Geting updated value
  inputValueChange(event: any) {
    const text = event.target.value;
    console.log(text, '123');
    this.isEmpty = text === '';

    if (event.key === 'Enter') {
      this.searchInput = text;
      this.searchText.emit(this.searchInput);
    } else {
      console.log(text, 'Subject Text');
      this.inputChanged.next(text);

      // If the input is empty, reset the state
      if (this.isEmpty) {
        this.searchInput = '';
        this.searchText.emit(this.searchInput); // Emit empty string to reset search results
      }
    }
  }
  // Outside click checking
  onClickedOutside(e) {
    if (!this.defaultSearOpen) {
      this.isEmpty = true;
      this.searchInputHideShow = false;
    }
  }

  // It will work as back space

  clear() {
    this.searchText.emit('');
    this.searchInputHideShow = false;
    this.isEmpty = true;
    this.searchInput = '';
    this.inputChanged.next(this.searchInput);
    console.log(this.searchInput);
  }
}