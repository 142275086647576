import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';
import { TOAST_STATUSES } from '@app/common/constants/util.constant';
import { ObservableHelperService } from '@app/common/services/observable-helper.service';

@Component({
  selector: 'app-notification-card',
  templateUrl: './notification-card.component.html',
  styleUrls: ['./notification-card.component.scss']
})
export class NotificationCardComponent implements OnInit {

  constructor( private observable: ObservableHelperService ) { }
  @Input() permissions: any;
  @Input() notificationData: any;
  @Output() redirectTo = new EventEmitter<any>();
  notType: any;
  ngOnInit() {
    this.notType = this.notificationData.notificationType;
  }
  notificationRedirection(obj) {
    // tslint:disable-next-line:one-variable-per-declaration
    let type: any, id: any, invType;
    // tslint:disable-next-line:no-unused-expression
    obj.patientId ? (type = 'patients', id = obj.patientId) : obj.appointmentId ? (type = 'appointments', id = obj.appointmentId) : obj.invoiceId ? (type = 'invoices', id = obj.invoiceId, invType = obj.invoiceType) : null;
    this.redirectTo.emit({Type: type, Id: id, InvType: invType});
    // this.observable.showToast(TOAST_STATUSES.SUCCESS, 'This feature is coming out soon..!!');
  }
}
