import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpService } from '@app/common/services/http.service';
import { TOAST_STATUSES } from '@app/common/constants/util.constant';

// declare var stripe: any;

@Component({
  selector: 'app-invoice-pay',
  templateUrl: './invoice-pay.component.html',
  styleUrls: ['./invoice-pay.component.scss']
})
export class InvoicePayComponent implements OnInit {
  showErrorMessage: boolean = false;
  errorMessage: string;
  invoiceId;
  paymentToken: string = '';
  showInvoiceDetails: any;
  showPaymentButton: boolean = false;
 timeLeft: number;
 interval: any;
 minutes: number = 0;
 seconds: number = 0;
 showtimer: boolean = true
  constructor(private http: HttpService, private route: ActivatedRoute) { }

  ngOnInit() {
    this.route.params.subscribe(params => {
      console.log(params, 'route params');
      if (params && params.id) {
        this.invoiceId = params.id;
      }
    });
    this.checkInvoiceValidity();
  }

  checkInvoiceValidity() {
    const payloadData = {
      type: 'GET',
      url: 'invoiceValidate',
      isDeveloper: true,
      isAuthToken: true,
      pathVariables: [this.invoiceId]
    };
    this.http.makeHttpRequest(payloadData).subscribe((res) => {
      if (this.http.isSuccessfulResponse(res)) {
        // this.initiatePayment();
        this.getInvoiceDetails()
      } else {
        // Error
        this.showErrorMessage = true;
        this.errorMessage = (res.error) ? res.error.message : 'Error in getting invoice data';
      }
    });
  }

  // initiatePayment() {
  //   let payload = {
  //     type: 'POST',
  //     url: 'checkoutSessionUser',
  //     isDeveloper: true,
  //     isAuthToken: true,
  //     body: {
  //       id: this.invoiceId
  //     }
  //   };
  //   this.http.makeHttpRequest(payload).subscribe((res) => {
  //     if (res.error) {
  //       alert(res.error.message);
  //       return;
  //     }
  //     stripe.redirectToCheckout({ sessionId: res.id });
  //   });
  // }

 getInvoiceDetails(){
   const payloadData = {
     type: 'GET',
     url: 'getInvoiceDetails',
     isDeveloper: true,
     // isAuthToken: true,
     pathVariables: [this.invoiceId]
   };
   console.log("payloadData", payloadData);
  
   this.http.makeHttpRequest(payloadData).subscribe((res) => {
       let invoiceDetails = res.data;
       this.showInvoiceDetails = res.data
       let totalAmt = invoiceDetails.pay + invoiceDetails.copay
       let invoiceId = invoiceDetails.invoiceId;
       let invoiceType = invoiceDetails.invoiceType;
       this.showPaymentButton = true
       this.getAcceptPaymentPage(invoiceId, totalAmt, invoiceType)
      // this.isShowScreen = true;
   });
 }

 getAcceptPaymentPage( invoiceID, Amt, invoiceType) {
   let payload = {
     type: 'POST',
     url: 'getPaymentLink',
     isDeveloper: true,
     body: {
       invoiceId : invoiceID,
       amount: Amt,
       invoiceType: invoiceType
     }
   };
   this.http.makeHttpRequest(payload).subscribe((res) => {
     if (res) {
       this.startTimer()
       this.paymentToken = res.response.token;
     } else {
       alert(res.error.message);
       return;
     }
   });
 }

 startTimer() {
   const totalTime = 900; // 15 minutes in seconds
   this.timeLeft = totalTime;
  
   this.interval = setInterval(() => {
     if(this.timeLeft > 0) {
       this.minutes = Math.floor(this.timeLeft / 60);
       this.seconds = this.timeLeft % 60;
       this.timeLeft--;
     } else {
       clearInterval(this.interval); // Stop the timer when it reaches 0
       window.location.reload(); // to reload the page
     }
   }, 1000);
 }

}
