import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { CustomFormHandlerService } from '@app/common/services/custom-form-handler.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DataService } from '@app/common/services/data.service';
import { ROUTE_PATHS } from '@app/common/constants/routing.constant';
import { HttpService } from '@app/common/services/http.service';
import { CognitoService } from '@app/common/services/cognito.service';
import { ObservableHelperService } from '@app/common/services/observable-helper.service';
import { HTTPMethods, TOAST_STATUSES, appTypeEnums, locationIdEnums } from '@app/common/constants/util.constant';
import { API_URLS } from '@app/common/constants/api-urls.constant';
import { ISelectedPatientDetails, IPatientBlockedTreatments } from '@app/common/constants/typeInterfaces.constants';
import { TOAST_MESSAGES } from '@app/common/constants/toast-messages.constant';

interface ILastVisitPayloadBodyI  {
  appointmentDate: string;
  appointmentTime: string;
  appointmentTypeId: number;
  locationId: string;
  patientId: number;
  timeDiff: string;
  timestamp: string;
  timestampDate: string;
  userId: string;
}

interface ILastVisitPayloadI {
  type: string;
  url: string;
  isDeveloper: boolean;
  body: Record<string, any>;
}
@Component({
  selector: 'app-appointment-page',
  templateUrl: './appointment-page.component.html',
  styleUrls: ['./appointment-page.component.scss']
})

export class AppointmentPageComponent implements OnInit {
  isOfficeFilled: boolean = false;
  isPatientFilled: boolean = false;
  isSheduleDataFilled: boolean = false;
  isDetails: boolean = false;
  location: any;
  isShow: string;
  typeofOffice: any;
  cancelPopupShowHide: boolean = false;
  userLogedIn: boolean = false;
  officeData: any;
  bookedSlotData: any;
  appointmentTypes: any;
  appointmentDetailObject = {
    typeAppointmentData: null,
    shedularData: null,
    appoinmentData: null,
    detailsData: null,
    locationElement: null,
    timeRange: null
  };
  isAppointment = true;
  appointmentData = [];

  typeAppointmentForm: any;
  officeForm: any;
  timeForm: any;
  userName;
  patientName;
  formData: any;
  appointmentForForm: any;
  announcementBannerVal: any;
  stepDetails: any;
  timeRange = {
    start: '06:30:00',
    end: '11:55:00'
  };
  appointmentId = 1;
  locationId = null;
  slotId = null;
  timeSlot = false;
  domElement;
  patientdocdetails: any;
  requestDocumentDelay = false; // if any document not submitted for more then 6 months block telemedicine appointment.
  blinkMsg = 'You can only reschedule before 2-hours of your appointment timing.';
  locationType: any;
  showScroll: boolean=false;

  selectedPatientData: Record<string, any>;
  @Input() isReshedule: boolean = false;
  @Input() rescheduleFormData;
  @Input() saveFormData;
  @Input() availabiltyData;
  @Output() submitEvent: EventEmitter<any> = new EventEmitter();
  @Output() typeOfTreatment: EventEmitter<any> = new EventEmitter();
  @Output() rescheduleData: EventEmitter<any> = new EventEmitter();
  @Output() patientDetails: EventEmitter<ISelectedPatientDetails> = new EventEmitter();
  patientsList: Record<string, any>[];
  typeAppointmentFormConfig = [
    {
      type: 'dropdown',
      label: 'What type of treatment would you like to book?',
      labelImage: '',
      required: true,
      key: 'type_of_appointment',
      value: '',
      editable: true,
      controlType: 'dropdown',
      emptyValueMessage: 'Please select this option',
      validatorsData: ['required'],
      validators: [],
      className: 'dropdown-btn-horizontal',
      list: [],
      showontoggle: true,
    },
    {
      idName: 'preffered_location',
      type: 'dropdown',
      placeholder: 'Select your preferred location',
      label: 'Select your preferred location',
      required: true,
      key: 'preffered_location',
      labelImage: '',
      value: '',
      list: [],
      controlType: 'dropdown',
      editable: true,
      emptyValueMessage: 'Please select patient',
      validatorsData: ['required'],
      validators: [],
      className: 'dropdown-btn-horizontal',
      focus: true,
      showontoggle: true,
    },
    {
      key: 'note_type',
      type: 'note',
      note: 'This is an In-person appointment. You are required to visit the clinic.',
      showontoggle: false,
      image: true,
      className: 'check-text',
    },
    {
      type: 'radio',
      label: 'What time slot would you prefer ?',
      subtitle: '*All the mentioned slots are in EST Time Zone',
      labelImage: '',
      required: true,
      key: 'prefferd_time',
      value: '7:00-11:55am',
      editable: true,
      controlType: 'custom-radio',
      emptyValueMessage: 'Please select this option',
      validatorsData: ['required'],
      validators: [],
      className: 'radio-btn-horizontal radio-btn',
      fields: [
        {
          label: '7:00 - 11:55AM',
          value: '07:00:00-11:55:00',
          img: 'morning',
          width: '27',
          height: '16'
        },
        {
          label: '12:00 - 3:55PM',
          value: '12:00:00-3:55:00',
          img: 'afternoon',
          width: '25',
          height: '25'
        },
        {
          label: '4:00 - 5:55PM',
          value: '4:00:00-5:55:00',
          img: 'evening',
          width: '27',
          height: '19'
        },
        {
          label: '6:00 - 8:00PM',
          value: '06:00-8:00:00',
          img: 'night',
          width: '22',
          height: '19'
        }
      ],
      showontoggle: false,
    },
  ];
  appointmentForFormConfig: any = [
    {
      type: 'radio',
      label: 'Who is this appointment for?',
      labelImage: '',
      required: true,
      key: 'meet_doctor',
      value: 'Self',
      editable: true,
      controlType: 'custom-radio',
      emptyValueMessage: 'Please select this option',
      validatorsData: ['required'],
      validators: [],
      className: 'radio-btn-horizontal',
      fields: [
        {
          label: 'Self',
          value: 'Self'
        },
        {
          label: 'Parent',
          value: 'Parent'
        },
        {
          label: 'Legal Guardian',
          value: 'Legal Guardian'
        }
      ],
      showontoggle: true,
    }

  ];
  detailsData = [
    {
      lable: 'Alexandria Office',
      icon: 'home'
    },
    {
      lable: null,
      icon: 'calendar-color'
    },
    {
      lable: null,
      icon: 'clock-color'
    }
  ];
  userId: any;
  locations: any;
  slots: any;
  time: any;
  message: any = 'Rescheduling is only allowed for the selected appointment. You can reschedule an appointment only 2 times. For other treatment options please book a new appointment.';
  patientId: any;
  showScreen: any;
  patientList: any;
  params: any;
  previousPatientId: string;
  noteMessage:string = 'Any Pharmacy, demographic, insurance updates should be made online only. Any email updates will not be taken care.'
  notemessageDocs:string = 'Telemedicine appointment is blocked. Please complete the requested document, and then you will be able to schedule a Telemedicine appointment.'
  newMessage: string= 'We provide "Schedule A" letter and "Work from Home" documentation.';
  isDoubleCheck: boolean = false;
  trearmentName: any = "";
  rescheduledData: any;
  patientUserId: any;
  isAnnouncementbanner: boolean = false;
  stateList: any = [];
  currentPatientID:number;
  constructor(
    private http: HttpService,
    private formService: CustomFormHandlerService,
    private router: Router,
    private observable: ObservableHelperService,
    private dataService: DataService,
    private cognitoService: CognitoService,
    private route: ActivatedRoute
  ) {
    this.params = this.route.snapshot.queryParams;
    if (Object.keys(this.params).length > 0) {
      this.typeAppointmentFormConfig.forEach(eachConfig => {
        eachConfig.editable = false;
      });
      this.appointmentForFormConfig.forEach(element => {
        element.editable = false;
      });
    }
  }
  rescheduleForm: any;

  ngAfterViewChecked(){
    let message = document.getElementById("message");
    let newmessage = document.getElementById("newmessage");
    let messageM = document.getElementById("message-m");
    let isLogedIn = window.localStorage.getItem('isLogedIn');
    if(message && this.patientsList && isLogedIn){
      window.setTimeout(() => {
        message.classList.add('expand')
        newmessage.classList.add('expand')
        messageM.classList.add('expand-m')
      }, 10);
    } else if(message && !isLogedIn){
      window.setTimeout(() => {
        message.classList.add('expand')
        newmessage.classList.add('expand')
        messageM.classList.add('expand-m')
      }, 10);
    }
  }
  ngOnInit() {
    this.http.getUserDetails();
    this.getStateList();
    this.getAnnouncmentData();
    let preData = this.dataService.getData(this.dataService.dataObject.PRE_APPOINTMENT);
    this.appointmentId = (preData !== undefined) ? preData.appointmentId : this.appointmentId;
    this.locationId = (preData !== undefined) ? preData.locationId : this.locationId;
    this.slotId = (preData !== undefined) ? preData.slotId : this.slotId;
    this.timeRange = (preData !== undefined) ? preData.timeRange : this.timeRange;
    this.patientId = (Object.keys(this.route.snapshot.queryParams).length > 0) ? Number(this.route.snapshot.queryParams['patientId']) : this.dataService.patientDetails;
    // this.userLogedIn = JSON.parse(window.localStorage.getItem('isLogedIn'));
    let data = this.observable.getAppointmentAddPatient();
    let appointmentFormData = (data.value) ? this.dataService.getPreviousData(this.dataService.dataObject.APPOINTMENT_DETAIL) : this.dataService.getData(this.dataService.dataObject.APPOINTMENT_DETAIL);
    this.appointmentId = (appointmentFormData !== undefined && appointmentFormData.typeAppointmentData) ? appointmentFormData.typeAppointmentData[0].value : this.appointmentId;
    this.appointmentTypes = this.massageAppointmentType(this.availabiltyData.metadata.appointmentTypes);
    let appointmentIndex;
    this.appointmentTypes.forEach((treatment, index) => {
      if (treatment.appointmentTypeId === +this.appointmentId) {
        appointmentIndex = index;
        return false;
      }
    });
    this.locations = this.filteringLocations(this.appointmentTypes[appointmentIndex].locations, this.availabiltyData.metadata.locations);
    this.locationId = (appointmentFormData !== undefined && appointmentFormData.typeAppointmentData) ? this.getLocationId(appointmentFormData.typeAppointmentData[1]) : this.locationId;
    this.slotId = (appointmentFormData !== undefined && appointmentFormData.typeAppointmentData) ? this.getSlotId(appointmentFormData.typeAppointmentData[3]) : this.slotId;
    // populate the selected user.
    if(appointmentFormData){
      this.patientId = appointmentFormData.appoinmentData ? appointmentFormData.appoinmentData[1].value : this.patientId;
    }
    this.initializingFroms(this.appointmentId, this.locationId, this.slotId);
    // if (this.userLogedIn) {
    this.cognitoService.getUser().then(user => {
      this.userLogedIn = Boolean(user && user.email);
      if (!(user && user.email)) {
        this.typeAppointmentForm = this.formService.createNewForm(this.typeAppointmentFormConfig);
        this.appointmentForForm = this.formService.createNewForm(this.appointmentForFormConfig);
      } else {
        this.userId = user.userId;
        this.userName = user.firstName + ' ' + user.lastName;
        let payload = {
          type: 'GET',
          url: 'getPatient',
          isDeveloper: true,
          pathVariables: [this.userId],
        };
        this.http.makeHttpRequest(payload).subscribe(res => {
          this.availabiltyData.showScreen = true;
          if (this.http.isSuccessfulResponse(res)) {
            // tslint:disable-next-line:no-shadowed-variable
            // Filtering patients that require a profile update.
            let patientListTemp = [];
            res.data.patients.forEach((element) => {
              let pendingTherapistReq = false;
              element.pendingDocumentRequest.forEach(element => {
                if(element.documentIds == '[9]') pendingTherapistReq = true;
              });
              let patientBasicInfo = JSON.parse(element.patientBasicInfo)
              patientListTemp.push({
                ...element,
                blockedList: this.massageBlockedTreatments(element.blockedServices),
                pendingTherapistReq: pendingTherapistReq,
                patientBasicInfo: patientBasicInfo,
                isStateValid: this.stateList.includes(patientBasicInfo.streetAddress.state)
              })
            });
            let patientList: Array<Record<string, any>> = patientListTemp.filter(each => (each.removedFlag === 0 && each.status === 'active' && !each.longTimeUpdate && !each.isInactive && each.blockedServices.length < 11));
            this.patientsList = patientList;
            if(this.rescheduleFormData){
              this.patientUserId = this.rescheduleFormData.patient.patientId;
            }
            else{
            this.patientUserId = (this.patientId !== undefined) ? this.patientId : this.patientsList[0].patientId;
            }
            this.patientsList.forEach(element => {
              if(element.patientId == this.patientUserId) {
                this.patientdocdetails = element;
              }
            });
            patientList = patientList.map(item => {
              return {
                label: item.firstName + ' ' + item.lastName,
                value: item.patientId,
                icon: 'user'
              };
            });
            this.patientList = patientList;
            this.appointmentForFormConfig.push({
              type: 'dropdown',
              placeholder: 'Select Patient',
              label: '',
              required: true,
              key: 'primaryholder',
              labelImage: '',
              value: (this.patientId !== undefined) ? this.patientId : this.patientList[0].value,
              list: patientList,
              controlType: 'dropdown',
              editable: true,
              emptyValueMessage: 'Please select patient',
              validatorsData: ['required'],
              validators: [],
              className: 'drop--down',
              focus: true,
              showontoggle: true,
            });
            patientList.push({ label: 'Add New         ________________', value: 'addNew', icon: 'plus-circle' });
            data = this.observable.getAppointmentAddPatient();
            appointmentFormData = (data.value) ? this.dataService.getPreviousData(this.dataService.dataObject.APPOINTMENT_DETAIL) : this.dataService.getData(this.dataService.dataObject.APPOINTMENT_DETAIL);
            this.appointmentForFormConfig = (appointmentFormData !== undefined && appointmentFormData.appoinmentData != null && !this.isReshedule) ? appointmentFormData.appoinmentData : this.appointmentForFormConfig;
            this.appointmentForFormConfig[1].list = this.patientList;
            this.appointmentForFormConfig[1].value = (this.appointmentForFormConfig[1].value === 'addNew') ? (this.patientId !== undefined) ? this.patientId : '' : (this.patientId !== undefined) ? this.patientId : this.appointmentForFormConfig[1].value;
            this.appointmentForFormConfig[1].value = (this.appointmentForFormConfig[1].value === 'addNew') ? this.patientList[0].value : this.appointmentForFormConfig[1].value
            this.typeAppointmentFormConfig = (appointmentFormData !== undefined && !this.isReshedule) ? appointmentFormData.typeAppointmentData : this.typeAppointmentFormConfig;
            this.detailsData = (appointmentFormData !== undefined && appointmentFormData.detailsData != null && !this.isReshedule) ? appointmentFormData.detailsData : this.detailsData;
            this.timeRange = (appointmentFormData !== undefined && !this.isReshedule) ? appointmentFormData.timeRange : this.timeRange;
            if (this.rescheduleFormData) {
              // debugger;
              // this.patientId = this.rescheduleFormData.patient.patientId
              let apptIndex;
              this.appointmentTypes.forEach((treatment, index) => {
                if (treatment.appointmentTypeId === +this.rescheduleFormData.appointmentTypeId) {
                  apptIndex = index;
                  return false;
                }
              });
              this.typeofOffice = this.appointmentTypes[apptIndex];
              this.appointmentId = this.rescheduleFormData.appointmentTypeId;
              this.locations = this.filteringLocations(this.typeofOffice.locations, this.availabiltyData.metadata.locations);
              this.patientName = `${this.rescheduleFormData.patient.firstName} ${this.rescheduleFormData.patient.lastName}`;
              this.locationId = this.getLocationId(this.rescheduleFormData.locationId);
              this.slotId = this.rescheduleFormData.slot - 1;
              this.patientsList.forEach(element => {
                if(element.patientId == this.rescheduleFormData.patient.patientId){
                  this.selectedPatientData = element;
                }
              });
              this.initializingFroms(this.appointmentId, this.locationId, this.slotId);
              this.detailsData[0].lable = this.location.label;
              let times = this.time.value.split('-');
              this.timeRange = {
                start: times[0],
                end: times[1]
              };
            }
            if (Object.keys(this.params).length > 0) {
              this.typeAppointmentFormConfig.forEach(eachConfig => {
                eachConfig.editable = false;
              });
              this.appointmentForFormConfig.forEach(element => {
                element.editable = false;
              });
            }
            this.typeAppointmentForm = this.formService.createNewForm(this.typeAppointmentFormConfig);
            this.appointmentForForm = this.formService.createNewForm(this.appointmentForFormConfig);
            this.rescheduleForm = this.formService.initForm(this.formService.formTypes.RESCHEDULE);
            if (this.isReshedule) {
              this.typeAppointmentForm.formInputs.forEach(element => {
                if (element.key === 'type_of_appointment') {
                  element.editable = false;
                }
              });
            }
            this.previousPatientId = this.patientId;
            this.setDetails();
            if(this.patientId !== undefined && appointmentFormData == undefined) {
              this.selectedPatient();
            }
            if(this.patientList[0].value && !this.rescheduleFormData){
              this.selectedPatient();
            }
          } else {
            let message = 'Failed to fetch patient data. Please try again';
            message = (res.error) ? res.error.message : message;
            this.observable.showToast(TOAST_STATUSES.ERROR, message);
          }
        });
      }
    });

    // }
    //  else {
    //   this.typeAppointmentForm = this.formService.createNewForm(this.typeAppointmentFormConfig);
    //   this.appointmentForForm = this.formService.createNewForm(this.appointmentForFormConfig);
    // }
    if (appointmentFormData !== undefined && !this.isReshedule) {
      this.getAvailabiltySlots();
    }
    if (Object.keys(this.params).length > 0) {
      let location = this.availabiltyData.metadata.locations.find(eachLocation => eachLocation.locationId === this.params.locationId)
      this.detailsData[0].lable = location.locationName;
      this.detailsData[1].lable = this.params.appointmentDate;
      this.detailsData[2].lable = this.params.appointmentTime;
    } else {
      this.blockedTreatments();
    }
    if (this.detailsData.every(eachData => eachData.lable != null)) {
      this.isDetails = true;
    } else {
      this.isDetails = false;
    }
  }


  sixMonthPatient(patient) {
    if (patient && typeof (patient) === 'object') {
      let lastUpdated = new Date(patient.lastUpdate);
      let lastSixMonthDate = this.addMonths(new Date(), -6); // six months before now
      if (lastUpdated <= lastSixMonthDate) {
        return true;
      } else {
        return false;
      }
    }
  }

  addMonths(date, months) {
    date.setMonth(date.getMonth() + months);
    return date;
  }
  ngOnChanges(changes: SimpleChanges): void {
    // Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    // Add '${implements OnChanges}' to the class.
  }
  getSlotId(data) {
    if (data && typeof (data) === 'object') {
      let index;
      data.fields.forEach((ele, ind) => {
        if (ele.value === data.value) {
          index = ind;
        }
      });
      return index;
    }
  }

  // This is for disable option to place at last positi
  initializingFroms(appointmentId, locationId, slotId) {
    let metaData = this.availabiltyData.metadata;
    let appointmentIndex;
    this.appointmentTypes = this.massageAppointmentType(metaData.appointmentTypes);
    this.appointmentTypes.forEach((treatment, index) => {
      if (treatment.appointmentTypeId === +appointmentId) {
        appointmentIndex = index;
        return false;
      }
    });
    this.locations = this.filteringLocations(this.appointmentTypes[appointmentIndex].locations, metaData.locations);
    this.locationType = this.getLocationTypeName(this.locations);
    this.typeAppointmentFormConfig.forEach(element => {
      if(element.key == 'note_type'){
        element.note = this.locationType === 'online' ? 'This is an online appointment. An appointment link will be emailed to you.' : 'This is an In-person appointment. You are required to visit the clinic.'
      }
    });
    if(this.patientdocdetails && this.patientdocdetails.pendingDocumentRequest.length > 0 && locationId != null){
    let documentDate = this.patientdocdetails.pendingDocumentRequest[0].date;
    let receiveOnDate = new Date(documentDate);
    receiveOnDate.setDate(receiveOnDate.getDate());
    let documentdateArray = receiveOnDate.getTime();
    var date  = this.formatDate( new Date());
    let sentOnDate = new Date(date);
    sentOnDate.setDate(sentOnDate.getDate());
    var dateArray = sentOnDate.getTime();
    let differenceInTime = dateArray-documentdateArray;
    let differenceInDays = Math.floor(differenceInTime / (1000 * 3600 * 24));
    if(differenceInDays/30 >= 6 && this.locations[locationId].value ==   'TEL'){
      this.requestDocumentDelay = true;
    }
    else{
      this.requestDocumentDelay = false;
    }
    }
  
    let blockMm = this.selectedPatientData ? this.selectedPatientData.blockedList.find(ele => ele.appointmentTypeId == appTypeEnums.medicationManagement) : null
    if(Object.keys(this.params).length <= 0 && appointmentId == appTypeEnums.medicationManagement && blockMm && blockMm.blockOffline){
      locationId = this.locations.findIndex(ele => ele.locationId == locationIdEnums.telemedicine)
    }
    this.slots = this.filteringSlots(this.appointmentTypes[appointmentIndex].slots, metaData.slots);
    let selectedAppointmentTypes = this.appointmentTypes
    selectedAppointmentTypes = this.appointmentTypes.filter((ele: any) => ele.className !== 'disable-radio');
    let locationsCopy = this.locations;
    let selectedAppointementId = this.appointmentTypes[appointmentIndex].appointmentTypeId
    locationsCopy = this.locations.filter((ele: any) => ele.className !== 'disable-radio')
    
    if(this.selectedPatientData){
    this.selectedPatientData.blockedServices.forEach(each => {
      if(each.appointmentTypeId === selectedAppointementId ){
        locationsCopy.forEach(e => {
          if(e.locationId === each.locationId){
            e.isSelected = true;
          }
        });
      }
    });
    }   
    locationsCopy = locationsCopy.filter((ele: any) => ele.isSelected !== true)
    let selectedAppointementIndex;
    let selectedLocationIndex;
    if(locationId != null){
      let selectedLocationId = this.locations[locationId].value;
      locationsCopy = locationsCopy.filter((ele: any) => ele.isSelected !== true)
      selectedLocationIndex = locationsCopy.findIndex(ele => ele.locationId == selectedLocationId);
      this.typeAppointmentFormConfig[2].showontoggle = true;
      this.typeAppointmentFormConfig[3].showontoggle = true;
    }
    selectedAppointementIndex = selectedAppointmentTypes.findIndex(ele => ele.appointmentTypeId == selectedAppointementId)
    this.typeAppointmentFormConfig[0].list = selectedAppointmentTypes;
    this.typeAppointmentFormConfig[0].value = selectedAppointmentTypes[selectedAppointementIndex].value;
    this.typeAppointmentFormConfig[1].list = locationsCopy;
    if(locationId != null){
      this.typeAppointmentFormConfig[1].value = locationsCopy[selectedLocationIndex].value;
    }
    else{
      this.typeAppointmentFormConfig[1].value = null;
    }
    this.typeAppointmentFormConfig[3].fields = this.slots;
    if(slotId != null){
      this.typeAppointmentFormConfig[3].value = this.slots[slotId].value;
      this.timeSlot = true;
    }
    else{
      this.typeAppointmentFormConfig[3].value = null;
    }
    this.typeofOffice = selectedAppointmentTypes[selectedAppointementIndex];
    if(locationId != null){
    this.location = locationsCopy[selectedLocationIndex];
    }
    this.time = this.slots[slotId];
    // this.setBlinkMessage();
  }
  getAnnouncmentData() {

    let payload = {
      type: HTTPMethods.GET,
      url: API_URLS.EMAIL_TEMPLATE_DETAILS,
      isDeveloper: true,
      pathVariables: [16]
    };
    this.http.makeHttpRequest(payload).subscribe((res) => {
     if (this.http.isSuccessfulResponse(res)) {
      this.showScroll=true;
      let divvalue = document.createElement('div')
      divvalue.innerHTML = res.data.htmlTemplate
      let announcementBannerdata = divvalue.children[0];
      let divvalue2 = document.createElement('div')
      let size =announcementBannerdata.childElementCount
      for ( var i =0 ; i < size; i++){
        divvalue2.append(announcementBannerdata.childNodes[0]);
      }
      this.announcementBannerVal = divvalue2.outerHTML
    } else {
      let message = TOAST_MESSAGES.ANNOUNCEMENT_BANNER_DATA;
      message = (res.error) ? res.error.message : message;
      this.observable.showToast(TOAST_STATUSES.ERROR, message);
    }
    });
  }
  filteringSlots(data, meta) {
    if (!data && !Array.isArray(data)) { return []; } else {
      let locations = meta.filter(element => data.includes(element.slotId));
      return locations.map(loc => {
        return {
          ...loc,
          label: this.getLable(loc.begin, loc.end),
          value: `${loc.begin}-${loc.end}`,
          img: loc.slotName.toLowerCase(),
          width: '27',
          height: '27'
        };
      });
    }
  }
  getHeightAndWidth(data) {
    if (data === 'morning') {
      return {
        width: '27',
        height: '16'
      };

    } else if (data === 'mid-day') {
      return {
        width: '25',
        height: '25'
      };
    } else if (data === 'afternoon') {
      return {
        width: '27',
        height: '19'
      };
    } else if (data === 'evening') {
      return {
        width: '27',
        height: '19'
      };
    }
  }
  getLable(start, end) {
    let from;
    let to;
    from = start.split(':');
    to = end.split(':');
    if (from[0] < 12) {
      // tslint:disable-next-line: radix
      return `${parseInt(from[0])}:${from[1]}-${parseInt(to[0])}:${parseInt(to[1])} AM`;
    } else {
      if (from[0] === '12') {
        return `${from[0]}:${from[1]}-${to[0] - 12}:${to[1]} PM`;
      } else {
        return `${from[0] - 12}:${from[1]}-${to[0] - 12}:${to[1]} PM`;
      }
    }
  }

  filteringLocations(data, meta) {
    if (!data && !Array.isArray(data)) { return []; } else {
      let locations = meta.filter(eachLoc => data.includes(eachLoc.locationId));
      let className = '';
      let medMngmt = this.selectedPatientData ? this.selectedPatientData.blockedList.find(ele => ele.appointmentTypeId == appTypeEnums.medicationManagement) : null
      if(medMngmt && this.appointmentId == appTypeEnums.medicationManagement){
          className = 'disable-radio'
      }
      return locations.map(eachLocation => {
        return {
          ...eachLocation,
          label: eachLocation.locationName,
          value: eachLocation.locationId,
          className: className ? (((medMngmt.blockOnline && eachLocation.locationId == locationIdEnums.telemedicine) || (medMngmt.blockOffline && eachLocation.locationId != locationIdEnums.telemedicine)) ? className : '') : ''
        };
      });
    }
  }
  massageAppointmentType(data) {
    if (!data && !Array.isArray(data)) { return []; }
    return data.map(eachAppointment => {
      if(eachAppointment.appointmentTypeName == 'TMS'){
        eachAppointment.appointmentTypeName = 'Transcranial magnetic stimulation(TMS)';
      }
      let medMangmt = this.selectedPatientData ? this.selectedPatientData.blockedList.find(ele => ele.appointmentTypeId == appTypeEnums.medicationManagement): null;
      let className = '';
      if(eachAppointment.appointmentTypeId == appTypeEnums.medicationManagement && medMangmt) {
        className = (medMangmt.blockOnline && medMangmt.blockOffline) ? 'disable-radio' : ''
        this.selectedPatientData.blockedServicesTypeLengths.forEach(ele =>{
          if(ele.appointmentTypeId === eachAppointment.appointmentTypeId && ele.blockedLocationCount == eachAppointment.locations.length){
            className ='disable-radio'
          }
        })
      }
      else {
        if(this.selectedPatientData){
          this.selectedPatientData.blockedList.forEach(ele=> {
            if(ele.appointmentTypeId === eachAppointment.appointmentTypeId && ele.locationId == null){
              className ='disable-radio' 
            }
  
            else{
              className = ''
            }
          });
          this.selectedPatientData.blockedServicesTypeLengths.forEach(ele =>{
            if(ele.appointmentTypeId === eachAppointment.appointmentTypeId && ele.blockedLocationCount == eachAppointment.locations.length){
              className ='disable-radio'
            }
          })
        }
        // className = ((this.selectedPatientData && this.selectedPatientData.blockedList.some(ele => ele.appointmentTypeId === eachAppointment.appointmentTypeId)) || eachAppointment.appointmentTypeId === 2) ? 'disable-radio' : '' // temporarly hiding physcological radio button
      }
      return {
        ...eachAppointment,
        label: eachAppointment.appointmentTypeName,
        value: eachAppointment.appointmentTypeId,
        className: className
      };
    });
  }



  /**
* Api call to get shedularData
*/
  getAvailabiltySlots() {
    let params = {
      appointmentTypeId: this.typeofOffice.appointmentTypeId,
      locationId: this.location.value,
      beginTime: this.time.begin,
      endTime: this.time.end,
      days: (this.typeofOffice.appointmentTypeId === 3 || this.typeofOffice.appointmentTypeId === 4) ? 64 : 40,
    };
    let payload = {
      type: 'GET',
      url: 'availability',
      isDeveloper: true,
      params,
      isAuthToken: true
    };
    this.http.makeHttpRequest(payload).subscribe((res) => {
      if (this.http.isSuccessfulResponse(res) && res) {
        this.availabiltyData = res.data;
      } else {
        let message = 'Failed to fetch availability data. Please try again';
        message = (res.error) ? res.error.message : message;
        this.observable.showToast(TOAST_STATUSES.ERROR, message);
      }
      this.availabiltyData.showScreen = true;
    });
  }
  
  selectedPatientID(event){
    this.currentPatientID = event.patientID;    
  }

  getDropValueList(event){
    if (event.field.key === 'type_of_appointment') {
      this.typeofOffice = event.locationValue;
      this.locationId = 0;
      this.appointmentId = this.typeofOffice.appointmentTypeId;
    }else if (event.field.key === 'preffered_location') {
    this.locationType = event.locationValue.locationType;
    this.location = event.locationValue;
    this.setBlinkMessage();
    this.locationId = this.getLocationId(event.locationValue);
    this.typeAppointmentFormConfig[2].showontoggle = true
    this.typeAppointmentFormConfig[3].showontoggle = true
    }
    this.initializingFroms(this.appointmentId, this.locationId, this.slotId);

    this.typeOfTreatment.emit({location: this.location, apptType: this.appointmentId, patientID: this.currentPatientID});
    this.getAppointmetDetails(event.field, event.locationValue);
    this.dataService.setData(this.dataService.dataObject.APPOINTMENT_DETAIL, this.appointmentDetailObject);
    this.getAvailabiltySlots();
  }
  clickedRadio(event) {
    let radio = event.radio;
    let field = event.field;
    if (field.key === 'type_of_appointment') {
      this.typeofOffice = radio;
      // this.locationId = 0;
      this.appointmentId = this.typeofOffice.appointmentTypeId;
    } else if ((field.key === 'prefferd_time')) {
      this.timeSlot = true;
    
      this.slotId = radio.slotId - 1;
      this.time = radio;
      let timeRange = radio.value.split('-');
      this.timeRange = {
        start: timeRange[0],
        end: timeRange[1]
      };
      this.appointmentDetailObject.timeRange = this.timeRange;
    } else if (field.key === 'preffered_location') {
      this.locationType = radio.locationType;
      this.location = radio;
      this.setBlinkMessage();
      this.locationId = this.getLocationId(radio);
    }
    this.initializingFroms(this.appointmentId, this.locationId, this.slotId);

    this.typeOfTreatment.emit({location: this.location, apptType: this.appointmentId, patientID:this.currentPatientID});
    this.getAppointmetDetails(field, radio);
    this.dataService.setData(this.dataService.dataObject.APPOINTMENT_DETAIL, this.appointmentDetailObject);
      this.getAvailabiltySlots();
  }

  setBlinkMessage() {
    let blinkClass = 'blink--msg';
    if (this.location.locationType === 'online') {
      this.typeAppointmentFormConfig[1].className = `${this.typeAppointmentFormConfig[1].className} ${blinkClass}`;
    } else {
      if (this.typeAppointmentFormConfig[1].className.includes(blinkClass)) {
        let classAray = this.typeAppointmentFormConfig[1].className.split(' ');
        classAray = classAray.filter(each => each !== blinkClass);
        this.typeAppointmentFormConfig[1].className = `${classAray.join(' ')}`;
      }
    }
  }

  getLocationId(location) {
    let index;
    if (location && typeof (location) === 'object') {
      this.locations.forEach((element, ind) => {
        if (element.locationId === location.value) {
          index = ind;
        }
      });
      return index;
    } else if (location && typeof (location) === 'string') {
      this.locations.forEach((element, ind) => {
        if (element.locationId === location) {
          index = ind;
        }
      });
      return index;
    }
  }
  getLocationTypeName(location) {
    let index;
    if (location && typeof (location) === 'object') {
      this.locations.forEach((element, ind) => {
        if(ind === this.locationId) {
          index = element.locationType;
        }
      });
      return index;
    } else if (location && typeof (location) === 'string') {
      this.locations.forEach((element, ind) => {
        if (ind === this.locationId) {
          index = ind;
        }
      });
      return index;
    }
  }

  getAppointmetDetails(field, option) {

    if (field.key === 'type_of_appointment' && option.value === locationIdEnums.telemedicine) {
      this.detailsData[0].lable = option.label;
    } else {
      this.detailsData[0].lable = this.location.label;
    }
    this.setDetails();
  }



  navigateAddNewPatient(event) {
    if (event.value === 'addNew') {
      this.saveFormData = true;
      this.observable.setAppointmentAddPatient(true);
      setTimeout(() => {
        this.router.navigate(['new-patient']);
        window.scroll({
          top: 0,
          left: 0,
          behavior: 'smooth'
        });
      }, 15);

    } else {
      this.selectedPatient();
    }
  }
  setDetails() {
    let patientId = this.selectedPatientData ? this.selectedPatientData.patientId : this.previousPatientId;
    if (this.detailsData && Array.isArray(this.detailsData) && (this.previousPatientId === patientId)) {
      if (this.detailsData[0].lable != null && this.detailsData[1].lable != null && this.detailsData[2].lable != null) {
        this.isDetails = true;
      }
    }
  }
  getOfficeForm(event) {
    if (event) {
      this.officeData = event;
      this.isOfficeFilled = true;
      this.typeAppointmentFormConfig = this.assignFormValues(event, this.typeAppointmentFormConfig);
      this.appointmentDetailObject.typeAppointmentData = this.typeAppointmentFormConfig;
      this.appointmentDetailObject.timeRange = this.timeRange;
      let appointmentFormData = this.dataService.getData(this.dataService.dataObject.APPOINTMENT_DETAIL);
      this.dataService.setData(this.dataService.dataObject.APPOINTMENT_DETAIL, this.appointmentDetailObject);
    }
    this.setValidation();
  }
  getSheduleData(event) {
    if (event) {
      this.previousPatientId = this.selectedPatientData ? this.selectedPatientData.patientId: undefined;
      this.detailsData[1].lable = event.date;
      this.detailsData[2].lable = event.time;
      this.appointmentDetailObject.detailsData = this.detailsData;
      this.setDetails();
    }
  }
  getTreatment() {
    if (this.appointmentDetailObject.typeAppointmentData && this.appointmentDetailObject.typeAppointmentData[0].list) {
      let treatment = this.appointmentDetailObject.typeAppointmentData[0].list.filter(eachTreatment => eachTreatment.appointmentTypeId === this.appointmentDetailObject.typeAppointmentData[0].value);
      return treatment[0].appointmentTypeName;
    }
  }
  cancel() {
    this.router.navigate([`${ROUTE_PATHS.EMPTY}`]);
  }
  getFinalSheduleData(event) {
    if (event) {
      this.bookedSlotData = event.selectedSlot;
      this.appointmentDetailObject.shedularData = event;
      let shedularData = event.selectedSlot;
      if (shedularData.time != null && shedularData.date != null && shedularData.status != null) {
        this.detailsData[1].lable = shedularData.date;
        this.detailsData[2].lable = shedularData.time;
        this.appointmentDetailObject.detailsData = this.detailsData;
        this.setDetails();
        this.isSheduleDataFilled = true;
        this.dataService.setData(this.dataService.dataObject.APPOINTMENT_DETAIL, this.appointmentDetailObject);
      } else {
        this.isSheduleDataFilled = false;
      }

    }
    this.setValidation();
  }

  /**
   * This function is for to check all forms are filled or not
   * If all forms are filled then it will send trigger to parent component
   * isOfficeFilled: Office form filled flag
   * isPatientFilled: Patient form filled flag
   * isSheduleDataFilled: Slot selected flag
   */
  setValidation() {
    // setTimeout(() => {
      if (this.isOfficeFilled && this.isPatientFilled && this.isSheduleDataFilled) {
        this.checkLastVist();
        this.isOfficeFilled = false;
        this.isPatientFilled = false;
        this.isSheduleDataFilled = false;
      }
    // }, 0);
  }

  /**
   * 
   * @param event :Patient form group data
   */
  getPatientData(event) {
    if (event) {
      this.isPatientFilled = true;
      this.appointmentForFormConfig = this.assignFormValues(event, this.appointmentForFormConfig);
      this.appointmentDetailObject.appoinmentData = this.appointmentForFormConfig;
      this.dataService.setData(this.dataService.dataObject.APPOINTMENT_DETAIL, this.appointmentDetailObject);
    }
    this.setValidation();
  }


  assignFormValues(formData, formConfig) {
    let keys = Object.keys(formData.value);
    formConfig.forEach((eachConfig, index) => {
      if (keys.includes(eachConfig.key)) {
        eachConfig.value = formData.value[eachConfig.key];
      }
    });
    return formConfig;
  }


  toggleScroll(status) {
    document.body.style.overflow = (status) ? 'hidden' : 'scroll';
  }
  cancelPopupShowHideStatusFun(status) {
    this.cancelPopupShowHide = status.cancelpopup;
    this.toggleScroll(this.cancelPopupShowHide);
  }
  getLoginPopup(event) {
    // window.scrollTo(0, 0)
    this.saveFormData = true;
    this.observable.setAppointmentAddPatient(true);
    setTimeout(() => {
      if (Object.keys(this.params).length > 0) {
        window.localStorage.setItem('previousUrl', this.router.url);
      } else {
        let preData = this.dataService.getData(this.dataService.dataObject.PRE_APPOINTMENT);
        if (preData !== undefined) {
          window.localStorage.setItem('beforLoginTypeData', JSON.stringify(preData));
        }
        window.localStorage.setItem('beforLogin', JSON.stringify(true));
        let typeData = this.dataService.getPreviousData(this.dataService.dataObject.APPOINTMENT_DETAIL);
        typeData.typeAppointmentData = typeData.typeAppointmentData.map(element => {
          return {
            ...element,
            validators: []
          };
        });
        window.localStorage.setItem('APPOINTMENT_DETAIL', JSON.stringify(typeData));
      }
      this.router.navigate([`${ROUTE_PATHS.ONBOARD}`]);
    }, 15);
  }

  getRescheduleData(event) {
    if (event && this.isOfficeFilled) {
      this.rescheduledData = event;
      this.isDoubleCheck = true;
    }
  }

  /**
   * This API is for the selected patient has crossed minInterval duraction or not to book an appointment
   */
  checkLastVist() {
    if (Object.keys(this.params).length <= 0) {
      const currentDate: Date = new Date();
      const timeDiff: string = (currentDate.getUTCHours() - currentDate.getHours()) + ':' + (currentDate.getUTCMinutes() - currentDate.getMinutes());
      const splitedDateArray: Array<any> = currentDate.toLocaleString().split(', ');
      const timestampDate: string = splitedDateArray[0];
      const timestamp: string = splitedDateArray[1].substring(0, splitedDateArray[1].length - 6) + splitedDateArray[1].slice(-3);
      const appointmentDetails: Record<string, any> = this.dataService.data;
      const bodyData: ILastVisitPayloadBodyI = {
          appointmentDate: this.formatDate(appointmentDetails.appointmentDetail.shedularData.selectedSlot.date),
          appointmentTime: appointmentDetails.appointmentDetail.shedularData.selectedSlot.time,
          appointmentTypeId: appointmentDetails.appointmentDetail.typeAppointmentData[0].value,
          locationId: appointmentDetails.appointmentDetail.typeAppointmentData[1].value,
          patientId: appointmentDetails.appointmentDetail.appoinmentData[1].value,
          timeDiff,
          timestamp,
          timestampDate,
          userId: this.userId,
      };
      const payloadData: ILastVisitPayloadI = {
        type: HTTPMethods.POST,
        url: API_URLS.CHECK_LAST_VISIT,
        isDeveloper: true,
        body: bodyData,
      };
      /**
       * This API is for the selected patient has crossed minInterval duraction or not to book an appointment.
       * Example:- the minInterval b/w two consecutive appointments is 27 days
       * Then this API will return if current appointment crossed min interval or not
       */
      this.http.makeHttpRequest(payloadData).subscribe((res) => {
        if (res.status === 'success') {
          this.isDoubleCheck = true;
          if(res.data.lastAppointmentData){
          this.dataService.previousAppointmentData = JSON.parse(res.data.lastAppointmentData.appointmentData);
          }else{
            this.dataService.previousAppointmentData = null;
          }
          this.previousPatientId = this.selectedPatientData.patientId;
        } else {
          let message = res.error.message;
          this.observable.showToast(TOAST_STATUSES.ERROR, message);
        }
      });
    } else {
      const currentDate: Date = new Date();
      const timeDiff: string = (currentDate.getUTCHours() - currentDate.getHours()) + ':' + (currentDate.getUTCMinutes() - currentDate.getMinutes());
      const splitedDateArray: Array<any> = currentDate.toLocaleString().split(', ');
      const timestampDate: string = splitedDateArray[0];
      const timestamp: string = splitedDateArray[1].substring(0, splitedDateArray[1].length - 6) + splitedDateArray[1].slice(-3);
      const appointmentDetails: Record<string, any> = this.dataService.data;
      const bodyData: ILastVisitPayloadBodyI = {
          appointmentDate: this.formatDate(appointmentDetails.appointmentDetail.shedularData.selectedSlot.date),
          appointmentTime: appointmentDetails.appointmentDetail.shedularData.selectedSlot.time,
          appointmentTypeId: appointmentDetails.appointmentDetail.typeAppointmentData[0].value,
          locationId: appointmentDetails.appointmentDetail.typeAppointmentData[1].value,
          patientId: appointmentDetails.appointmentDetail.appoinmentData[1].value,
          timeDiff,
          timestamp,
          timestampDate,
          userId: this.userId,
      };
      const payloadData: ILastVisitPayloadI = {
        type: HTTPMethods.POST,
        url: API_URLS.LAST_APPOINTMENT_DATA,
        isDeveloper: true,
        body: bodyData,
      };
      /**
       * This API is for the selected patient has crossed minInterval duraction or not to book an appointment.
       * Example:- the minInterval b/w two consecutive appointments is 27 days
       * Then this API will return if current appointment crossed min interval or not
       */
      this.http.makeHttpRequest(payloadData).subscribe((res) => {
        if (res.status === 'success') {
          this.isDoubleCheck = true;
          if(res.data.lastAppointmentData){
          this.dataService.previousAppointmentData = JSON.parse(res.data.lastAppointmentData.appointmentData);
          }else{
            this.dataService.previousAppointmentData = null;
          }
          this.submitEvent.emit({status: true, step: 1});
        } 
      }); 
    }
  }

  /**
   * This function will return the date formate as "YYYY-MM-DD"
   * @param date :selected date
   * @returns :"YYYY-MM-DD"
   */
  formatDate(date: Date | string) {
    var d = new Date(date);
    var month = '' + (d.getMonth() + 1);
    var day = '' + d.getDate();
    var year = d.getFullYear();

    if (month.length < 2) {
      month = '0' + month;
    }
    if (day.length < 2) {
      day = '0' + day;
    }
    return [year, month, day].join('-');
  }

  /**
   * get the selected user to check if the appt is users first ketamine.
   */
  selectedPatient(){
    if(Object.keys(this.params).length <= 0) this.isDetails = false;
    let selectedPatient: ISelectedPatientDetails = {
      isketamineEnrollmentRequired: false
    };
    this.patientsList.forEach(element => {
      if(element.patientId == this.appointmentForFormConfig[1].value){
        selectedPatient.isketamineEnrollmentRequired = element.isketamineEnrollmentRequired != null ? element.isketamineEnrollmentRequired : false;
        this.selectedPatientData = element;
      }
    });
    this.patientDetails.emit(selectedPatient);
    this.blockedTreatments();
  }

  /**
   * Hide blocked treatments.
   */
  blockedTreatments(){
    if(Object.keys(this.params).length <= 0 && this.selectedPatientData){
      this.appointmentTypes.forEach(element => {
        let medMangmt = this.selectedPatientData.blockedList.find(ele => ele.appointmentTypeId == appTypeEnums.medicationManagement);
        if(element.appointmentTypeId == appTypeEnums.medicationManagement && medMangmt){
        element.className = (medMangmt.blockOnline && medMangmt.blockOffline) ? 'disable-radio' : ''
        this.selectedPatientData.blockedServicesTypeLengths.forEach(ele =>{
          if(ele.appointmentTypeId === element.appointmentTypeId && ele.blockedLocationCount == element.locations.length){
            element.className = 'disable-radio'
          }
        })
        }
        else {
          this.selectedPatientData.blockedList.forEach(ele=>{
            if(ele.appointmentTypeId == element.appointmentTypeId && ele.locationId == null){
              element.className = 'disable-radio'
            }
          })
          this.selectedPatientData.blockedServicesTypeLengths.forEach(ele =>{
            if(ele.appointmentTypeId === element.appointmentTypeId && ele.blockedLocationCount == element.locations.length){
              element.className = 'disable-radio'
            }
          })
        }
      });
      let field, radio;
      this.appointmentTypes.some(element => {
        if(element.className === ''){
          field = {
            key: "type_of_appointment"
          };
          radio = {
            appointmentTypeId: element.appointmentTypeId,
            value: element.appointmentTypeId,
            label: element.appointmentTypeName
          }
          this.clickedRadio({field: field, radio: radio});
          this.getDropValueList({field: field,locationValue: radio});
          return true;
        }
      });
    } 
  }
/**
 * Massage blocked treatments
 * @param blockedList 
 */
  massageBlockedTreatments(blockedList: Array<any>): IPatientBlockedTreatments[]{
    let blockedTreatments: IPatientBlockedTreatments[] = [];
    blockedList.forEach((element) => {
      if(element.appointmentTypeId == appTypeEnums.medicationManagement){
        if(element.locationId == null){
          blockedTreatments.forEach(ele => {
            if(ele.appointmentTypeId == appTypeEnums.medicationManagement){
              ele['blockOffline'] = true;
            }
          })
          element['blockOffline'] = true;
        } else if(element.locationId == locationIdEnums.telemedicine){
          blockedTreatments.forEach(ele => {
            if(ele.appointmentTypeId == appTypeEnums.medicationManagement){
              ele['blockOnline'] = true;
            }
          })
          element['blockOnline'] = true;
        }
      }
      if(!blockedTreatments.find(ele => ele.appointmentTypeId == element.appointmentTypeId)) blockedTreatments.push(element);
      // else if (element == 'Psychological Therapy') blockedTreatments.push({ name: element, id: appTypeEnums.pt })
    });
    return blockedTreatments;
  }

  /**
   * @param status : status of the popup
   * This function is to close the double check popup
   */
  doubleCheackPopupclose(status) {
    this.isDoubleCheck = status.cancelpopup;
  }

  // This is for proceed next after double check
  proceedNext() {
    let rescheduleData = {
      reason: this.rescheduledData,
      slot: this.bookedSlotData,
      office: this.officeData,
      officeType: this.locationType
    };
    this.rescheduleData.emit(rescheduleData);
    this.submitEvent.emit({ status: true, step: 1 });
  }

  getStateList() {
    let payload = {
      type: 'GET',
      url: 'states',
      isDeveloper: true,
    };
    this.http.makeHttpRequest(payload).subscribe((res) => {
      if (this.http.isSuccessfulResponse(res) && res) {
        res.data.forEach(element => {
          this.stateList.push(element.value);
        });
      } else {
        let message = TOAST_MESSAGES.STATES_FAIL;
        message = (res.error) ? res.error.message : message;
        this.observable.showToast(TOAST_STATUSES.ERROR, message);
      }
    });
  }
}
