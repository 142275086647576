import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-page-banner',
  templateUrl: './page-banner.component.html',
  styleUrls: ['./page-banner.component.scss']
})
export class PageBannerComponent implements OnInit {

  @Input() bannerData = {
    bannerClass: '',
    orangeHeading: 'We offer',
    blueHeading1: 'a spectrum of',
    blueHeading2: 'world class',
    blueHeading3: 'services',
    bannerDescription: 'We are proud to offer a spectrum of world class mental health services New Tele Doc has a range of worldclass psychiatric services that are highly curated & are focused on development of a patients well being. We take pride in treating each and every patient with atmost care & focus. All our services are approved by medical association & FDA. We also partner with the best possible providers in the industry to bring the latest advancements & medications.',
    bannerImg: 'services-banner'
  };

  constructor() { }

  ngOnInit() {
  }

}
