import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CustomFormHandlerService } from '@app/common/services/custom-form-handler.service';

@Component({
  selector: 'app-check-boxes-group',
  templateUrl: './check-boxes-group.component.html',
  styleUrls: ['./check-boxes-group.component.scss']
})
export class CheckBoxesGroupComponent implements OnInit {
  @Input() groupCheckBox: any;
  @Input() formFieldIndex: any;
  @Output() categorySelectValues: EventEmitter<any> = new EventEmitter();
  @Output() optionSelectValue: EventEmitter<any> = new EventEmitter();
  constructor(private formService: CustomFormHandlerService) { }

  ngOnInit() {
  }

  categorySelect(event, index) {
    this.categorySelectValues.emit({ event, i: index });
  }

  optionSelect(event, taskId, categoryId) {
    this.optionSelectValue.emit({ event, taskId, categoryId });
  }
}
