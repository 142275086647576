import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ROUTE_PATHS } from './common/constants/routing.constant';
import { ActivateGuard } from './common/services/activate.guard';
import { WebsiteactiveGuard } from './common/services/websiteactive.guard';
import { EachTreatmentComponent } from './pages/home/each-treatment/each-treatment.component';
import { InvoicePayComponent } from './pages/pages/invoice-consumer/invoice-pay/invoice-pay.component';
import { RequestDocumentDetailsComponent } from './pages/request-documents/request-document-details/request-document-details.component';
import { TmsRedirectComponent } from './tms-redirect/tms-redirect.component';

const routes: Routes = [
  {
    path: ROUTE_PATHS.ONBOARD,
    loadChildren: () => import('@app/pages/onboard/onboard.module').then(mod => mod.OnboardModule)
  },
  {
    path: ROUTE_PATHS.MIGRATEDUSER,
    loadChildren: () => import('@app/pages/onboard/onboard.module').then(mod => mod.OnboardModule)
  },
  {
    path: ROUTE_PATHS.ACTIVATEDUSER,
    loadChildren: () => import('@app/pages/onboard/onboard.module').then(mod => mod.OnboardModule)
  },
  {
    path: ROUTE_PATHS.APPOINTMENT,
    loadChildren: () => import('@app/pages/AppointmentBooking/appointment.module').then(mod => mod.appointmentModule)
  },
  {
    path: ROUTE_PATHS.SOON,
    loadChildren: () => import('@app/pages/coming-soon/comingSoon.module').then(mod => mod.ComingSoonModule)
  },
  {
    path: ROUTE_PATHS.SERVICES,
    loadChildren: () => import('@app/pages/services/services.module').then(mod => mod.ServicesModule)
  },
  {
    path: ROUTE_PATHS.TREATMENTS,
    loadChildren: () => import('@app/pages/treatments/treatments.module').then(mod => mod.TreatmentsModule)
  },
  {
    path: ROUTE_PATHS.ABOUTUS,
    loadChildren: () => import('@app/pages/aboutus/aboutus.module').then(mod => mod.AboutusModule)
  },
  {
    path: ROUTE_PATHS.BLOG,
    loadChildren: () => import('@app/pages/blog/blog.module').then(mod => mod.BlogModule)
  },
  {
    path: ROUTE_PATHS.FAQS,
    loadChildren: () => import('@app/pages/faqs/faqs.module').then(mod => mod.FaqsModule)
  },
  {
    path: ROUTE_PATHS.PRIVACY,
    loadChildren: () => import('@app/pages/privacy/privacy.module').then(mod => mod.PrivacyModule)
  },
  {
    path: ROUTE_PATHS.TERMS,
    loadChildren: () => import('@app/pages/terms/terms.module').then(mod => mod.TermsModule)
  },
  {
    path: ROUTE_PATHS.NEW_PATIENT,
    loadChildren: () => import('@app/pages/new-patient/newPatient.module').then(mod => mod.newPatientModule)
  },
  {
    path: ROUTE_PATHS.ADD_MyACCOUNT,
    loadChildren: () => import('@app/pages/my-account/my-account.module').then(mod => mod.MyAccountModule)
  },
  {
    path: ROUTE_PATHS.Document_request,
    loadChildren: () => import('@app/pages/request-documents/request-document/request-document.module').then(mod => mod.RequestDocumentModule)
  },
  {
    path: `${ROUTE_PATHS.PATIENTS}/${ROUTE_PATHS.REQUESTED_DOCUMENTS}/:${ROUTE_PATHS.ID}`,
    component: RequestDocumentDetailsComponent,
    canActivate: [ActivateGuard]
  },
  {
    path: ROUTE_PATHS.EMPTY,
    loadChildren: () => import('@app/pages/home/home.module').then(mod => mod.HomeModule)
  },
  {
    path: ROUTE_PATHS.EACH_TREATEMENT,
    loadChildren: () => import('@app/pages/home/home.module').then(mod => mod.HomeModule)
  },
  {
    path: ROUTE_PATHS.NEW_PATIENT,
    loadChildren: () => import('@app/pages/new-patient/newPatient.module').then(mod => mod.newPatientModule)
  },
  {
    path: ROUTE_PATHS.ADD_MyACCOUNT,
    loadChildren: () => import('@app/pages/my-account/my-account.module').then(mod => mod.MyAccountModule)
  },
  {
    path: ROUTE_PATHS.USER_LANDING_PAGE,
    loadChildren: () => import('@app/pages/user-landing-page/user-landing-page.module').then(mod => mod.UserLandingPageModule)
  },
  {
    path: ROUTE_PATHS.MyACCOUNT,
    loadChildren: () => import('@app/pages/create-pwd-template/create-pwd-template.module').then(mod => mod.CreatePwdTemplateModule)
  },

  {
    path: ROUTE_PATHS.APPOINTMENT,
    loadChildren: () => import('@app/pages/resheduling-appointment/resheduling-appointment.module').then(mod => mod.ReshedulingAppointmentModule)
  },
  {
    path: ROUTE_PATHS.PROFILE,
    loadChildren: () => import('@app/pages/user-profile/user-profile.module').then(mod => mod.UserProfileModule)
  },
  {
    path: ROUTE_PATHS.ADMIN,
    loadChildren: () => import('@app/pages/admin/admin.module').then(mod => mod.AdminModule)
  },
  {
    path: `${ROUTE_PATHS.ADMIN}/${ROUTE_PATHS.ONBOARD}`,
    loadChildren: () => import('@app/pages/onboard/onboard.module').then(mod => mod.OnboardModule)
  },
  {
    path: `${ROUTE_PATHS.INVOICES}/:${ROUTE_PATHS.ID}`,
    component: InvoicePayComponent,
  },
  {
    path: 'tms-redirect/:id',
    component: TmsRedirectComponent,
  },
  {
    path: `${ROUTE_PATHS.APPOINTMENT}/pending/${ROUTE_PATHS.TMS_FORMS}/:id`,
    loadChildren: () => import('@app/pages/AppointmentBooking/appointment.module').then(mod => mod.appointmentModule)
  },
  {
    path: '**',
    loadChildren: () => import('@app/pages/home/home.module').then(mod => mod.HomeModule)
  },
  // {
  //   path: `**`,
  //   redirectTo: '',
  //   pathMatch: 'full',
  // }

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
