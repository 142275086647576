export const TOAST_STATUSES = {
    SUCCESS: 'success',
    ERROR: 'error',
    WARNING: 'warning',
};

/**
 * This is for Medicaiton Managment Online (Telemedicine)
 */
export const TELEMEDICINE_MEDICATION = {
  appointmentTypeId: 1,
  appointmentTypeName: 'Medication Management',
  locationId: 'TEL'
};

/**
 * List of HTTP Methods for API calls
 */
export enum HTTPMethods {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  PATCH = 'PATCH',
  DELETE = 'DELETE'
}

/**
 * List of treatment types.
 */
export enum appTypeEnums {
  medicationManagement = 1,
  tms = 4,
  ktm = 3,
  pt = 2,
  therepy = 6,
  sunstance = 7,
}

/**
 * List of email template types.
 */
export enum emailTemplateTypeEnums {
  vitals = '4',
  adhd = '1',
  tmsFollowUp = '2',
  ktmFeedback = '3',
  medicinePres = '5',
  therapistRemainder = '7',
  therapistInfo = '6',
  benefits= '8',
  infoRequest = '12',
  phq = '17'
}

/**
 * List of locations.
 */
export enum locationIdEnums {
  telemedicine = 'TEL',
}

/**
 * List of validation states.
 */
export enum validationState {
  valid = 'VALID',
  invalid = 'INVALID'
}


export const EDT_TIME_ZONE_DIFF = '-04.00';
export const DEFAULT_TIME_ZONE_DIFF = '-05.00';
export const getEstDiff = () => {
  // const stdTimezoneOffset = () => {
  //   let jan = new Date(0, 1);
  //   let jul = new Date(6, 1);
  //   // console.log('jan', jan.getTimezoneOffset());
  //   // console.log('ul', jul.getTimezoneOffset());

  //   return Math.max(jan.getTimezoneOffset(), jul.getTimezoneOffset());
  // };

  // let today = new Date();
  // // console.log('today', today);

  // // tslint:disable-next-line:no-shadowed-variable
  // const isDstObserved = (today: Date) => {
  //   // console.log('tos', today.getTimezoneOffset());
  //   return today.getTimezoneOffset() < stdTimezoneOffset();
  // };

  // if (isDstObserved(today)) {
  //   return EDT_TIME_ZONE_DIFF;
  // } else {
  //   return DEFAULT_TIME_ZONE_DIFF;
  // }

  let date = new Date
  let strTime = date.toLocaleString('en-US', { timeZone: 'America/New_York', timeZoneName: 'short' })
  if (strTime.slice(strTime.length - 3, strTime.length) === 'EDT') {
    return EDT_TIME_ZONE_DIFF
  } else {
    return DEFAULT_TIME_ZONE_DIFF
  }
};
export const DEFAULT_PAGINATION_LIMIT = 10;
export const convertDateToSpecifiedOffset = (param1, currentDate?) => {
    const clientDate = (currentDate) ? new Date(currentDate) : new Date();
    const utc = clientDate.getTime() + (clientDate.getTimezoneOffset() * 60000);
    const serverDate = new Date(utc + (Number(getEstDiff()) * 3600000));
    // console.log(serverDate, "serverdatee")
    return serverDate;
};

export const NEWSTRESS_EMAIL_IDS =  [
  'scheduling02@newstressclinic.com',
 'frontofficedc@newstressclinic.com',
 'VAOffice@newstressclinic.com',
 'nsclaimsgroup@newstressclinic.com',
 'insuranceverification@newstressclinic.com',
 'drprayaga@newstressclinic.com',
 'admin@newstressclinic.com',
 'scheduling@newstressclinic.com'
];
   
export const stateDropDownList = ["MD","NY","VA","DC"];