import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TOAST_STATUSES, HTTPMethods } from '@app/common/constants/util.constant';
import { ObservableHelperService } from '@app/common/services/observable-helper.service';
import { ROUTE_PATHS } from '@app/common/constants/routing.constant';
import { HttpService } from '@app/common/services/http.service';
import { CognitoService } from '@app/common/services/cognito.service';
import { API_URLS } from '@app/common/constants/api-urls.constant';
import { IPutMethodPayload } from '@app/common/constants/typeInterfaces.constants';
import { TOAST_MESSAGES } from '@app/common/constants/toast-messages.constant';

@Component({
  selector: 'app-my-account',
  templateUrl: './my-account.component.html',
  styleUrls: ['./my-account.component.scss']
})
export class MyAccountComponent implements OnInit {
  name: any = '';
  isExixtingUser: any;
  appointmentList: any = [];
  showScreen = false;
  userDetails: any;
  appointmentNumbers: number;
  patientsList: any = [];
  patientTotalLocations: any;
  patientsName: any = '';
  isApiCame: boolean = false;
  tmsPatId: any;
  tmsRedirect: boolean = false;
  isUnsubscribe: boolean = false;
  templateId: string = '';
  showUnsubscribePopUp: boolean = false;
  email: string = '';
  emailUnsubscribed: string = 'Email already Unsubscribed';
  stateList: any = [];
  constructor(private cognitoService: CognitoService, private http: HttpService, private router: Router, private observableHelperService: ObservableHelperService, private route: ActivatedRoute) {
    this.route.queryParams.subscribe(params => {
      if (Object.keys(params).length > 0 && params.paymentDone) {
        this.observableHelperService.showToast(TOAST_STATUSES.SUCCESS, 'Payment is successfully done.');
        const removedParams = {...params};
        delete removedParams.paymentDone;
        this.router.navigate([], { queryParams: removedParams });
      }
    });
    this.tmsPatId = JSON.parse(window.localStorage.getItem('patId'));
    // tslint:disable-next-line:radix
    this.tmsPatId = parseInt(this.tmsPatId);
    this.tmsRedirect = JSON.parse(window.localStorage.getItem('tms-redirect'));
  }

  ngOnInit() {
    if(window.localStorage.getItem('unsub') == 'true'){
      this.email = window.localStorage.getItem('email') ?  window.localStorage.getItem('email') : '';
      this.templateId =  window.localStorage.getItem('templateId') ?  window.localStorage.getItem('templateId') : '';
      this.isUnsubscribe = true;
    }
    this.observableHelperService.toggleThrobber(false);
    this.cognitoService.getUser().then(user => {
      this.userDetails = user;
      this.name = `${this.userDetails.firstName} ${this.userDetails.lastName}`;
      this.getStateList()
      
      this.getPatientsList(user.userId);
    });
  }


  ngAfterViewChecked(): void {
    // Called after every check of the component's view. Applies to components only.
    // Add 'implements AfterViewChecked' to the class.
    if (document.getElementById('my_appointment')) {
      document.getElementById('my_appointment').style.display = 'none';
    }
  }

  ngAfterViewInit(): void {
    // Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    // Add 'implements AfterViewInit' to the class.
    if (document.getElementById('my_appointment')) {
      document.getElementById('my_appointment').style.display = 'none';
    }
  }
  ngOnDestroy(): void {
    // Called once, before the instance is destroyed.
    // Add 'implements OnDestroy' to the class.
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
    if (document.getElementById('my_appointment')) {
      document.getElementById('my_appointment').style.display = 'block';
    }
  }
  getAppoinmnetsList(userId) {
    let payload = {
      type: 'GET',
      url: 'getAppointment',
      isDeveloper: true,
      pathVariables: [userId]
    };
    this.http.makeHttpRequest(payload).subscribe((res) => {
      if (this.http.isSuccessfulResponse(res) && res) {
        this.appointmentList = res.data;
        this.appointmentNumbers = this.appointmentList.length;
        // console.log(this.appointmentList, 'appointment List');
      } else {
        let message = 'Failed to fetch appointment data. Please try again';
        message = (res.error) ? res.error.message : message;
        this.observableHelperService.showToast(TOAST_STATUSES.ERROR, message);
      }
    });
  }
  getPatientsList(userId) {
    let payload = {
      type: 'GET',
      url: 'getPatient',
      isDeveloper: true,
      pathVariables: [userId]
    };
    this.http.makeHttpRequest(payload).subscribe((res) => {
      if (this.http.isSuccessfulResponse(res) && res) {
        this.patientsList = res.data && res.data.patients ? res.data.patients : this.patientsList;
        this.patientTotalLocations = res.data.totalTreatmentLocationCombinations;
        this.patientsList.forEach(element => {
          element.patientBasicInfo = JSON.parse(element.patientBasicInfo)
          element.patientStateValid = this.stateList.includes(element.patientBasicInfo.streetAddress.state)
          console.log(element.patientBasicInfo,"json data")
        });
        if (this.patientsList.length > 0) {
          if (this.tmsRedirect) {
            let patIdFound = false;
            this.patientsList.every(pat => {
              if (pat.patientId === this.tmsPatId) {
                this.tmsRedirect = false;
                patIdFound = true;
                if (pat.pendingTMS) {
                  this.router.navigate([`${ROUTE_PATHS.APPOINTMENT}/pending/${ROUTE_PATHS.TMS_FORMS}/${this.tmsPatId}`]);
                } else {
                  this.observableHelperService.showToast(TOAST_STATUSES.SUCCESS, 'TMS Details have been already submitted for this patient');
                  window.localStorage.removeItem('patId');
                  window.localStorage.removeItem('tms-redirect');
                  this.getPatientsName(this.patientsList);
                }
                return false;
              }
              return true;
            });
            if (!patIdFound) {
              this.observableHelperService.showToast(TOAST_STATUSES.WARNING, 'This account is not linked with the current Patient Details. Please login with other account');
              window.localStorage.removeItem('patId');
              window.localStorage.removeItem('tms-redirect');
              this.getPatientsName(this.patientsList);
            }
          } else {
            this.getPatientsName(this.patientsList);
          }
        }
        console.log(this.patientsList, 'Patients List');
        if (this.isUnsubscribe) {
          this.showUnsubscribePopUp = true;
        }
        this.patientsList = this.patientsList.filter(each => each.removedFlag === 0);
      } else {
        let message = 'Failed to fetch patient data. Please try again';
        message = (res.error) ? res.error.message : message;
        this.observableHelperService.showToast(TOAST_STATUSES.ERROR, message);
      }
      this.showScreen = true;
    });

  }

  getPatientsName(list) {
    if (list && Array.isArray(list)) {
      list.forEach((each, index) => {
        let suffix;
        if (index < list.length - 1) {
          suffix = ' ' + 'or';
        } else {
          suffix = '';
        }
        this.patientsName = this.patientsName + ' ' + each.firstName + suffix;
      });
    }
  }
  redirectingToAddPatientForm(link) {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
    this.router.navigate([link]);
  }
  navigateFaq(event) {

    if (event) {
      this.router.navigate([`${ROUTE_PATHS.ADD_MyACCOUNT}/${ROUTE_PATHS.FAQS}`]);
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });
    }
  }

  callPatientApi(e) {
    if (e.status && e.patId) { this.getPatientsList(e.patId); }
  }

  unsubscribe(){
    let body = {
      templateId : this.templateId,
      email: this.email
    }
    let payload: IPutMethodPayload = {
      type: HTTPMethods.PUT,
      url: API_URLS.UNSUBSCRIBE,
      isDeveloper: true,
      pathVariables: [],
      body: body
    };
    this.http.makeHttpRequest(payload).subscribe((res) => {
      if (this.http.isSuccessfulResponse(res) && res) {
        if(res.data === this.emailUnsubscribed){
          this.observableHelperService.showToast(TOAST_STATUSES.SUCCESS, TOAST_MESSAGES.ALREADY_SUBSCRIBED);
        } else {
          this.observableHelperService.showToast(TOAST_STATUSES.SUCCESS, TOAST_MESSAGES.UNSUB_SUCCESS);
        }
      } else {
        this.observableHelperService.showToast(TOAST_STATUSES.ERROR, TOAST_MESSAGES.UNSUB_FAIL);
      }
      this.router.navigate([]);
      this.isUnsubscribe = false;
    });
  }

  /**
   * To hide/show the popup
   * @param status popup visibility
   */
  cancelPopup(status) {
    this.showUnsubscribePopUp = status.cancelpopup;
    this.router.navigate([]);
    window.localStorage.removeItem('unsub');
    window.localStorage.removeItem('email');
    window.localStorage.removeItem('templateId');
  }

  hidePopup(status){
    this.showUnsubscribePopUp = false;
    if(status){
      this.unsubscribe();
    } else {
      this.router.navigate([]);
    }
    window.localStorage.removeItem('unsub');
    window.localStorage.removeItem('email');
    window.localStorage.removeItem('templateId');
  }
  
  getStateList() {
    let payload = {
      type: 'GET',
      url: 'states',
      isDeveloper: true,
    };
    this.http.makeHttpRequest(payload).subscribe((res) => {
      if (this.http.isSuccessfulResponse(res) && res) {
        res.data.forEach(element => {
          this.stateList.push(element.value);
        });
      } else {
        let message = TOAST_MESSAGES.STATES_FAIL;
        message = (res.error) ? res.error.message : message;
        this.observableHelperService.showToast(TOAST_STATUSES.ERROR, message);
      }
    });
  }
}
