import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { ROUTE_PATHS } from '@app/common/constants/routing.constant';
import { DataService } from '@app/common/services/data.service';
import { ObservableHelperService } from '@app/common/services/observable-helper.service';
import { TOAST_STATUSES, convertDateToSpecifiedOffset, DEFAULT_TIME_ZONE_DIFF } from '@app/common/constants/util.constant';
import { HttpService } from '@app/common/services/http.service';

@Component({
  selector: 'app-appointment-detail-card',
  templateUrl: './appointment-detail-card.component.html',
  styleUrls: ['./appointment-detail-card.component.scss']
})
export class AppointmentDetailCardComponent implements OnInit {
  @Input() appointmentDetails;
  @Input() patients;
  cancelPopupShowHide: boolean = false;
  userIndex: any;
  showPopup: boolean = false;
  popUpAction: string = "";
  updateProfileData: boolean = false;

  @Output() cancelAppointment: EventEmitter<any> = new EventEmitter();
  isReshedule: boolean;
  appointmentDetailObject = {
    appointmentId: null,
    appointmentTypeId: null,
    locationType: null,
    locationId: null,
    slotId: null,
    timeRange: null,
    selectedSlot: null,
    timeIndex: null
  };
  timeRange = {
    start: '06:30:00',
    end: '11:55:00'
  };
  selectedSlot: any = {
    time: null,
    date: null,
    status: 'selected'
  };
  slotId: any;
  begin: any;
  end: any;

  constructor(private observableHelperService: ObservableHelperService, private router: Router, private dataService: DataService, private http: HttpService) { }

  ngOnInit() {
    let lastReschedDate: any = new Date(this.appointmentDetails.lastReschedDate);
    let offset = new Date().getTimezoneOffset();
    lastReschedDate = new Date(lastReschedDate.getTime() + (offset * 60 * 1000)).setHours(0, 0, 0);
    let currentDate = new Date().setHours(0, 0, 0, 0);
    // To check if appt rescheduling is possible - before 1hr of appt.
    if ((this.appointmentDetails.reschedCount < 2 || (lastReschedDate < currentDate)) && this.getApptReSchedule()) {
      this.isReshedule = false;
    } else {
      this.isReshedule = true;
    }
    this.appointmentDetails.lastReschedDate = new Date(this.appointmentDetails.lastReschedDate);
    let appointment = this.dataService.getData(this.dataService.dataObject.APPOINTMENT_DETAIL);
    let data = this.dataService.getData(this.dataService.dataObject.RESCHEDULE_DATA);
    // console.log(data, appointment);
  }

  toggleScroll(status) {
    document.body.style.overflow = (status) ? 'hidden' : 'scroll';
  }

  /**
* Api call to get shedularData
*/
  getAvailabiltySlots(data) {
    let params = {
      appointmentTypeId: data.appointmentTypeId,
      locationId: data.locationId,
      beginTime: '06:30:00',
      endTime: '23:30:00',
      days: 1,
      metadata: true
    };
    // console.log(params);
    let payload = {
      type: 'GET',
      url: 'availability',
      isDeveloper: true,
      params,
      isAuthToken: true
      // body: data
    };
    this.http.makeHttpRequest(payload).subscribe((res) => {
      if (this.http.isSuccessfulResponse(res) && res) {
        console.log(res);
        this.selectedSlot.time = data.appointmentTime;
        this.selectedSlot.date = `${data.appointmentDate}T${data.appointmentTime}`;
        this.appointmentDetailObject.appointmentTypeId = data.appointmentTypeId;
        this.appointmentDetailObject.locationType = data.locationId;
        this.appointmentDetailObject.appointmentId = data.appointmentTypeId - 1;
        this.appointmentDetailObject.locationId = this.getLocationId(res.data.metadata, data.locationId);
        this.appointmentDetailObject.slotId = this.getSlotId(data.appointmentTime);
        this.appointmentDetailObject.selectedSlot = this.selectedSlot;
        this.appointmentDetailObject.timeRange = this.getTimeRange();
        this.appointmentDetailObject.timeIndex = this.getTimeIndex(res.data, data);
        this.dataService.setData(this.dataService.dataObject.PRE_APPOINTMENT, this.appointmentDetailObject);
      } else {
        let message = 'Failed to fetch availability data. Please try again';
        message = (res.error) ? res.error.message : message;
        this.observableHelperService.showToast(TOAST_STATUSES.ERROR, message);
      }
    });
  }
  bookingConfirm(appointmentDetails) {
    let toModify: boolean = false;
    let toUpdate: boolean = false;
    this.patients.every(patient => {
      if (patient.patientId === appointmentDetails.patient.patientId) {

        // check if both 2 year and 8 months intake form updates are required.
        if(patient.longTimeUpdate && patient.isInactive){
          this.updateProfileData = true;
          toUpdate = true;
          return false;
        } else if(patient.longTimeUpdate){ // 2 years update
          toModify = true;
          return false;
        } else if(patient.isInactive){ // 8 months update 
          toUpdate = true;
          return false;
        }
      } 
      return true;
    });
    if (toModify) {
      toModify = false;
      this.popUpAction = "modify";
      this.showPopup = true;
    } else if (toUpdate) {
      toUpdate = false;
      this.popUpAction = "update";
      this.showPopup = true;
    } else {
    // this.observableHelperService.showToast(TOAST_STATUSES.SUCCESS, 'This feature is coming out soon..!!');
    this.router.navigate([`${ROUTE_PATHS.APPOINTMENT}`], { queryParams: { appointmentId: appointmentDetails.appointmentId, appointmentTypeId: appointmentDetails.appointmentTypeId, locationId: appointmentDetails.locationId, locationType: appointmentDetails.location.locationType, appointmentDate: appointmentDetails.appointmentDate, appointmentTime: appointmentDetails.appointmentTime, patientId: appointmentDetails.patient.patientId } });
    }

    // this.getAvailabiltySlots(appointmentDetails);
  }

  getTimeIndex(meta, data) {
    let timeIndex;
    let actcualCalendar = meta.availability.calendar[0].slots;
    actcualCalendar = actcualCalendar.filter((each) => each.time >= this.timeRange.start && each.time <= this.timeRange.end);
    actcualCalendar.forEach((slot, index) => {
      if (slot.time === data.appointmentTime) {
        timeIndex = index;
      }
    });
    timeIndex = Math.floor(timeIndex / 9);
    return timeIndex;
  }
  getLocationId(data, locId) {
    if (data && typeof (data) === 'object') {
      let index;
      data.locations.forEach((element, ind) => {
        if (element.locationId === locId) {
          index = ind;
        }
      });
      return index;
    }
  }
  getSlotId(time) {
    if ('06:30:00' <= time && time <= '11:55:00') {
      this.slotId = 0;
      this.begin = '06:30:00';
      this.end = '11:55:00';
      return this.slotId;
    } else if ('12:00:00' <= time && time <= '15:55:00') {
      this.slotId = 1;
      this.begin = '12:00:00';
      this.end = '15:55:00';
      return this.slotId;
    } else if ('16:00:00' <= time && time <= '17:55:00') {
      this.slotId = 2;
      this.begin = '16:00:00';
      this.end = '17:55:00';
      return this.slotId;
    } else if ('18:00:00' <= time && time <= '19:55:00') {
      this.slotId = 3;
      this.begin = '18:00:00';
      this.end = '19:55:00';
      return this.slotId;
    } else if ('20:00:00' <= time && time <= '23:30:00') {
      this.slotId = 4;
      this.begin = '20:00:00';
      this.end = '23:30:00';
      return this.slotId;
    }
  }
  getTimeRange() {
    this.timeRange.start = this.begin;
    this.timeRange.end = this.end;
    return this.timeRange;
  }
  resheduleData(appointment) {
    let lastReschedDate: any = new Date(this.appointmentDetails.lastReschedDate);
    let offset = new Date().getTimezoneOffset();
    lastReschedDate = new Date(lastReschedDate.getTime() + (offset * 60 * 1000)).setHours(0, 0, 0);
    let currentDate = new Date().setHours(0, 0, 0, 0);
    if (appointment.reschedCount < 2 || (lastReschedDate < currentDate)) {
      console.log('appointment', appointment);
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });
      this.router.navigate([`${ROUTE_PATHS.APPOINTMENT}/${appointment.appointmentId}/${ROUTE_PATHS.RESCHEDULE}`]);
    } else {
      this.observableHelperService.showToast(TOAST_STATUSES.WARNING, 'You have reached the max limit for rescheduling. Please cancel this appointment and book a new one or get in touch with our team.');
    }
  }
  cancelPopupShowHideStatusFun(status) {
    this.cancelPopupShowHide = status.cancelpopup;
    this.toggleScroll(this.cancelPopupShowHide);
    this.cancelAppointment.emit({ status, appointmentId: this.userIndex });
  }
  cancelPopupShowHideFun(appointment) {
    this.cancelPopupShowHide = !this.cancelPopupShowHide;
    this.toggleScroll(this.cancelPopupShowHide);
    this.userIndex = appointment.appointmentId;
  }

  // popup yes/no buttons
  popUpClick(id: any, status: boolean) {
    this.showPopup = false;
    if (status){
      if(this.updateProfileData){ 
        // Both 2 years and 8 months update are required.
        this.router.navigate([`/${ROUTE_PATHS.NEW_PATIENT}`], { queryParams: { id, updateProfile: true } });
      } else if(this.popUpAction == 'modify'){
        // 2 years
        this.router.navigate([`/${ROUTE_PATHS.NEW_PATIENT}`], { queryParams: { id, modify: true } });
      } else if(this.popUpAction == 'update'){
        // 8months
        this.router.navigate([`/${ROUTE_PATHS.NEW_PATIENT}`], { queryParams: { id, modifyProfile: true} });
      }
    }
  }

  // This is closing for make as paid popup
  showPopupFun(status) {
    this.showPopup = status.cancelpopup;
  }

  /**
   * To check if rescheduling is possible or not.
   */
  getApptReSchedule(): boolean {
    let apptTime: Array<number> = (this.appointmentDetails.appointmentTime).split(":");
    let apptDate: Array<number> = (this.appointmentDetails.appointmentDate).split("-");
    let apptScheduleTime: Date = new Date(apptDate[0],apptDate[1]-1,apptDate[2],apptTime[0],apptTime[1],apptTime[2]);

    let currentDate: Date = new Date(convertDateToSpecifiedOffset(DEFAULT_TIME_ZONE_DIFF, this.appointmentDetails.timestamp));
    currentDate = new Date(currentDate.setHours(currentDate.getHours() + 1));
    return apptScheduleTime.valueOf() > currentDate.valueOf()
  }
}
