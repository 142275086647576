import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TOAST_MESSAGES } from '@app/common/constants/toast-messages.constant';
import { HttpService} from '@app/common/services/http.service';
import { TOAST_STATUSES, DEFAULT_PAGINATION_LIMIT, HTTPMethods } from '@app/common/constants/util.constant';
import { ObservableHelperService } from '@app/common/services/observable-helper.service';
import { API_URLS } from '@app/common/constants/api-urls.constant';
import { ROUTE_PATHS } from '@app/common/constants/routing.constant';

@Component({
  selector: 'app-request-document-details',
  templateUrl: './request-document-details.component.html',
  styleUrls: ['./request-document-details.component.scss']
})
export class RequestDocumentDetailsComponent implements OnInit {
  requestId: any;
  documentDetails: any;
  breadCrumbData = {
    link: '',
    linkText: 'Document Request Details'
  };
  showOther: boolean = false;
  displayMarkAsComplete: boolean = false;
  popupData: any;
  showPopup: boolean = false;
  otherValue: any = '';
  otherValued: any = '';
  remainingValue: number = 350;
  nonEmptyRegex = /^(?!\s*$).+/;
  showOtherError: boolean = false;
  constructor(private router: Router, private route: ActivatedRoute, private http: HttpService, private observable: ObservableHelperService, private httpClient: HttpClient) {
    this.route.params.subscribe(params => {
      this.requestId = params.id;
      this.getRequestedDocumentDetails();
    });
   }

  ngOnInit() {
  }
  ngAfterViewChecked(): void {
    // Called after every check of the component's view. Applies to components only.
    // Add 'implements AfterViewChecked' to the class.
    if (document.getElementById('document_request')) {
      document.getElementById('document_request').style.display = 'none';
    }
  }

  ngAfterViewInit(): void {
    // Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    // Add 'implements AfterViewInit' to the class.
    if (document.getElementById('document_request')) {
      document.getElementById('document_request').style.display = 'none';
    }
  }
  ngOnDestroy() {
    if (document.getElementById('document_request')) {
      document.getElementById('document_request').style.display = 'flex';
    }
  }
  getRequestedDocumentDetails() {
    const payload = {
      type: HTTPMethods.GET,
      url: API_URLS.PATIENT_REQUEST_DOCUMENTS,
      isDeveloper: true,
      pathVariables: [this.requestId],
    };
    this.http.makeHttpRequest(payload).subscribe((res) => {
      if (this.http.isSuccessfulResponse(res)) {
        this.documentDetails = res.data;
        this.documentDetails.documentURLs = this.massageDocumentUrls(this.documentDetails.documentURLs);
        this.documentDetails.adminDocumentURLs = this.massageDocumentUrls(this.documentDetails.adminDocument);
        this.documentDetails.signedDocuments = this.massageDocumentUrls(this.documentDetails.adminDocURLs);
        this.documentDetails.finalDocuments = this.massageDocumentUrls(this.documentDetails.adminDocument1);
        this.documentDetails.status = this.getStatusLabel(this.documentDetails.status);
        this.documentDetails.statusLabel = 'Completed Date';
        if (this.documentDetails.status === 'Cancelled') {
          this.documentDetails.statusLabel = 'Cancelled Date';
        }
      } else {
        this.observable.showToast(TOAST_STATUSES.ERROR, TOAST_MESSAGES.DOCUMENT_DATA_REQUEST_ERROR_MSG);
      }
    });
  }
    /**
   *
   * @param docUrls list of document urls
   * @returns list with file name and url
   * This function is for massaging the document urls
   */
     massageDocumentUrls(docUrls) {
      if (!docUrls && !Array.isArray(docUrls)) { return []; }
      return docUrls.map(eachUrl => {
        return {
          url: eachUrl,
          fileName: eachUrl.includes('__name__') ? 
          eachUrl.substring(eachUrl.lastIndexOf('__name__') + 8, eachUrl.indexOf('?')) :
          eachUrl.substring(eachUrl.lastIndexOf('/') + 1, eachUrl.lastIndexOf('?'))
        };
      }
      );
    }
  
    /**
     * This function is for elobrating status to display in UI
     * @param status //P,D,C
     * @returns //Pending,Cancelled,Complete
     */
    getStatusLabel(status) {
      if (status && status === 'P') {
        return 'Pending';
      } else if (status && (status === 'C')) {
        return 'Completed';
      } else if (status && status === 'D') {
        return 'Cancelled';
      }else if (status && (status === 'PFR')) {
        return 'Pending For Review';
      } else if (status && status === 'RP') {
        return 'Review Pending';
      }else if (status && (status === 'PP')) {
        return 'Payment Pending';
      } else if (status && status === 'UP') {
        return 'Under Process';
      }else if (status && (status === 'DUP')) {
        return 'Document Under Process';
      } else if (status && status === 'PPR') {
        return 'Pending Patient Review';
      }
      else if (status && (status === 'DUP')) {
        return 'Document Under Process';
      }  else if (status && (status === 'DUP')) {
        return 'Patient Approved Waiting For Dr Approved';
      }
      else if (status && (status === 'DUP')) {
        return 'Invoice Pending';
      }
      else if (status && (status === 'DUP')) {
        return 'Emplouyee Not Assigned';
      }
      else if (status && (status === 'RJ')) {
        return 'Rejected';
      }
      else if (status && (status === 'PC')) {
        return 'Payment Completed';
      }
      else if (status && (status === 'DURPF')) {
        return 'Document Under Review Pending For Approval';
      }
      else if (status && (status === 'PCP')) {
        return 'Response Pending For Admin Comments';
      }
    }
    sendChanges(){
      this.showPopup = true;
    }
    conformComments(){
      if (!this.nonEmptyRegex.test(this.otherValued)) {
        this.showOtherError = true;
      }
      else{
      let bodyData = {
        requestId:this.documentDetails.requestId,
        empId: this.documentDetails.empId,
        adminComments:this.documentDetails.adminComments,
        patientComments: this.otherValued
      };
      let payloadData = {
        type: HTTPMethods.POST,
        url: API_URLS.PATIENT_CONFORM_COMMENTS,
        isDeveloper: true,
        body: bodyData,
      };
  
      this.http.makeHttpRequest(payloadData).subscribe((res) => {
        if (this.http.isSuccessfulResponse(res)) {
          this.observable.showToast(TOAST_STATUSES.SUCCESS, TOAST_MESSAGES.SEND_CHANGED_SUCCESS);
          this.router.navigate([`${ROUTE_PATHS.Document_request}`]);
        }
        else {
          this.observable.showToast(TOAST_STATUSES.ERROR, TOAST_MESSAGES.SEND_CHNAGES_DATA);
        }
      });
    }
    }
    sendChangesData(){
      if (!this.nonEmptyRegex.test(this.otherValue)) {
        this.showOtherError = true;
      }
      else{
      let bodyData = {
        requestId:this.documentDetails.requestId,
        empId: this.documentDetails.empId,
        adminComments:this.documentDetails.adminComments,
        documentURLs:null,
        patientComments: this.otherValue
      };
      let payloadData = {
        type: HTTPMethods.POST,
        url: API_URLS.PATIENT_SEND_CHANGES,
        isDeveloper: true,
        body: bodyData,
      };
  
      this.http.makeHttpRequest(payloadData).subscribe((res) => {
        if (this.http.isSuccessfulResponse(res)) {
          this.observable.showToast(TOAST_STATUSES.SUCCESS, TOAST_MESSAGES.SEND_CHANGED_SUCCESS);
          this.router.navigate([`${ROUTE_PATHS.Document_request}`]);
        }
        else {
          this.observable.showToast(TOAST_STATUSES.ERROR, TOAST_MESSAGES.SEND_CHNAGES_DATA);
        }
      });
    }
    }
    conformRequest(){
      let bodyData = {
        requestId:this.documentDetails.requestId,
        empId: this.documentDetails.empId,
        adminComments:this.documentDetails.adminComments,
        documentURLs:null,
        patientComments: this.documentDetails.patientComments
      };
      let payloadData = {
        type: HTTPMethods.POST,
        url: API_URLS.PATIENT_CONFORM,
        isDeveloper: true,
        body: bodyData,
      };
  
      this.http.makeHttpRequest(payloadData).subscribe((res) => {
        if (this.http.isSuccessfulResponse(res)) {
          this.observable.showToast(TOAST_STATUSES.SUCCESS, TOAST_MESSAGES.CONFORM_DOCUMNT_COMPLETED);
          this.router.navigate([`${ROUTE_PATHS.Document_request}`]);
        }
        else {
          this.observable.showToast(TOAST_STATUSES.ERROR, TOAST_MESSAGES.CONFORM_DOCUMENT);
        }
      });
    }
    popUpClick(){
      this.showPopup = false;
    }
    showPopupFun(){
      this.showPopup = false;
    }
    // This method for closing the popup
    documentsRequestPopupStatusFun(status) {
      this.displayMarkAsComplete = status.cancelpopup;
      if (status.apiStatus) {
        this.getRequestedDocumentDetails();
      }
    }
  
    // This function is for mark as complete popup data
    goToMarkAsComplete() {
      this.displayMarkAsComplete = true;
      this.popupData = {
        label: 'Mark Request As Complete',
        type: 'markAsRequestComplete',
        requestId: this.requestId
      };
    }
  
    // This function is for Cancel request popup data
    cancelRequest() {
      this.displayMarkAsComplete = true;
      this.popupData = {
        label: 'Cancel Request',
        type: 'cancelRequest',
        requestId: this.requestId
      };
    }
  
    // This method is for sending reminder to patient
    sendRemainder() {
      const payload = {
        type: HTTPMethods.GET,
        url: API_URLS.SEND_REMINDER,
        isDeveloper: true,
        pathVariables: [this.requestId],
      };
      this.http.makeHttpRequest(payload).subscribe((res) => {
        if (this.http.isSuccessfulResponse(res)) {
          this.observable.showToast(TOAST_STATUSES.SUCCESS, TOAST_MESSAGES.SUCCESSFULL_REMIDNDER_SENT_MSG);
        } else {
          this.observable.showToast(TOAST_STATUSES.ERROR, TOAST_MESSAGES.SEND_REMINDER_ERROR_MSG);
        }
      });
  
    }
    /**
     *
     * @param urls :list of document urls
     * This function is for sending document urls to the download funciton
     */
    downloadAll(urls) {
      if (urls && urls.length) {
        urls.forEach(doc => {
          this.downloadImage(doc.url);
        });
      }
    }
  
    // This function is for downloading the documents
    downloadImage(img) {
      const imgUrl = img;
      const imgName = imgUrl.includes('__name__') ?  
      imgUrl.substring(imgUrl.lastIndexOf('__name__') + 8, imgUrl.indexOf('?')) :
      imgUrl.substring(imgUrl.lastIndexOf('/') + 1, imgUrl.lastIndexOf('?'))
      this.httpClient.get(imgUrl, { responseType: 'blob' as 'json' })
        .subscribe((res: any) => {
          const file = new Blob([res], { type: res.type });
  
          // IE
          // if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          //   window.navigator.msSaveOrOpenBlob(file);
          //   return;
          // }
  
          const blob = window.URL.createObjectURL(file);
          const link = document.createElement('a');
          link.href = blob;
          link.download = imgName;
          // Version link.click() to work at firefox
          link.dispatchEvent(new MouseEvent('click', {
            bubbles: true,
            cancelable: true,
            view: window
          }));
  
          setTimeout(() => { // firefox
            window.URL.revokeObjectURL(blob);
            link.remove();
          }, 100);
        });
    }
    textCounter(event) {
      this.remainingValue = 350 - (event.target.value.length);
      this.showOtherError = false;
    }
}
