import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DataService {
  data: any = {};
  dataObject = {
    USER_DATA: 'userData',
    CURRENT_SUBTASK__USER: 'currentSubtaskUser',
    IMPORTED_TASK: 'importedTask',
    APPOINTMENT_DETAIL: 'appointmentDetail',
    HIPPA: 'hippa',
    HEALTH_DETAILS: 'healthDetails',
    MEDICATION_CONSENT: 'medicationConsent',
    TELEMEDICINE_CONSENT: 'telemedicineConsent',
    FEEDBACK: 'feedback',
    TMSFEEDBACK: 'tmsfeedback',
    KETAMINEFEEDBACK: 'ketaminefeedback',
    NEW_PATIENT_FORM: 'newPatien',
    RESCHEDULE_DATA: 'rescheduleData',
    ADD_PATIENT_CLICKED: 'addPatient',
    TMS_QUESTIONNAIRE: 'tmsQuestionnaire',
    HAM_QUESTIONNAIRE: 'hamQuestionnaire',
    GAD_QUESTIONNAIRE: 'gadQuestionnaire',
    PH_QUESTIONNAIRE: 'phqQuestionnaire',
    PRE_APPOINTMENT: 'preAppointment',
    KETAMINEENROLLMENT: 'ketamineEnrollment',
    KETAMINEMONITORING: 'ketamineMonitoring',
    KETAMINECONSENT: 'ketamineConsent',
    TMSCONSENT: 'tmsConsent',
  };
  previousValue:any;
  metalHealthData: any;
  previousAppointmentData : any;
  locationDetails: any;
  patientDetails: any;
  previousData: any = {};
  cardData = [
    {
      id: '0',
      date: '18th Feb,2020',
      time: '9:30 AM',
      user: 'John Doe',
      office: 'Alexendria Office',
      status: 'active',
      resheduleCount: 2
    },
    {
      id: '1',
      date: '18th Feb,2020',
      time: '9:30 AM',
      user: 'Virat Kholi',
      office: 'Alexendria Office',
      status: 'active',
      resheduleCount: 0
    },
    {
      id: '2',
      date: '18th Feb,2020',
      time: '9:30 AM',
      user: 'Dhawan',
      office: 'Alexendria Office',
      status: 'past',
      resheduleCount: 0
    },
    {
      id: '3',
      date: '18th Feb,2020',
      time: '9:30 AM',
      user: 'David Warner',
      office: 'Alexendria Office',
      status: 'past',
      resheduleCount: 0
    }
  ];
  numberOfPopupOpened: number = 0;

  constructor() { }

  /**
   * getting the whole array of users
   * @param key
   */
  getData(key) {
    return this.data[key];
  }

  getPreviousData(key) {
    return this.previousData[key];
  }
  /**
   *
   * @param key
   * @param data
   */
  setData(key, data) {
    this.data[key] = data;
    this.previousData[key] = data;
  }


  /**
   *
   * @param key
   * @param data
   */
  clearData() {
    return this.data = {};
  }
  clearPreviousData() {
    return this.previousData = {};
  }
}
