import { Component, OnInit, Input } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-faqs-content',
  templateUrl: './faqs-content.component.html',
  styleUrls: ['./faqs-content.component.scss']
})
export class FaqsContentComponent implements OnInit {

  @Input() iconPath = {
    iconMinus: 'minus-circle.svg',
    iconPlus: 'plus-circle.svg',
  };
  @Input() cardDetails = [
    {
      expanded: true,
      title: `How to request for early appointment?`,
      description: `Kindly send a copy of the back and front travel itinerary at <a style="color: #FF4E00 !important;" href='mailto:scheduling@newstressclinic.com'>scheduling@newstressclinic.com</a> so as to request an early appointment.`
    },
    {
      expanded: false,
      title: `Did not receive the link for payment?`,
      description: `The invoice for the appointment will be sent at least 30 min before the appointment time. Kindly check the appointment scheduled in case of any in person appointment the invoice will not be sent to the email. If the appointment is rescheduled from in person to Telemedicine kindly confirm your appointment from the upcoming appointment section otherwise the invoice for your appointment will not be sent.`
    },
    {
      expanded: false,
      title: `Did not receive the link for appointment?`,
      description: `The link will be sent to your email once the payment for the appointment is completed.  Kindly check the appointment scheduled you scheduled in case of any in person appointment the invoice and the link will not be sent to the email. If the appointment is rescheduled from in person to Telemedicine kindly confirm your appointment from the upcoming appointment section otherwise the invoice and the link for the appointment will not be sent.`
    },
    {
      expanded: false,
      title: `How to update pharmacy information?`,
      img: 'assets/images/pngs/patient-list.png',
      img2: 'assets/images/pngs/pharmacy.png',
      description: `You can update the insurance information by following these steps. First, Login to portal then dashboard page will be open. In this page my patients section will be there on each patient there will be more icon if you click on that link "Update Pharmacy Details" option will be seen like below mentioned.`,
      description2: `By selecting "Update Pharmacy Details" it will take you to patient update page, where you can change the pharmacy details like below mentioned.`
    },
    {
      expanded: false,
      title: `How to update insurance information?`,
      img: 'assets/images/pngs/patient-list.png',
      img2: 'assets/images/pngs/insurance.png',
      description: `You can update the insurance information by following these steps. First, Login to portal then dashboard page will be open. In this page my patients section will be there on each patient there will be more icon if you click on that link "Update Insurance Details" option will be seen like below mentioned screen`,
      description2: `By selecting "Update Insurance Details" it will take you to patient update page, where you can change the insurance details like below mentioned.`

    },
    {
      expanded: false,
      title: `How can I change my email address to receive the appointment details to a different email address?`,
      img: 'assets/images/pngs/dashboard.PNG',
      img2: 'assets/images/pngs/account-info.png',
      img3: 'assets/images/pngs/old-pwd.png',
      img4: 'assets/images/pngs/dob.png',
      img5: 'assets/images/pngs/reset-link.png',
      img6: 'assets/images/pngs/new-pwd.png',
      description: `You can update the mailing address by following these steps. First, Login to portal then dashboard page will be open like below mentioned.`,
      description2: `In this page "My Profile" option will be there on header section, By clicking on "My Profile" it will take you to "My Profile" Page. Where you can update the mail address and password.`,
      description3: `After changing email address you will get one confirmation email. In the email you will find confirmation link. By clicking on the confirmation link you will navigate to below mentioned screen where you can enter old password. Now if you remember the old password, enter the password and your account will activated.`,
      description4: ` If you don't remember the old password click on forgot password
      After clicking on "Forgot Password" you need to enter date of birth of primary patient in below mentioned screen.`,
      description5: `After entering the date of birth of primary patient you will receive one verification link on your email id.`,
      description6: `Click on the link in the received email to create a new password. After creating new password, login with the new username & password.`
    },
    {
      expanded: false,
      title: `Can my medications be sent early`,
      description: `Kindly send a copy of back and front travel itinerary at <a style="color: #FF4E00 !important;" href='mailto:scheduling@newstressclinic.com'>scheduling@newstressclinic.com</a> so as to send medications early.`
    },
    {
      expanded: false,
      title: `Need prescription refill`,
      description: `Kindly schedule an appointment for any prescription refill.`
    },
    {
      expanded: false,
      title: `I lost my medications`,
      description: `If controlled substances are involved, we need police report and then email the report to us. and then you can schedule another appointment.`
    },
    {
      expanded: false,
      title: 'Do I need to complete a consent form each time?',
      description: 'Yes, a consent form is required for each appointment.'
    },
    {
      expanded: false,
      title: 'Can medication changes be done via telemedicine?',
      description: 'No, Major medication changes must be done during an office appointment.'
    },
    {
      expanded: false,
      title: 'When will my medications be sent to the Pharmacy?',
      description: 'Prescriptions are sent to the pharmacy upon the successful completion of an appointment, payment of all fees,  and the submission of all requested forms. Prescriptions are sent to the pharmacy by the close of business once all of the fore-mentioned criteria are met.'
    },
    {
      expanded: false,
      title: 'Can I change my pharmacy?',
      description: 'Yes. Pharmacy changes must be completed submitted 24 hours prior to your appointment. If a request is submitted outside of 24 hours, we will try our best to update if time permits and if we are unable to do so, the information will be updated for future appointments.'
    },
    {
      expanded: false,
      title: 'How can I request my medical records to be released to another provider?',
      description: 'Call or email and request a release consent form to complete, authorizing Dr. Prayaga to release your information to the designated entity.'
    },
    {
      expanded: false,
      title: 'Why do I need a prior authorization for a medication?',
      description: `Insurance companies often require physicians to submit additional information to validate the need for certain medications. If your pharmacist tells you that you need prior authorization, please ask them to fax over a rejection claim to the office at <a style="color: #FF4E00 !important;" href="tel:8665286229">866-528-6229</a>.`
    },
    {
      expanded: false,
      title: 'What happens if I am unable to connect with Dr. Prayaga online?',
      description: `Please call the office at <a style="color: #FF4E00 !important;" href="tel:877-202-8202">877-202-8202</a> and a staff member will assist you in connecting online.`
    },
    {
      expanded: false,
      title: 'I am traveling and need to request an early appointment/medication refill, what do I need to do to get the request approved?',
      description: `Submit a copy of your travel itinerary, a departure, and arrival itinerary, to <a style="color: #FF4E00 !important;" href='mailto:scheduling@newstressclinic.com'>scheduling@newstressclinic.com</a>. Once a copy is submitted, staff will reach out to you regarding scheduling.`
    },
    {
      expanded: false,
      title: 'My written prescription was lost or my pill bottle was lost, can I get a new prescription?',
      description: 'If <b style="font-weight: bold;">controlled</b> substances are involved, we need a police report and then email the report to us. and then you can schedule another appointment. If <b style="font-weight: bold;">non controlled</b> substances are Involved please schedule another appointment',
    },
    {
      expanded: false,
      title: 'Is it possible to send my prescriptions out of State?',
      description: 'Dr. Prayaga sends prescriptions to the States for which he is licensed to practice medicine including  Maryland, Washington, D.C., and Virginia.'
    },
    {
      expanded: false,
      title: 'I am unable to make it to my appointment, can I send someone else to pick up my prescription?',
      description: 'No, Dr. Prayaga provides written prescriptions only to patients.'
    },
    {
      expanded: false,
      title: 'I need an accommodation letter for my emotional support letter, is there a fee?',
      description: 'Yes, there is a processing fee for all Emotional support letters.'
    },
    {
      expanded: false,
      title: 'Will Dr. Prayaga be able to speak with my therapist?',
      description: 'Yes, once you’ve completed the required release consent form giving Dr. Prayaga permission to speak with your therapist or any other provider, Dr. Prayaga can reach out on your behalf.'
    },
    {
      expanded: false,
      title: 'Does Dr. Prayaga provide therapy or counseling?',
      description: 'Currently, Dr. Prayaga does not offer therapy or counseling. However, with proper consent, Dr. Prayaga can coordinate care with your current therapist as well a provide referrals to therapists or counselors.'
    },
    {
      expanded: false,
      title: 'Other than medication management and psychiatric evaluations, what other services does  Dr. Prayaga provide?',
      description: 'Dr. Prayaga provides Transcranial Magnetic Stimulation and Ketamine Treatments.'
    },
      {
      expanded: false,
      title: 'What insurances are accepted?',
      description: 'Most insurances Accepted. Please contact the clinic through phone or email for specific insurance inquiries.'
    },
  ];
  constructor(private router: Router) { }

  ngOnInit() {
    const canonicalLink = document.querySelector('link[rel="canonical"]');
    if (canonicalLink) {
      canonicalLink.setAttribute('href', 'https://newteledoc.com/faqs');
      this.router.events.subscribe((event) => {
        if (event instanceof NavigationEnd) {
          canonicalLink.setAttribute('href', 'https://newteledoc.com/faqs');
        }
      });
    }
  }

  onClick(e) {
    e.expanded = !e.expanded;
  }

}
