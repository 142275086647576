import { formatDate } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { Validators } from '@angular/forms';

@Component({
  selector: 'app-filters',
  templateUrl: './filters.component.html',
  styleUrls: ['./filters.component.scss']
})
export class FiltersComponent implements OnInit {
  // Dates validation message
  datesValidationState = false;
  filterFormControls;
  doctorList: any;
  treatmentList: any;
  locationList: any;
  clearCheckBoxes = false;
  filterClear = false;

  @Output() submitEvent: EventEmitter<any> = new EventEmitter();
  @Output() clearEvent: EventEmitter<any> = new EventEmitter();
  @Input() filterType: any;
  @Input() filterInputs: any = [
    {
      type: 'radio',
      label: 'View by:',
      labelImage: '',
      required: true,
      key: 'viewby',
      value: 'All',
      className: 'radio-btn-horizontal',
      editable: true,
      controlType: 'custom-radio',
      emptyValueMessage: 'Please select one option',
      validators: [Validators.required],
      fields: [
        {
          label: 'All',
          value: 'All'
        },
        {
          label: 'Appointment Date',
          value: 'Appointment Date'
        },
        {
          label: 'Booking Date',
          value: 'Booking Date'
        }
      ],
      showontoggle: true,
    },
    {
      type: 'header',
      label: 'Days',
      showontoggle: true,
      editable: true,
      key: 'days'
    },
    {
      type: 'calendar',
      placeholder: 'Start Date',
      isTime: false,
      required: true,
      key: 'prescriptionStartDate',
      labelImage: '',
      value: '',
      controlType: 'calendar',
      editable: true,
      emptyValueMessage: 'Please select date',
      validators: [],
      className: 'side__by__side',
      focus: true,
      showontoggle: true,
    },
    {
      type: 'calendar',
      placeholder: 'End Date',
      isTime: false,
      required: false,
      key: 'prescriptionEndDate',
      labelImage: '',
      value: '',
      controlType: 'calendar',
      editable: true,
      className: 'side__by__side',
      emptyValueMessage: 'Please select end date',
      validationValue: 'End date should be greater than start date',
      validators: [],
      focus: true,
      showontoggle: true,
    },
    {
      type: 'header',
      label: 'Time',
      showontoggle: true,
      editable: true,
      key: 'time'
    },
    {
      type: 'calendar',
      placeholder: 'Start Time',

      required: true,
      key: 'startTime',
      labelImage: '',
      isTime: true,
      value: '',
      controlType: 'timer',
      editable: true,
      // emptyValueMessage: 'Please select Start Time',
      validators: [this.validateStartTime],
      className: 'side__by__side',
      focus: true,
      showontoggle: true,
    },
    {
      type: 'calendar',
      placeholder: 'End Time',
      isTime: true,
      required: false,
      key: 'endTime',
      labelImage: '',
      value: '',
      controlType: 'timer',
      editable: true,
      className: 'side__by__side',
      // emptyValueMessage: 'Please select End Time',
      validationValue: 'End date should be greater than start date',
      validators: [this.validateEndTime],
      focus: true,
      showontoggle: true,
    },
    {
      type: 'header',
      controlType: 'hrLine',
      className: 'hrLine',
      showontoggle: true,
      editable: true,
    },
    {
      type: 'groupCheckbox',
      categoryLabel: 'Doctor',
      controlType: 'groupCheckbox',
      key: 'doctor',
      required: true,
      value: [],
      isIntermediate: false,
      options: [
        {
          name: 'Luis Calvillo',
          isSelected: false
        },
        {
          name: 'Zhan Huo',
          isSelected: false
        }
      ],
      validators: [],
      focus: true,
      editable: true,
      isSelected: false,
      showontoggle: true,
    },
    {
      type: 'header',
      controlType: 'hrLine',
      className: 'hrLine',
      showontoggle: true,
      editable: true,
    },
    {
      type: 'groupCheckbox',
      categoryLabel: 'Treatment',
      controlType: 'groupCheckbox',
      key: 'treatment',
      required: true,
      value: [],
      isIntermediate: false,
      options: [
        {
          name: 'Medmanagement',
          isSelected: false
        },
        {
          name: 'Tele Medicine',
          isSelected: false
        },
        {
          name: 'TMS',
          isSelected: false
        },
        {
          name: 'Therapy',
          isSelected: false
        }
      ],
      validators: [],
      focus: true,
      editable: true,
      isSelected: false,
      showontoggle: true,
    },
    {
      type: 'header',
      controlType: 'hrLine',
      className: 'hrLine',
      showontoggle: true,
      editable: true,
    },
    {
      type: 'groupCheckbox',
      categoryLabel: 'Location',
      controlType: 'groupCheckbox',
      key: 'location',
      required: true,
      value: [],
      isIntermediate: false,
      options: [
        {
          name: 'Washington DC',
          isSelected: false
        },
        {
          name: 'Alexandria',
          isSelected: false
        },
        {
          name: 'Tyson Corner',
          isSelected: false
        }
      ],
      validators: [],
      focus: true,
      editable: true,
      isSelected: false,
      showontoggle: true,
    },
    {
      type: 'submit',
      placeholder: '',
      required: true,
      text: 'Apply',
      key: '',
      value: '',
      controlType: 'submitButton',
      validators: [],
      callback: '',
      cencelBtn: 'Clear',
      showontoggle: true,
    }
  ];
  formGroup: any;
  formInputs: any;
  formSubmitAttempt = false;
  todayDate = new Date();
  monthSelector: Element;
  yearSelector: Element;
  @Input() set filterData(value: any) {
    if (value) {
      this.assignFilterData(value);
      this.initForm(this.filterInputs);
      this.setValidatorsForTime();
    }
  }

  @Input() set isFilterPanelClosed(value: any) {
    this.formSubmitAttempt = !value;
  }
  @Output() clearFilter;
  @Input() set isFilterClear(value: any) {
    if (value) {
      this.clearFormfields();
    }
  }
  constructor(private formBuilder: FormBuilder) { }

  // This will do form submission
  ngOnInit() {
    this.monthSelector = document.getElementsByClassName('ui-datepicker-month')[0];
    this.yearSelector = document.getElementsByClassName('ui-datepicker-year')[0];
    if (this.monthSelector && this.yearSelector) {
      this.yearSelector.setAttribute('onfocus', 'this.size = 4');
      this.yearSelector.setAttribute('onblur', 'this.size = 1');
      this.yearSelector.setAttribute('onchange', 'this.size = 1');
      this.monthSelector.setAttribute('onfocus', 'this.size = 4');
      this.monthSelector.setAttribute('onblur', 'this.size = 1');
      this.monthSelector.setAttribute('onchange', 'this.size = 1');
    }
  }

  ngAfterContentChecked(): void {
    this.yearSelector = document.getElementsByClassName('ui-datepicker-year')[0];
    this.monthSelector = document.getElementsByClassName('ui-datepicker-month')[0];
    let isMobile = window.innerWidth < 1024 ? true : false;
    if (this.monthSelector && this.yearSelector) {
      this.yearSelector.setAttribute('onfocus', 'this.size = 10');
      this.yearSelector.setAttribute('onblur', 'this.size = 1');
      this.yearSelector.setAttribute('onchange', 'this.size = 1');
      this.monthSelector.setAttribute('onfocus', 'this.size = 10');
      this.monthSelector.setAttribute('onblur', 'this.size = 1');
      this.monthSelector.setAttribute('onchange', 'this.size = 1');
    }
  }

  // Assigning filter data to filterComponent
  assignFilterData(data) {
    if (data && typeof (data) === 'object') {
      this.filterInputs = this.filterInputs.map(inp => {
        console.log(this.formInputs, 'formInputs');
        return {
          ...inp,
          fields: (inp.key === 'viewby') ? data[inp.key] : [],
          options: (inp.key === 'doctor' || inp.key === 'invTreatment' || inp.key === 'treatment' || inp.key === 'other' || inp.key === 'location' || inp.key === 'status' || inp.key === 'offlinePatientStatus' || inp.key === 'onlinePatientStatus' || inp.key === 'requestType' || inp.key === 'docStatus') ? data[inp.key] : []
        };
      });
    } else {
      // Error
    }
  }

  initForm(formType) {
    // LoginForm
    this.formGroup = this.toReactiveForm(formType);
    this.formInputs = formType;
  }
  /**
   * this function is used to check error for a given input control
   * @param control {FormControl} - form control to be validated
   * @param formSubmitted {boolean} - to validate the form on form submission
   */
  isInvalid(control, formSubmitted) {
    return ((control.invalid && formSubmitted && (control.touched || control.dirty)) ||
      (formSubmitted && control.invalid)
    );
  }

  /**
  * To convert form data to reactive form
  // tslint:disable-next-line: jsdoc-format
  * @param inputs  - array of input fields
  */
  toReactiveForm(inputs: Array<{}>) {
    let group = {};
    Array.prototype.forEach.call(inputs, (input, i) => {
      if (input.type !== 'header') {
        if (input.controlType === 'formGroup') {
          group[input.key] = this.toReactiveForm(input.formInputs);
        } else if (input.controlType === 'submitButton') {
          // Don't add to form Group
        } else {
          group[input.key] = [(input.value === undefined || input.value === null) ? '' : input.value, input.validators];
        }
      }
    });
    return this.formBuilder.group(group);
  }
  // This will do form clear
  clearFormfields() {
    this.clearEvent.emit(this.clearFilter = true);
    this.formGroup.reset();
    this.formSubmitAttempt = false;
    console.log(this.formGroup);
    this.formInputs.forEach(each => {
      if (each.type !== 'header') {
        if (each.type === 'calendar') {
          each.value = '';
          this.formGroup.controls[each.key].setValue('');
        } else if (each.type === 'radio') {
          this.formGroup.controls[each.key].setValue('All');
          each.value = 'All';
        } else if (each.type === 'groupCheckbox') {
          console.log(each);
          each.isSelected = false;
          // tslint:disable-next-line:no-unused-expression
          each.editable !== undefined ? each.editable = true : null;
          each.isIntermediate = false;
          this.formGroup.controls[each.key].value = [];
          each.value = [];
          each.options.forEach(opt => {
            opt.isSelected = false;
            // tslint:disable-next-line:no-unused-expression
            opt.editable !== undefined ? opt.editable = true : null;
          });
        }
      }
    });

    for (const key in this.formGroup.controls) {
      if (Object.prototype.hasOwnProperty.call(this.formGroup.controls, key)) {
        this.formGroup.controls[key].clearValidators();
        this.formGroup.controls[key].updateValueAndValidity();
      }
    }
  }
  // radio change Event
  radioChnage(field) {
    for (const key in this.formGroup.controls) {
      if (Object.prototype.hasOwnProperty.call(this.formGroup.controls, key)) {
        if (this.formGroup.controls[field.key].value !== 'All') {
          if (key === 'prescriptionStartDate' || key === 'prescriptionEndDate' || key === 'startTime' || key === 'endTime') {
            this.setValidators(key);
            this.formSubmitAttempt = true;
          }
        } else {
          this.formGroup.controls[key].clearValidators();
          this.formGroup.controls[key].updateValueAndValidity();
          this.formSubmitAttempt = false;
        }
      }
    }
  }
  setValidators(key) {
    if (key === 'prescriptionStartDate') {
      this.formGroup.controls[key].setValidators([this.validateStartDate]);
    } else if (key === 'prescriptionEndDate') {
      this.formGroup.controls[key].setValidators([this.validateEndDate]);
    } else if (key === 'startTime') {
      this.formGroup.controls[key].setValidators([this.validateStartTime]);
    } else if (key === 'endTime') {
      this.formGroup.controls[key].setValidators([this.validateEndTime]);
    } else if (key === 'viewby') {
      this.formGroup.controls[key].setValidators([Validators.required]);
    }
    this.formGroup.controls[key].updateValueAndValidity();
  }



  // This will do form sumbission call
  actionField(event?) {
    this.formSubmitAttempt = true;
    if (this.formGroup.valid) {
      console.log(this.formGroup);
      this.sendingData(this.formGroup.controls);
      this.formSubmitAttempt = false;
    }
    setTimeout(() => {
      if (document.getElementsByClassName('invalid-feedback')[0]) {
        document.getElementsByClassName('invalid-feedback')[0].scrollIntoView({ block: 'center' });
      }
    }, 2);
  }

  // Validation for Radio fields
  validateCheckBox(control: FormControl) {
    let found = control.value;
    return found ? null : { radio: true };
  }

  // Validation for StartDate
  validateStartDate(control: FormControl) {
    let startDate = control.value;
    if (startDate === '') {
      return null;
    } else {
      startDate = formatDate(new Date(new Date(startDate)), 'yyyy-MM-dd', 'en-US');
      let endDate = (control.parent !== undefined) ? control.parent.get('prescriptionEndDate').value : '';
      endDate = (endDate !== '') ? formatDate(new Date(new Date(endDate)), 'yyyy-MM-dd', 'en-US') : '';
      let found = endDate < startDate;
      if (found && endDate != '') {
        if (control.parent !== undefined) {
          control.parent.get('prescriptionEndDate').setErrors({ date: true });
        }
      } else {
        if (control.parent !== undefined) {
          control.parent.get('prescriptionEndDate').setErrors(null);
        }
      }
    }
  }
  // Validation for EndDate
  validateEndDate(control: FormControl): any {
    let endDate = control.value;
    if (endDate === '') {
      return null;
    } else {
      endDate = formatDate(new Date(endDate), 'yyyy-MM-dd', 'en-US');
      let startDate = (control.parent !== undefined) ? control.parent.get('prescriptionStartDate').value : '';
      startDate = (startDate !== '') ? formatDate(new Date(startDate), 'yyyy-MM-dd', 'en-US') : '';
      let found = endDate >= startDate;
      if(startDate === ''){
        control.parent.get('prescriptionStartDate').setErrors({ name: true });
      } else {
        control.parent.get('prescriptionStartDate').setErrors(null);
      }
      return found ? null : { date: true };
    }
  }

  // Validation for the Starting Time
  validateStartTime(control: FormControl) {
    let startTime = control.value;
    if (startTime === '' || startTime === undefined) {
      return null;
    } else {
      startTime = formatDate(new Date(startTime), 'HH:mm', 'en-US');
      let startDate = (control.parent !== undefined) ? (control.parent.get('prescriptionStartDate').value === '' ? '' : formatDate(new Date(control.parent.get('prescriptionStartDate').value), 'yyyy-MM-dd', 'en-US')) : '';
      let endDate = (control.parent !== undefined) ? (control.parent.get('prescriptionEndDate').value === '' ? '' : formatDate(new Date(control.parent.get('prescriptionEndDate').value), 'yyyy-MM-dd', 'en-US')) : '';
      let endTime = (control.parent !== undefined) ? (control.parent.get('endTime').value === '' ? '' : formatDate(new Date(control.parent.get('endTime').value), 'HH:mm', 'en-US')) : '';
      let found = `${endDate}T${endTime}` < `${startDate}T${startTime}`;
      if (found) {
        if (control.parent !== undefined) {
          control.parent.get('endTime').setErrors({ time: true });
        }
      } else {
        if (control.parent !== undefined) {
          control.parent.get('endTime').setErrors(null);
        }
      }
    }
    return null;
  }

  // Validating endTime
  validateEndTime(control: FormControl): any {
    let endTime = control.value;
    if (endTime === '' || endTime === undefined) {
      return null;
    } else {
      endTime = formatDate(new Date(endTime), 'HH:mm', 'en-US');
      let startDate = (control.parent !== undefined) ? (control.parent.get('prescriptionStartDate').value === '' ? '' : formatDate(new Date(control.parent.get('prescriptionStartDate').value), 'yyyy-MM-dd', 'en-US')) : '';
      let endDate = (control.parent !== undefined) ? (control.parent.get('prescriptionEndDate').value === '' ? '' : formatDate(new Date(control.parent.get('prescriptionEndDate').value), 'yyyy-MM-dd', 'en-US')) : '';
      let startTime = (control.parent !== undefined) ? (control.parent.get('startTime').value === '' ? '' : formatDate(new Date(control.parent.get('startTime').value), 'HH:mm', 'en-US')) : '';
      startTime = (startTime !== '') ? startTime : '';
      let found = `${endDate}T${endTime}` >= `${startDate}T${startTime}`;
      return found ? null : { time: true };
    }
  }

  // This will call when date is changed
  dateChanged() {
    if(this.formGroup.controls['viewby']){
      let radioValue = this.formGroup.controls['viewby'].value;
    if (radioValue === '') {
      this.formGroup.controls['viewby'].setValidators([Validators.required]);
      this.formGroup.controls['viewby'].updateValueAndValidity();
    }
    }
  }
  // This will do if category selected all sub options will be slected and vice-versa
  categorySelect(event, index) {
    let groupList = [];
    this.formInputs[index].isSelected = event.checked;
    let status = this.formInputs[index].isSelected;
    let key = this.formInputs[index].key;
    if (this.formInputs[index].isSelected) {
      this.formInputs[index].isIntermediate = false;
    }
    this.formInputs[index].options = this.formInputs[index].options.map((each) => {
      return {
        ...each,
        isSelected: each.editable === true || each.editable === undefined ? this.formInputs[index].isSelected : false,
      };
    });
    if (key === 'status' || key === 'invTreatment') {
      let flag = false;
      if (this.formInputs[0].options[0].isSelected === true) {flag = true; }
      if (!flag) {
        this.formInputs[2].options.forEach(obj => {
          if (obj.isSelected === true) {
            flag = true;
            return false;
          }
        });
      }
      if (flag) {
          this.formInputs[4].isSelected = false;
          this.formInputs[4].editable = false;
          this.formInputs[4].options[0].isSelected = false;
          this.formInputs[4].options[0].editable = false;
          this.formInputs[4].options[1].isSelected = false;
          this.formInputs[4].options[1].editable = false;
        } else {
          this.formInputs[4].editable = true;
          this.formInputs[4].options[0].editable = true;
          this.formInputs[4].options[1].editable = true;
        }
    }

    if (key === 'other' && status === true) {
      this.formInputs[0].options[0].isSelected = false;
      this.formInputs[0].options[0].editable = false;
      this.formInputs[2].isSelected = false;
      this.formInputs[2].editable = false;
      this.formInputs[2].options.forEach(obj => {
        obj.isSelected = false;
        obj.editable = false;
      });
    }
    if (key === 'other' && status === false) {
      this.formInputs[0].options[0].editable = true;
      this.formInputs[2].editable = true;
      this.formInputs[2].options.forEach(obj => {
        obj.editable = true;
      });
    }
    this.formGroup.controls[this.formInputs[index].key].setValue(this.setGroupcheck(this.formInputs[index]));
    console.log(this.formInputs[index]);
  }

  // This set formGroup values which is selected
  setGroupcheck(config) {
    let checkedGroup = [];
    if (config && typeof (config) === 'object') {
      config.options.forEach(each => {
        if (each.isSelected === true) {
          checkedGroup.push(each.value);
        }
      });
    }
    return checkedGroup;
  }

  // This will do if option selected or unselected category will be unselected
  optionSelect(event, taskId, categoryId) {
    this.formInputs[categoryId].options[taskId].isSelected = event.checked;
    let status = this.formInputs[categoryId].options[taskId].isSelected;
    let key = this.formInputs[categoryId].key;

    if (key === 'status' || key === 'invTreatment') {
      let flag = false;
      if (this.formInputs[0].options[0].isSelected === true) {flag = true; }
      if (!flag) {
        this.formInputs[2].options.forEach(obj => {
          if (obj.isSelected === true) {
            flag = true;
            return false;
          }
        });
      }
      if (flag) {
          this.formInputs[4].isSelected = false;
          this.formInputs[4].editable = false;
          this.formInputs[4].options[0].isSelected = false;
          this.formInputs[4].options[0].editable = false;
          this.formInputs[4].options[1].isSelected = false;
          this.formInputs[4].options[1].editable = false;
        } else {
          this.formInputs[4].editable = true;
          this.formInputs[4].options[0].editable = true;
          this.formInputs[4].options[1].editable = true;
        }
    }

    if (key === 'other') {
      if (status === true) {
        if(taskId == 0){
          this.formInputs[0].options[0].isSelected = false;
          this.formInputs[0].options[0].editable = false;
          this.formInputs[2].isSelected = false;
          this.formInputs[4].options[1].isSelected = false;
          this.formInputs[4].options[1].editable = false;
          this.formInputs[2].editable = false;
          this.formInputs[2].options.forEach(obj => {
            obj.isSelected = false;
            obj.editable = false;
          });
      }
      else{
        this.formInputs[0].options[0].isSelected = false;
        this.formInputs[0].options[0].editable = false;
        this.formInputs[2].isSelected = false;
        this.formInputs[4].options[0].isSelected = false;
        this.formInputs[4].options[0].editable = false;
        this.formInputs[2].editable = false;
        this.formInputs[2].options.forEach(obj => {
          obj.isSelected = false;
          obj.editable = false;
        });
      }
      } else if (status === false) {
        if(taskId == 0){
        this.formInputs[0].options[0].editable = true;
        this.formInputs[2].editable = true;
        this.formInputs[4].options[1].editable = true;
        this.formInputs[2].options.forEach(obj => {
          obj.editable = true;
        });
      }
      else{
        this.formInputs[0].options[0].editable = true;
        this.formInputs[2].editable = true;
        this.formInputs[4].options[0].editable = true;
        this.formInputs[2].options.forEach(obj => {
          obj.editable = true;
        });
      }
    }
    }
    let optionsCount = this.formInputs[categoryId].options.length;
    let count = 0;
    this.formInputs[categoryId].options.forEach((each) => {
      if (each.isSelected === true) {
        count++;
      }
    });
    if (count > 0 && count < optionsCount) {
      this.formInputs[categoryId].isIntermediate = true;
    } else {
      this.formInputs[categoryId].isIntermediate = false;
    }
    if (count === optionsCount) {
      this.formInputs[categoryId].isSelected = true;
      this.formInputs[categoryId].isIntermediate = false;
    } else {
      this.formInputs[categoryId].isSelected = false;
    }
    this.formGroup.controls[this.formInputs[categoryId].key].setValue(this.setGroupcheck(this.formInputs[categoryId]));
  }

  // Formating request JSON
  sendingData(form) {
    var filter: any;
    if (this.filterType === 'appointments') {
      filter = {
        doctor: form.doctor.value,
        treatment: form.treatment.value,
        location: form.location.value,
      };
      if (form.viewby.value && form.prescriptionStartDate.value && form.prescriptionEndDate.value) {
        filter = {
          ...filter,
          viewby: (form.viewby.value === 'All' || form.viewby.value === '') ? ['bookingDate', 'appointmentDate'] : [form.viewby.value],
        };
      }
      if (form.viewby.value && form.prescriptionStartDate.value !== '' && form.prescriptionEndDate.value !== '') {
        filter = {
          ...filter,
          time: {
            startTime: `${(form.prescriptionStartDate.value === '') ? '' : formatDate(new Date(form.prescriptionStartDate.value), 'yyyy-MM-dd', 'en-US')}  ${(form.startTime.value === '' || form.startTime.value === null) ? '00:00:00' : formatDate(new Date(form.startTime.value), 'HH:mm:ss', 'en-US')}`,
            endTime: `${(form.prescriptionEndDate.value === '') ? '' : formatDate(new Date(form.prescriptionEndDate.value), 'yyyy-MM-dd', 'en-US')}  ${(form.endTime.value === '' || form.endTime.value === null) ? '23:59:59' : formatDate(new Date(form.endTime.value), 'HH:mm:ss', 'en-US')}`,
          },
        };
      }
    } else if (this.filterType === 'invoice') {
      if(form.other.value== 'Patient Document List'){
        filter = {
          treatment: form.invTreatment.value,
          status: form.status.value,
          patientInvoice : false,
          invoiceType: 'document_request_invoice'
        };
      }
      else{
      filter = {
        treatment: form.invTreatment.value,
        status: form.status.value,
        patientInvoice : form.other.value.length > 0 ? true : false,
      }
      };
    } else if (this.filterType === 'patient') {
      filter = (form.offlinePatientStatus.value.length === 0 && form.onlinePatientStatus.value.length === 0 ) ? {} : {
        offlinePatientStatus: form.offlinePatientStatus.value,
        onlinePatientStatus: form.onlinePatientStatus.value,
      };
    } else if(this.filterType === 'reqDocs'){
      if(form.docStatus){
        filter = {
          requestType: form.requestType.value,
          status: form.docStatus.value,
        };
      }
      else{
        filter = {
          requestType: form.requestType.value,
          status: [],
        };
      }
      if (form.prescriptionStartDate.value !== '' || form.prescriptionEndDate.value !== '') {
        filter = {
          ...filter,
          time: {
            startTime: form.prescriptionStartDate.value === '' ? '' : `${(form.prescriptionStartDate.value === '') ? '' : formatDate(new Date(form.prescriptionStartDate.value), 'yyyy-MM-dd', 'en-US')}  ${(form.startTime.value === '' || form.startTime.value === null) ? '00:00:00' : formatDate(new Date(form.startTime.value), 'HH:mm:ss', 'en-US')}`,
            endTime: `${(form.prescriptionEndDate.value === '') ? form.prescriptionStartDate.value !== '' ? formatDate(new Date(), 'yyyy-MM-dd', 'en-US') : '' : formatDate(new Date(form.prescriptionEndDate.value), 'yyyy-MM-dd', 'en-US')}  ${(form.endTime.value === '' || form.endTime.value === null) ? '23:59:59' : formatDate(new Date(form.endTime.value), 'HH:mm:ss', 'en-US')}`,
          },
        };
      }
    } else {
      // Errors
    }
    this.submitEvent.emit(filter);
  }

  /**
   * To set validators for time fields.
   */
  setValidatorsForTime(){
    if(this.filterType === 'reqDocs'){
      this.formInputs.forEach(element => {
        if(element.key == 'prescriptionStartDate' || element.key == 'prescriptionEndDate' || element.key == 'startTime' || element.key == 'endTime'){
          this.setValidators(element.key)
        }
      });
    }
  }
}
