import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { CustomFormHandlerService } from '@app/common/services/custom-form-handler.service';
import { TOAST_STATUSES } from '@app/common/constants/util.constant';
import { ObservableHelperService } from '@app/common/services/observable-helper.service';
import { CognitoService } from '@app/common/services/cognito.service';
import { Router } from '@angular/router';
import { admin } from '../../../../environments/environment';
import { AdminCogntioService } from '@app/common/services/admin-cogntio.service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {

  @Output() state = new EventEmitter<any>();
  adminMail = admin.email;
  form: any;
  registrationPhase = 'pre';
  userMail;
  registrationData = {
    pre: {
      headerData1: 'Let\'s get you started,',
      headerData2: 'Create a New Tele Doc account to book & manage your appointments.',
      text1: 'Already have an account?',
      btnText: 'Login'
    },
    post: {
      headerData1: 'Thank you for registering with ',
      headerData2: 'New Tele Doc!',
      text1: 'To activate your account, please click on the link sent to your registered email id.',
      btnText: 'Didn’t receive an email?'
    }
  };

  constructor(
    private formService: CustomFormHandlerService,
    private observableHelperService: ObservableHelperService,
    private cognitoService: CognitoService,
    private router: Router,
    private adminCognito: AdminCogntioService
  ) { }

  ngOnInit() {
    this.formService.registerFormInputsConfig.forEach(each => {
      if (each.isShowpassword) {
        each.isShowpassword = false;
        each.type = 'password';
        each.value = '';
      } else if (each.isShowpassword === false) {
        each.type = 'password';
      }
    });
    this.setFormFields(this.formService.formTypes.REGISTER);
    try {

      this.form.formGroup.controls.newPassword.valueChanges.subscribe(
        x => {
          if (this.form.formGroup.controls.newConfirmPassword) {
            this.form.formGroup.controls.newConfirmPassword.updateValueAndValidity();
          }
        }
      );
    } catch (err) {

    }
  }

  setFormFields(tag) {
    this.form = this.formService.initForm(tag);
  }

  existingUser() {
    this.state.emit('login');
  }

  btnClickEvent(event) {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
    console.log(event.value);
    let registerData = event.value;
    if (Object.keys(registerData).length === 0) {
      if (this.form.formInputs[0].value === 'resend') {
        this.adminCognito.resendConfirmationCode({ userName: this.userMail.toLowerCase() }, () => {

        });
      } else {
        this.state.emit('login');
        this.router.navigate(['login']);
      }

      // this.observableHelperService.showToast(TOAST_STATUSES.SUCCESS, "Your email has been sent");
    } else {
      registerData.emailId = registerData.emailId.toLowerCase();
      console.log(event, 'regiter data');
      this.userMail = registerData.emailId.toLowerCase();
      this.cognitoService.signUp(registerData, () => {
        this.form.formGroup.reset();
        this.registrationPhase = 'post';
        this.setFormFields(this.formService.formTypes.RESENDMAIL);
        document.getElementById('registered-user-id').style.marginBottom = '0';
        document.getElementById('register-form-id').style.marginTop = '15px';
        event.reset();
      });
    }
  }
  toggleScroll() {
    document.body.style.overflow = 'scroll';
  }

  ngOnDestroy() {
    this.form.formGroup.reset();
    this.form.formInputs.forEach(ele => ele.value = '');
    this.userMail = '';
  }

}
