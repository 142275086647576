import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({
  name: 'customDate'
})
export class CustomDatePipe extends DatePipe implements PipeTransform {


  // Date pipe it adds suffix to date like(1st,2nd,3rd) and return (Sat, 27th Jun)
  transform(date: any): any {
    if (date) {
      date = new Date(date);
      let suffix = 'th';
      let day = date.getDate().toLocaleString();

      if (day === '1' || day === '21' || day === '31') {
        suffix = 'st';
      } else if (day === '2' || day === '22') {
        suffix = 'nd';
      } else if (day === '3' || day === '23') {
        suffix = 'rd';
      }
      return `${date.toLocaleString('default', { weekday: 'short' })}, ${date.toLocaleString('default', { month: 'short' })} ${day} `;
    } else {
      return '---';
    }
  }
}
