import { Component, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { convertDateToSpecifiedOffset, DEFAULT_TIME_ZONE_DIFF, TOAST_STATUSES } from '@app/common/constants/util.constant';
import { ObservableHelperService } from '@app/common/services/observable-helper.service';
import { ROUTE_PATHS } from '@app/common/constants/routing.constant';

@Component({
  selector: 'app-patient-documents-request-list',
  templateUrl: './patient-documents-request-list.component.html',
  styleUrls: ['../appointments-list/appointments-list.component.scss']
})
export class PatientDocumentsRequestListComponent implements OnInit {

  @Input() adminPermissions;
  // patient data @input varible and initial data
  @Input() requestData;
  @Input() patientRequest;
  @Output() patientTrigger = new EventEmitter<any>();
  @ViewChild('op', { static: false }) overlay;
  isPatient: boolean = true;


  constructor(private router: Router, private observable: ObservableHelperService) { }

  ngOnInit() {

    if(this.patientRequest === 'true'){
      this.isPatient = false;
    }
  }
  requestListClick(details) {
    if(this.patientRequest === 'true'){
      this.router.navigate([`${ROUTE_PATHS.PATIENTS}/${ROUTE_PATHS.REQUESTED_DOCUMENTS}/${details.requestId}`]);
    }else if(this.patientRequest === false){
      this.router.navigate([`${ROUTE_PATHS.ADMIN}/${ROUTE_PATHS.PATIENTS}/${ROUTE_PATHS.REQUESTED_DETAILS}/${details.requestId}`]);
    }
    else{
      this.router.navigate([`${ROUTE_PATHS.ADMIN}/${ROUTE_PATHS.PATIENTS}/${ROUTE_PATHS.REQUESTED_DOCUMENTS}/${details.requestId}`]);
    }
  }
  moreClick(e, op) {
    console.log(e, op);
  }
  requestActionEvent(...details) {
    console.log(...details);
  }
}
