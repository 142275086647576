import { Component, OnInit, EventEmitter, ChangeDetectorRef, Output, Input } from '@angular/core';
import { DEFAULT_PAGINATION_LIMIT } from '@app/common/constants/util.constant';


@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent implements OnInit {
  PAGE_LIMITS = [{label: 10, value: 10}, {label: 20, value: 20}, {label: 50, value: 50}, {label: 100, value: 100}];
  totalNumberOfPages: any;
  paginationControls = []; // pagination nav data (controls with dots)
  paginationData: any; // Actual data to paginate
  currentPage = 0; // Current page
  offLine = true; // whether to handle pagination chunking and limiting evething locally or from backend
  limit = DEFAULT_PAGINATION_LIMIT; // Number of records per page
  totalPages;
  inputData: any;

  @Input()
  set paginationRawData(data) {
    this.offLine = data.offLine;
    this.limit = data.limit;
    this.currentPage = data.currentPage || 0; // initial default page for offline
    this.paginationData = data.data;
    this.totalPages = data.totalPages;
    this.processPagination();
  }
  @Input() paginationDropDownDisplay = true;
  @Input() totalNumberOfList: any;
  @Output() pageChanged = new EventEmitter<object>();


  constructor( private cd: ChangeDetectorRef) {}

  ngOnInit() {
    // Do something...
   this.cd.markForCheck();
  }


  /**
   * @desc To chunk the data and process the pagination
   */
  processPagination() {
    if (!this.paginationData) { return; }

    if (this.offLine) {
      this.paginationData = this.makeChunks(this.paginationData, this.limit);
      // tslint:disable-next-line: max-line-length
      const offlineDefaultPage = this.currentPage > 0 && this.paginationData.length >= this.currentPage ? this.currentPage : this.currentPage;
      this.pageChanged.emit((this.paginationData[offlineDefaultPage - 1] || []));
      this.paginationControls = this.pagination(offlineDefaultPage, this.totalPages);
    } else {
      this.paginationControls = this.pagination(this.currentPage, this.totalPages);
    }
    this.cd.markForCheck();
  }

  /**
   * @desc To make the array data in chunks
   * @param { any } pdata
   * @param  {number} limit
   * @returns chunked data
   */
  makeChunks(pdata: any, limit: number): any {
    limit = limit || 20;
    let i, j, temparray: any = {}, chunkData: any = {};
    chunkData.data = [];
    for (i = 0, j = 0; i < pdata.length; i += limit, j++) {
      temparray.data = pdata.slice(i, i + limit);
      temparray.pageNumber = j + 1;
      // do whatever
      chunkData.data.push(temparray);
      temparray = {};
    }
    chunkData.totalPages = j;
    chunkData.currentPage = 1;
    return chunkData;
  }

  /**
   * @desc To create data pagination nav item
   * @param  {number} currentPage
   * @param  {number} nrOfPages
   * @returns paginated nav item
   */
  pagination(currentPage: number, nrOfPages: number): any {
    var delta = 2,
      range = [],
      rangeWithDots = [],
      l;

    this.currentPage = currentPage;

    // Pushing first value
    range.push(1);

    if (nrOfPages <= 1) {
      return range;
    }

    for (let i = currentPage - delta; i <= currentPage + delta; i++) {
      if (i < nrOfPages && i > 1) {
        range.push(i);
      }
    }

    // Pushing last value
    range.push(nrOfPages);

    for (let i of range) {
      if (l) {
        if (i - l === 2) {
          rangeWithDots.push(l + 1);
        } else if (i - l !== 1) {
          rangeWithDots.push('.....');
        }
      }
      rangeWithDots.push(i);
      l = i;
    }

    return rangeWithDots;
  }

  /**
   * @desc To change the list data to show when page is changed
   * @param {number} pagntn
   */
  changePage(pagntn: number, limitChange?: boolean): void {
    if (pagntn !== this.currentPage || limitChange) {
      this.paginationControls = this.pagination(pagntn, this.totalPages);
      if (this.offLine) {
        this.pageChanged.emit(this.paginationData[pagntn - 1]);
      } else {
        this.pageChanged.emit({ currentPage: pagntn, limit: this.limit });
      }
    }
  }

  /**
   * @desc To change the data when previous button is clicked from nav-items
   */
  previousPage() {
    if (this.currentPage > 1) {
      this.paginationControls = this.pagination(this.currentPage - 1, this.totalPages);
      if (this.offLine) {
        this.pageChanged.emit(this.paginationData[this.currentPage - 1]);
      } else {
        this.pageChanged.emit({currentPage: this.currentPage, limit: this.limit});
      }
    }
  }

  /**
   * @desc To change the data when next button is clicked from nav-items
   */
  nextPage() {
    if (this.currentPage < this.totalPages) {
      this.paginationControls = this.pagination(this.currentPage + 1, this.totalPages);
      if (this.offLine) {
        this.pageChanged.emit(this.paginationData[this.currentPage - 1]);
      } else {
        this.pageChanged.emit({currentPage: this.currentPage, limit: this.limit});
      }
    }
  }
 /**
  *
  * @desc geting the dropdown change event and passing the emit data
  */
 dropDownOnChange(event) {
    // this.pageChanged.emit({currentPage: this.currentPage, initailListLimit: event.value * this.currentPage,
    // perpage: event.value});
    // this.totalNumberOfPages = Math.ceil(this.totalNumberOfList / event.value);
    // this.totalPages = this.totalNumberOfPages;
    // this.processPagination();
    this.changePage(1, true);
  }
}

