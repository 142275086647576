import { Component, Input, OnInit, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-last-visit-block',
  templateUrl: './last-visit-block.component.html',
  styleUrls: ['./last-visit-block.component.scss']
})
export class LastVisitBlockComponent implements OnInit {

  @Input() lastVisitData;
  BlockedServices : any;
  constructor() { }
  ngOnChanges(changes: SimpleChanges) {
     this.BlockedServices = this.lastVisitData.details.blockStatus;
    this.BlockedServices = this.BlockedServices.filter((obj, pos, arr) => {
      return arr.map(mapObj => mapObj.appointmentTypeId).indexOf(obj.appointmentTypeId) === pos;
    })
  }
  ngOnInit() {
  }

}
