import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-carousel-bullets',
  templateUrl: './carousel-bullets.component.html',
  styleUrls: ['./carousel-bullets.component.scss']
})
export class CarouselBulletsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
