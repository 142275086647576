import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, CanLoad, Route, UrlSegment, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router, ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { ROUTE_PATHS } from '../constants/routing.constant';
import { CognitoService } from './cognito.service';

@Injectable({
  providedIn: 'root'
})
export class ActivateGuard implements CanActivate, CanActivateChild, CanLoad {
  currentUrl: string;
  constructor(private router: Router, private cognitoService: CognitoService) { }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    this.currentUrl = this.router.url;
    return this.cognitoService.getUser().then(user => {

    const urlParams = new URLSearchParams(window.location.search);
    const email = urlParams.get('email');
    const templateId = urlParams.get('templateId');
    if(email && templateId){
      window.localStorage.setItem('unsub', 'true');
      window.localStorage.setItem('templateId', templateId);
      window.localStorage.setItem('email', email);
    }

      console.log(user);
      if (user && !(user.lastName && user.firstName) && !this.currentUrl.includes('profile')) {
        if (user && user.email) {
          this.router.navigate(['profile']);
        } else {
          window.localStorage.clear();
          this.router.navigate(['login']);
        }
      } else if (user && !user.admin) {
        return true;
      } else if (user && user.admin) {
        this.router.navigate([`${ROUTE_PATHS.ADMIN}`]);
      } else {
        window.localStorage.clear();
        this.router.navigate(['login']);
      }
    });
  }
  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return true;
  }
  canLoad(
    route: Route,
    segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean {
    return true;
  }
}
