import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { ROUTE_PATHS } from '@app/common/constants/routing.constant';

@Component({
  selector: 'app-tms-redirect',
  templateUrl: './tms-redirect.component.html',
  styleUrls: ['./tms-redirect.component.scss']
})
export class TmsRedirectComponent implements OnInit {

  constructor(private router: Router, private route: ActivatedRoute) {
    let patId = router.url.split('/').pop();
    window.localStorage.setItem('tms-redirect', JSON.stringify(true));
    window.localStorage.setItem('patId', JSON.stringify(patId));
    this.router.navigate([`${ROUTE_PATHS.ONBOARD}`]);
  }

  ngOnInit() {
  }

}
