export const TOAST_MESSAGES = {
    INVALID_EXTENSION_ERROR_MSG: 'Only jpg, png, gif, jpeg, tiff, psd, pdf, esp, ai, indd, raw, heic, heif documents are allowed. Please remove not supported documents',
    INVALID_FILENAME_ERROR_MSG: 'Filename should not contain special characters. Please upload with a different name.',
    MAX_FILE_LIMIT_ERROR_MSG: 'Maximum 5 documents are allowed to upload.',
    MAX_FILE_SIZE_ERROR_MSG: 'Maximum document size is 10MB.',
    SUCCESSFULL_UPLOAD_MSG: 'Successfully uploaded documents.',
    UPLOAD_ERROR_MSG: 'Error in uploading documents.',
    DOCUMENT_DATA_REQUEST_ERROR_MSG: 'Error in getting document request details data',
    CONFORM_DOCUMENT: 'Data Error',
    CONFORM_DOCUMNT_COMPLETED: 'Documents Confirm Successfully',
    CONFIRM_DOCS: 'Employee Assigned Successfully',
    CONFIRM_PAYMENT: 'Invoice has been marked as Paid Successfully Completed',
    CONFIM_DOCS_CONTENT: 'Comments Send to Patient Successfully',
    UPLOADS_DOCS: 'Upload Successfully',
    SEND_CHNAGES_DATA: 'Data is Wrong',
    SEND_CHANGED_SUCCESS: 'Response Sent Successfully',
    INVOICE_RE_SENT: 'Invoice has been re-sent to the customer successfully.',
    RESEND_INVOICE :'Failed to resend invoice the appointment. Please try again',
    INVOICE_CUSTOMER:'Invoice has been sent to the customer successfully',
    INVOICE_MARKED:'Invoice has been marked as paid successfully.',
    UPDATAE_FILES_ERROR:'Failed to update uploading files . Please try again',
    DELETE_FILES_ERROR:'Failed to delete uploading files . Please try again',
    REJECT_DATA_SUCCESS: 'Reject Successfully',
    REJECT_DATA_ERROR: 'Reject Error',
    REQUEST_SENT_TO_PATIENT_MSG: 'Document request successfully',
    REQUEST_SENT_TO_PATIENT_ERROR_MSG: 'Error in sending request to patient.',
    SUCCESSFULL_REMIDNDER_SENT_MSG: 'Succefully sent reminder to patient',
    SEND_REMINDER_ERROR_MSG: 'Error in sending reminder to patient',
    COMPLETED_DOCUMENT_REQUEST_MSG: 'Document request completed successfully',
    CANCEL_DOCUMENT_REQUEST_MSG: 'Document Request has been canceled',
    COMPLETED_DOCUMENT_REQUEST_ERROR_MSG: 'Error in mark as complete request.',
    CANCEL_REQUEST_ERROR_MSG: 'Error in cancel request.',
    EXCLUDE_PATIENTS_MSG: 'Successfully excluded patients',
    EXCLUDE_PATIENTS_ERROR_MSG: 'Failed to exclude patients. Please try again',
    EMAIL_TEMPLATES_FETCH_ERROR_MSG: 'Failed to fetch email template list. Please try again',
    EMAIL_ALREADY_EXCLUDED_ERROR_MSG: 'This email Id already excluded.',
    EMAILS_DATA_FETCH_ERROR_MSG : 'Failed to fetch emails data. Please try again',
    UNABLE_TO_UPDATE_PROFILE: 'Unable to update profile.',
    SUCCESSFULL_UPLOAD:   'File has been successfully uploaded.',
    UPDATED_USER_PROFILE_ADMIN: 'Successfully updated user profile.',
    UNABLE_TO_FETCH_POC: 'Unable to fetch point of contact.',
    POC_DOESNT_EXIST: 'Point of contact does not exist for this patient.',
    FILE_UPLOAD_INPROGRESS_MSG: 'File upload is in progress. Please wait for few seconds.',
    VERIFY_EMAIL_MSG: 'Please verify your email id.',
    EMAIL_TEMPLATE_DETAILS_ERROR_MSG: 'Failed to fetch email template details. Please try again',
    UPDATE_EMAIL_TEMPLATE: 'Email template updated successfully.',
    UPDATE_EMAIL_TEMPLATE_FAIL: 'Unable to update the email template.',
    GET_EMAIL_TEMPLATE_DETAILS_FAIL: 'Failed to fetch the email template details.',
    ACCESS_PERMISSOIN_DENIED_MSG: 'Sorry! You do not have access to this page.',
    ACCESS_PERMISSION_ERROR_MSG: 'Error in getting permissions',
    ADHD_QUESTIONNAIRE_SUCCESS_MSG: 'ADHD Questionnaire submitted successfully.',
    SUBSTANCE_CONSENT_SUCCESS_MSG: 'CONTROL SUBSTANCE submitted successfully.',
    PHQ_QUESTIONNAIRE_SUCCESS_MSG: 'PHQ9 Questionnaire submitted successfully.',
    ADHD_QUESTIONNAIRE_ERROR_MSG: 'Failed to submit the ADHD Questionnaire form. Please try again',
    CONTROL_SBSTANCE_ERROR_MSG: 'Failed to submit the CONTROL SUBSTANCE FORM. Please try again',
    PHQ_QUESTIONNAIRE_ERROR_MSG: 'Failed to submit the PHQ9 Questionnaire form. Please try again',
    KETAMINE_MONITORING_SUBMIT_MSG: 'Ketamine Monitoring form submitted successfully.',
    KETAMINE_MONITORING_SAVE_MSG: 'Ketamine Monitoring form saved successfully.',
    KETAMINE_MONITORING_ERROR_MSG:  'Failed to submit the ketamine monitoring. Please try again',
    APPOINTMENT_DETAILS_ERROR_MSG: 'Failed to fetch appointment details. Please try again',
    ADHD_REQUEST_NOT_FOUND_MSG: 'There are no open ADHD requests.',
    PHQ_REQUEST_NOT_FOUND_MSG: 'There are no open PHQ9 requests.',
    PATIENT_LIST_ERROR_MSG: 'Failed to fetch pateint list. Please try again',
    MEDS_NOTIFICATION_SUCCESS: 'Medication prescription email sent successfully.',
    MEDS_NOTIFICATION_FAIL: 'Unable to send medication prescription email.',
    DOCS_LIST_FAIL: 'Failed to fetch request documents list. Please try again',
    DOC_FILTER_FAIL: 'Failed to fetch request document filter data. Please try again',
    THERAPIST_INFO_SUBMIT_SUCCESS: 'Therapist information submitted successfully.',
    THERAPIST_INFO_SUBMIT_FAIL: 'Failed to submit the therapist information.',
    USER_INFO_FAIL: 'Failed to fetch patient emergency contact info.',
    THERAPIST_INFO_FAIL: 'Failed to fetch patient therapist info.',
    THERAPIST_INFO_NOT_FOUND: 'There are no open therapist info requests.',
    MEDICATION_MAIL_LIMIT: 'You can only select 30 patients at a time.',
    LOGIN_SESSION_EXP: 'Session expired. Please login again...',
    INVALID_THERAPIST_MAIL: 'Therapist mail must be a valid email',
    INVALID_PRESCRIBER_MAIL: 'Prescriber mail must be a valid email',
    UNSUB_FAIL: 'Failed to unsubscribe the email.',
    UNSUB_SUCCESS: 'Unsubscribed the email successfully.',
    MEDICATION_FEEDBACK_SUCCESS_MSG: 'Medication Management Feedback submitted successfully.',
    MEDICATION_FEEDBACK_ERROR_MSG: 'Failed to submit the Medication Management feedback form. Please try again',
    ADHD_REQUEST_NOT_FOUND: 'There are no open ADHD requests.',
    MEDICATION_FEEDBACK_REQUEST_NOT_FOUND: 'There are no open Medication Feedback requests.',
    ALREADY_SUBSCRIBED: 'You have already unsubscribed the email.',
    TMS_NOTIFICATION_SUCCESS: 'TMS/Ketamine follow up email sent successfully.',
    TMS_NOTIFICATION_FAIL: 'Unable to send TMS/Ketamine follow up email.',
    APPOINTMENT_CONFIRMED: 'This appointment is not in pending state.',
    APPOINTMENT_DETAILS_FAIL: 'Failed to fetch appointment data. Please try again',
    WRONG_DOB: 'Unable to complete the action. Please try again.',
    LAB_DOC_FAIL: 'Failed to get the document details. Please try again',
    LAB_DOC_UPDATE_SUCCESS: 'PDF content updated successfully.',
    LAB_DOC_UPDATE_FAIL: 'Unable to update the PDF content.',
    DELETE_USER_SUCCESS_MSG: 'Successfully deleted user account.',
    DELETE_USER_ERROR_MSG: 'Failed to delete the user account.',
    RESET_USER_PASSWORD_SUCCESS_MSG: 'Successfully sent reset password link to the user.',
    RESET_USER_PASSWORD_ERROR_MSG: 'Failed to send the reset password link to the user.',
    INFORMATION_REQUEST_SUCCESS_MSG: 'INFN Req submitted successfully.',
    INFORMATION_FORM_SUCCESS_MSG: 'INFN Form updated successfully.',
    INFORMATION_REQUEST_ERROR_MSG: 'Failed to submit INFN Req. Please try again.',
    INFORMATION_REQUEST_NOT_FOUND_MSG: 'There are no open INFN Req.',
    INFORMATION_REQUEST_FAILED_ERROR_MSG: 'Error in getting information request details data.',
    INFORMATION_REQUEST_FORM_FAILED_ERROR_MSG: 'Error in getting information request form data.',
    MEDICATION_FEEDBACK_NOT_FOUND_MSG: 'There are no open Medication feedback requests.',
    DELETE_PATIENT_SUCCESS_MSG: 'Successfully deleted patient.',
    DELETE_PATIENT_ERROR_MSG: 'Failed to delete the patient.',
    SHEET_INVALID: "Selected sheet doesn't have the following required columns: ",
    SHEET_UPLOAD_SUCCESS: "Uploaded the sheet successfully.",
    SHEET_UPLOAD_FAIL: "Unable to upload the sheet.",
    FILE_EXPORT_FAIL: "Unable to export the file.",
    FILE_EXPORT_SUCCESS: "Link has been shared successfully.",
    PLEASE_UPLOAD_XL_SHEET:"Please upload an excel sheet.",
    MAIL_GENERATED_SUCCESSFULLY_FOR_TOMORROW: 'Tomorrow Sheet Not Yet Imported',
    STATES_FAIL: 'Failed to fetch states list. Please try again',
    VALID_STATE: "We only accept patients within Tri-States (MD, VA and DC) and NY. Please change your Demographics to one of these 4 states.",
    PAYMENT_RECIEPTS_SUC: "Document sent to patient successfully.",
    PAYMENT_RECIEPTS_FAIL: "Error in sending the document to patient.",
    ANNOUNCEMENT_BANNER_DATA: "Error in getting announcement banner data.",
    MEDNOTE_GENERATED: `Patient's Mednote Generated Successfully.`,
    EVALUATTION_GENERATED: `Patient's Evaluation Note Generated Successfully.`,
    TMSNOTE_GENERATED: `Patient's TMS note Generated Successfully.`,
    TMSNOTE_SAVED: `Patient's TMS note Saved Successfully.`,
}


