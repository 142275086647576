// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // serverUrl: "http://localhost:3000",
  // serverUrl: 'https://a8cbx8dqja.execute-api.us-east-1.amazonaws.com/test',
  // serverUrl: 'https://tp5h0i7ejh.execute-api.us-east-1.amazonaws.com/sai/',
  // serverUrl: 'https://fh91usxie2.execute-api.us-east-1.amazonaws.com/beta',
  // serverUrl: 'https://fbu18gnk36.execute-api.us-east-1.amazonaws.com/admin',
  // serverUrl: 'https://9bl85vin1k.execute-api.ap-south-1.amazonaws.com/krishna',
  // serverUrl: 'https://mudmh1pdwd.execute-api.us-east-1.amazonaws.com/prod',
  serverUrl: 'https://api.qa.newteledoc.com',
  // serverUrl: 'https://qdylahw8gg.execute-api.us-east-1.amazonaws.com/staging',
  authenticationNeeded: false,
  token: ''
};


// export const poolData = {
//   UserPoolId: 'us-east-1_BPO2a7NAa',
//   ClientId: '74e7j5b871g1fpsdvibv1dt8rk'
// };

// export const poolData = {
//   UserPoolId: 'us-east-1_adW6b9U1A',
//   ClientId: '3gp5j5doii1j6gsrdlg683ct1q'
// };

export const poolData = {
  UserPoolId: 'us-east-1_zr8laU3pQ',
  ClientId: '90lfglb7i3prmlhqgks1o8d4t'
};




export const admin = {
  email: 'prabhakar@divami.com'
};

// export const poolData = {
//   UserPoolId: "us-east-1_FnO5ujiiZ",
//   ClientId: "4078o3blcjcnnromcg6thp64qo"
// }
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
