import { Component, EventEmitter, OnInit, Input, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ObservableHelperService } from '@app/common/services/observable-helper.service';
import { HttpService } from '@app/common/services/http.service';
import { HTTPMethods, TOAST_STATUSES } from '@app/common/constants/util.constant';
import { CognitoService } from '@app/common/services/cognito.service';
import { FileUpload } from 'primeng';
import { TOAST_MESSAGES } from '@app/common/constants/toast-messages.constant';
import { ApiGatewayManagementApi } from 'aws-sdk';
import { API_URLS } from '@app/common/constants/api-urls.constant';

@Component({
  selector: 'app-patient-documents-request',
  templateUrl: './patient-documents-request.component.html',
  styleUrls: ['./patient-documents-request.component.scss']
})
export class PatientDocumentsRequestComponent implements OnInit {
  remainingValue1: number = 350;
  documentUrls: any = [];
  fileValidation: boolean;
  userDetails: any;
  constructor(private cognitoService: CognitoService, private http: HttpService, private observable: ObservableHelperService) {
  }
  // dev info
  documentsArr: any = [
    // { id: 1, documentLabel: 'ADHD Document', isSelected: false }
  ];
  @Input() patientId;
  @Input() isPatient;
  @Output() closePopup = new EventEmitter<any>();
  @Input() set documentsRequest(value: any) {
    this.documentsArr = this.massageDocumentsData(value);
  }
  showSelectionError: boolean = false;
  showOther: boolean = false;
  showOtherError: boolean = false;
  remainingValue: number = 350;
  otherValue: any = '';
  adminComments: any = '';

  documentsSelected = [];
  uploadedFiles: any = [];
  selectedFiles: any = [];
  nonEmptyRegex = /^(?!\s*$).+/;
  ngOnInit() {
    this.cognitoService.getUser().then(data => {
      this.userDetails = data;
    });
  }

  // this function is to massage the document data
  massageDocumentsData(data) {
    if (!data && !Array.isArray(data)) { return []; }
    let tempList = data.map(eachDoc => {
      return {
        eachDoc,
        id: eachDoc.documentId,
        documentLabel: eachDoc.documentName,
        isSelected: false,
      };
    });

    let other = tempList.find(ele => ele.documentLabel == 'Others')
    tempList = tempList.filter(ele => (ele.documentLabel !== 'Others' && ele.eachDoc.documentName !== 'LAB WORK'))
    tempList.push(other);
    return tempList;
  }

  /**
   *
   * @param event uploaded files
   * @param remove flag for removing files
   * This funciton will do all the file validations like file extensions,file size,file name,maximum files
   *
   */
  onUpload(event, uploader: FileUpload, remove?) {
    let isRemove = remove || false;
    this.uploadedFiles = event.currentFiles;
    let extentions = ['jpg', 'png', 'gif', 'jpeg', 'tiff', 'psd', 'pdf', 'esp', 'ai', 'indd', 'raw', 'heic', 'heif'];
    let regex: any = /[!@#$%^&*()+\-=\[\]{};':"\\|,.~`<>\/?]+/;
    /**
     * event.currentFiles.length <= 5:-This if condition checks length of the files <= 5
     */
    if (event.currentFiles.length <= 5) {
      let extentionFlag = this.uploadedFiles.every(element => extentions.includes(element.name.includes('.') ? element.name.split('.').pop().toLowerCase() : 'apk'));
      /**
       * extentionFlag:This if condition will check whether uploaded file is valid extention or not
       */
      if (extentionFlag) {
        let isValidFileName = this.uploadedFiles.every(element => !regex.test((element.name.includes('.') && element.name.split('.').length === 2) ? element.name.split('.')[0] : element.name));
        /**
         * isValidFileName:This if condition will check whether uploaded file names has special characters or not except underscore(_)
         */
        if (isValidFileName || this.uploadedFiles.length === 0) {
          let fileSizeFlag = this.uploadedFiles.every(element => element.size <= 10000000);
          /**
           * fileSizeFlag: This if condition will check wheter uploaded file size less than 10MB or not
           */
          if (fileSizeFlag) {
              this.fileUpload();
          } else {
            this.fileValidation = false;
            if (!isRemove) {
              this.observable.showToast(TOAST_STATUSES.ERROR, TOAST_MESSAGES.MAX_FILE_SIZE_ERROR_MSG);
            }
          }
        } else {
          for (let index = event.currentFiles.length - 1; index >= 0; index--) {
            if (event.currentFiles.length && event.currentFiles[index] && event.currentFiles[index].name) {
              if (regex.test((event.currentFiles[index].name.includes('.') && event.currentFiles[index].name.split('.').length === 2) ? event.currentFiles[index].name.split('.')[0] : event.currentFiles[index].name)) {
                uploader.remove(event, index);
              }
            }
          }
          if (!isRemove) {
            this.observable.showToast(TOAST_STATUSES.ERROR, TOAST_MESSAGES.INVALID_FILENAME_ERROR_MSG);
          }
        }
      } else {
        this.fileValidation = false;
        if (!isRemove) {
          this.observable.showToast(TOAST_STATUSES.ERROR, TOAST_MESSAGES.INVALID_EXTENSION_ERROR_MSG);
        }
      }
    } else {
      for (let len = event.currentFiles.length; len > 5; len--) {
        uploader.remove(event, len - 1);
      }
      if (!isRemove) {
        this.observable.showToast(TOAST_STATUSES.ERROR, TOAST_MESSAGES.MAX_FILE_LIMIT_ERROR_MSG);
      }
    }
    if (!isRemove) {
      if (event.files.length) {
        let uploadedFiles = [];
        for (let file of event.files) {
          uploadedFiles.push(file);
        }
        let extentionFlag = uploadedFiles.every(element => extentions.includes(element.name.includes('.') ? element.name.split('.').pop().toLowerCase() : 'apk'));
        if (!extentionFlag) {
          this.observable.showToast(TOAST_STATUSES.ERROR, TOAST_MESSAGES.INVALID_EXTENSION_ERROR_MSG);
        } else if (!uploadedFiles.every(element => element.size <= 10000000)) {
          this.observable.showToast(TOAST_STATUSES.ERROR, TOAST_MESSAGES.MAX_FILE_SIZE_ERROR_MSG);
        }
      }
    }
  }

  /**
   *
   * @param event remove file
   * This function will do removing of files which are added.
   */
  filesRemoved(event, uploader) {
    if (event.file) {
      this.uploadedFiles = this.uploadedFiles.filter(each => each.name !== event.file.name);
    }
    let fileEvent = {
      currentFiles: this.uploadedFiles
    };
    this.onUpload(fileEvent, uploader, true);
  }

  // This funciton will upload the uploaded all files into s3 bucket
  fileUpload() {
    this.fileValidation = true;
    var getUploadUrls;
    var filesData = [];
    let isUpdatedFiles = this.uploadedFiles.every(element => this.documentUrls.some(url => url.indexOf(element.name)));
    if (this.uploadedFiles.length !== this.documentUrls.length || !isUpdatedFiles) {
      this.uploadedFiles.every(element => this.documentUrls.includes(element.name));
      this.uploadedFiles.forEach((element, ind) => {
        let obj = {
          key: 'patientRequestDoc' + ind,
          name: this.accentedtoNormalFun(element.name),
          contentType: element.type || element.name.split('.').pop().toLowerCase()
        };
        filesData.push(obj);
      });
      getUploadUrls = {
        type: 'POST',
        url: 'getUploadUrl',
        isDeveloper: true,
        body: {
          fileNames: filesData,
          userId: this.userDetails.userId,
          adminRequest: true
        },
      };
      this.http.makeHttpRequest(getUploadUrls).subscribe(res => {
        this.documentUrls = [];
        const urls = res;
        if (res && typeof (res) === 'object') {
          for (const key in res) {
            if (Object.prototype.hasOwnProperty.call(res, key)) {
              this.documentUrls.push(res[key]);
            }
          }
        } else {
          this.documentUrls = [];
        }
        console.log(this.documentUrls);
        filesData.forEach(eachFile => {
          let file = this.uploadedFiles.filter(each => this.accentedtoNormalFun(each.name) === eachFile.name);
          let uploadPayload = {
            type: 'PUT',
            url: urls[eachFile.key],
            body: file[0],
            isExternal: true,
            contentType: eachFile.contentType || eachFile.name.split('.').pop().toLowerCase()
          };
          this.http.makeHttpRequest(uploadPayload).subscribe(response => {
            console.log(response);
          });
        });
      });
    }
  }

  /* for accented letter change convention function */
  accentedtoNormalFun(name) {
    let originalText = name;
    let result = originalText.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
    return result;
  }
  // This function will remove all files which are uploaded.
  allFilesCleared(event) {
    console.log(event);
    this.selectedFiles = [];
  }

  // This function will set validation if no documents are selected
  handleDocuments(event, doc) {
    if (event.checked) {
      this.showSelectionError = false;
      // tslint:disable-next-line:no-unused-expression
      doc.documentLabel === 'Others' ? this.showOther = true : null;
    } else {
      doc.documentLabel === 'Others' ? (this.showOther = false, this.showOtherError = false, this.otherValue = '') : null;
      this.remainingValue = 350;
    }
    this.documentsSelected = this.documentsArr.filter(doc => doc.isSelected);
  }

  // This function will do decrement of entered text count
  textCounter(event) {
    console.log(event.target.value.length);
    this.remainingValue = 350 - (event.target.value.length);
    this.showOtherError = false;
  }

  // This function will do decrement of entered text count
  textCounter1(event) {
    console.log(event.target.value.length);
    this.remainingValue1 = 350 - (event.target.value.length);
  }

  // This function will do final submition of form
  submit() {
    if (!this.documentsSelected.length) {
      this.showSelectionError = true;
    } else if (this.showOther && !this.nonEmptyRegex.test(this.otherValue)) {
      this.showOtherError = true;
    } else {
    }
    if(this.isPatient == true){
      if ((this.documentsSelected.length && !this.showOther) || (this.documentsSelected && this.showOther && this.nonEmptyRegex.test(this.otherValue))) {
        if ((this.uploadedFiles && this.uploadedFiles.length && this.fileValidation) || (this.uploadedFiles.length === 0)) {
          let documents = [];
          this.documentsSelected.forEach(doc => {
            documents.push(doc.id);
          });
          const payloadData = {
            type: HTTPMethods.POST,
            url: API_URLS.ADD_PATIENT_REQUEST,
            isDeveloper: true,
            body: {
              patientId: this.patientId,
              documentIds: documents,
              otherComment: this.otherValue,
              patientDocument: this.documentUrls,
            }
          };
          this.http.makeHttpRequest(payloadData).subscribe((res) => {
            if (this.http.isSuccessfulResponse(res)) {
              this.closePopup.emit({ isPopup: false, apiStatus: true });
              this.observable.showToast(TOAST_STATUSES.SUCCESS, TOAST_MESSAGES.REQUEST_SENT_TO_PATIENT_MSG);
            } else {
              this.observable.showToast(TOAST_STATUSES.ERROR, TOAST_MESSAGES.REQUEST_SENT_TO_PATIENT_ERROR_MSG);
            }
          });
        }
  
      }
    }
    // below condition is atleast one document needs select and if other is selected comment must be required
    else{
    if ((this.documentsSelected.length && !this.showOther) || (this.documentsSelected && this.showOther && this.nonEmptyRegex.test(this.otherValue))) {
      if ((this.uploadedFiles && this.uploadedFiles.length && this.fileValidation) || (this.uploadedFiles.length === 0)) {
        let documents = [];
        this.documentsSelected.forEach(doc => {
          documents.push(doc.id);
        });
        const payloadData = {
          type: 'POST',
          url: 'addAdminRequest',
          isDeveloper: true,
          body: {
            patientId: this.patientId,
            documentIds: documents,
            adminComments: this.adminComments,
            otherComment: this.otherValue,
            adminDocument: this.documentUrls,
          }
        };
        this.http.makeHttpRequest(payloadData).subscribe((res) => {
          if (this.http.isSuccessfulResponse(res)) {
            this.closePopup.emit({ isPopup: false, apiStatus: true });
            this.observable.showToast(TOAST_STATUSES.SUCCESS, TOAST_MESSAGES.REQUEST_SENT_TO_PATIENT_MSG);
          } else {
            this.observable.showToast(TOAST_STATUSES.ERROR, TOAST_MESSAGES.REQUEST_SENT_TO_PATIENT_ERROR_MSG);
          }
        });
      }

    }
  }
  }

  // This function is for cancel the popup
  cancel() {
    this.closePopup.emit({ isPopup: false, apiStatus: false });
  }
}
