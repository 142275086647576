/**
 * Route paths come here
 */
export const ROUTE_PATHS = {
  EMPTY: '',
  APPOINTMENT: 'appointment',
  DETAILS: 'details',
  NEW_PATIENT: 'new-patient',
  ADD_MyACCOUNT: 'myaccount',
  MyACCOUNT: 'templates',
  USER_LANDING_PAGE: 'user-landing-page',
  CREATE_PASSWORD: 'create-password',
  ADDING_PATIENT: 'adding-patient',
  APPOINTMENT_SCHEDULE: 'rescheduled-appointment',
  APPOINTMENT_CANCELLED: 'cancelled-appointment',
  APPOINTMENT_PENDING: 'pending-appointment',
  WELCOME_TEMPLATE: 'activate-account',
  PROFILE: 'profile',
  HOME: 'home',
  SOON: 'coming-soon',
  ONBOARD: 'login',
  MIGRATEDUSER: 'login/:tempPassword/:username',
  ACTIVATEDUSER: 'login-activate/:username',
  SERVICES: 'services',
  TREATMENTS: 'treatments',
  EACH_TREATEMENT: 'home',
  ABOUTUS: 'aboutus',
  BLOG: 'blog',
  FAQS: 'faqs',
  Document_request: 'document-request',
  PRIVACY: 'privacy',
  TERMS: 'terms',
  BLOGDETAILS: 'details/:id',
  APPOINTMENT_ID: 'appointemntId',
  PATIENT_ID: 'patientId',
  RESCHEDULE: 'reschedule',
  UPLOADMIGRATE: 'migrate',
  CONTACT_US: 'contact-us',
  ADHD_QUESTIONNAIRE: 'adhd-questionnaire',
  PHQ_QUESTIONNAIRE: 'phq-questionnaire',
  // Subsentence_Consent: 'subsenctence-consent',
  Subsentence_Wrapper:'sub-wrapper-consent',
  // Admin Module
  TMS_FORMS: 'tms-forms',
  // Admin Module
  ADMIN: 'admin',
  APPOINTMENTS: 'appointments',
  UPCOMINGAPPOINTMENTS: 'upcoming-appointments',
  PATIENTS: 'patients',
  LOCATIONS: 'locations',
  INV_TYPE: 'type',
  ID: 'id',
  EMPLOYEE: 'employee',
  EMPLOYEE_CREATION: 'new-employee',
  INVOICES: 'invoices',
  PATIENT_DETAILS: 'patient_details',
  CREAT_PATIENT: 'create-patients',
  NEWAPPOINTMENT: 'new-appointment',
  NOTIFICATIONS: 'notifications',
  CREATE_LOCATION: 'create-locations',
  EDIT_LOCATIONS: 'edit-locations',
  ADMIN_PROFILE: 'admin-profile',
  PROFILE_EDIT: 'edit-admin-profile',
  ADMIN_CHANGE_PWD: 'admin-change-password',
  NOT_GENERATED: 'notgenerated',
  // for patient to upload
  PATIENT_UPLOAD: 'patient-upload/:id',
  REQUESTED_DOCUMENTS: 'requested-documents',
  REQUESTED_DETAILS: 'requested-details',
  REQUEST_DOCUMENT_DETAILS: 'request-document-details',
  // for email tempalting path
  EMAIL_TEMPLATES: 'email-templates',
  INFO_REQUEST_FORM: 'info-form',
  ADMIN_KETAMINE_MONITORING_FORM: 'ketamine-monitoring',
  REQUEST_DOCUMENTS: 'request-documents',
  PATIENT_REQU_DOCUMENTS: 'patient-documents',
  REQUEST_THERAPIST_DETAILS: 'request-therapist-details',
  SEND_MAIL: 'send-mail',
  MEDICATION_FEEDBACK: 'medication-feedback',
  LAB_DOC_EDIT: 'document-edit',
  USER_LIST: 'users',
  INFORMATION_REQUEST: 'info-request',
}
