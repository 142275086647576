import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { ROUTE_PATHS } from '@app/common/constants/routing.constant';
import { DataService } from '@app/common/services/data.service';

@Component({
  selector: 'app-booking-slot',
  templateUrl: './booking-slot.component.html',
  styleUrls: ['./booking-slot.component.scss']
})
export class BookingSlotComponent implements OnInit {
  @Input() timeSlots;
  @Input() metaData;
  appointmentDetailObject = {
    appointmentId: null,
    appointmentTypeId: null,
    locationType: null,
    locationId: null,
    slotId: null,
    timeRange: null,
    selectedSlot: null,
    timeIndex: null
  };
  timeRange = {
    start: '06:30:00',
    end: '11:55:00'
  };
  selectedSlot: any = {
    time: null,
    date: null,
    status: 'selected'
  };
  slotId: any;
  begin: any;
  end: any;
  constructor(private router: Router, private dataService: DataService) { }

  ngOnInit() {
    window.localStorage.removeItem('beforLogin');
    window.localStorage.removeItem('beforLoginTypeData');
    window.localStorage.removeItem('APPOINTMENT_DETAIL');
  }

  navigateLogin() {

    // this.router.navigate(['/login'])
    console.log(this.metaData);
    console.log(this.timeSlots);
    this.selectedSlot.time = this.timeSlots.time;
    this.selectedSlot.date = `${this.metaData.date}T${this.timeSlots.time}`;
    this.appointmentDetailObject.appointmentTypeId = this.metaData.appointmentTypeId;
    this.appointmentDetailObject.locationType = this.metaData.locationId;
    this.appointmentDetailObject.appointmentId = this.metaData.appointmentTypeId;
    this.appointmentDetailObject.locationId = this.getLocationId(this.metaData);
    this.appointmentDetailObject.slotId = this.getSlotId(this.timeSlots.time);
    this.appointmentDetailObject.selectedSlot = this.selectedSlot;
    this.appointmentDetailObject.timeRange = this.getTimeRange();
    this.appointmentDetailObject.timeIndex = this.getTimeIndex();
    this.dataService.setData(this.dataService.dataObject.PRE_APPOINTMENT, this.appointmentDetailObject);
    this.router.navigate([`${ROUTE_PATHS.APPOINTMENT}`]);
  }
  getTimeIndex() {
    let timeIndex;
    let actcualCalendar = this.metaData.slots[0].calendar[0].slots;
    actcualCalendar = actcualCalendar.filter((each) => each.time >= this.timeRange.start && each.time <= this.timeRange.end);
    actcualCalendar.forEach((slot, index) => {
      if (slot.time === this.timeSlots.time) {
        timeIndex = index;
      }
    });
    timeIndex = Math.floor(timeIndex / 9);
    return timeIndex;
  }
  getLocationId(data) {
    if (data && typeof(data) === 'object') {
      let index;
      data.locations.forEach((element, ind) => {
        if (element === data.locationId) {
          index = ind;
        }
      });
      return index;
    }
  }
  getSlotId(time) {
    if ('06:30:00' <= time && time <= '11:55:00') {
      this.slotId = 0;
      this.begin = '06:30:00';
      this.end = '11:55:00';
      return this.slotId;
    } else if ('12:00:00' <= time && time <= '15:55:00') {
      this.slotId = 1;
      this.begin = '12:00:00';
      this.end = '15:55:00';
      return this.slotId;
    } else if ('16:00:00' <= time && time <= '17:55:00') {
      this.slotId = 2;
      this.begin = '16:00:00';
      this.end = '17:55:00';
      return this.slotId;
    } else if ('18:00:00' <= time && time <= '19:55:00') {
      this.slotId = 3;
      this.begin = '18:00:00';
      this.end = '19:55:00';
      return this.slotId;
    } else if ('20:00:00' <= time && time <= '23:30:00') {
      this.slotId = 4;
      this.begin = '20:00:00';
      this.end = '23:30:00';
      return this.slotId;
    }
  }
  getTimeRange() {
    this.timeRange.start = this.begin;
    this.timeRange.end = this.end;
    return this.timeRange;
  }
}
