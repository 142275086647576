import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { CustomFormHandlerService } from '@app/common/services/custom-form-handler.service';
import { ObservableHelperService } from '@app/common/services/observable-helper.service';
import { TOAST_STATUSES } from '@app/common/constants/util.constant';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { ROUTE_PATHS } from '@app/common/constants/routing.constant';
import { UtilsService } from '@app/common/services/utils.service';
import { HttpService } from '@app/common/services/http.service';
import { CognitoService } from '@app/common/services/cognito.service';
import { TOAST_MESSAGES } from '@app/common/constants/toast-messages.constant';
import { AdminCogntioService } from '@app/common/services/admin-cogntio.service';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  form: any;
  @Input() isAppointment: boolean = false;
  @Output() state = new EventEmitter<any>();
  isLogedIn: boolean = false;
  listOfUsers: any;
  previousUrl: string;
  askPassword: boolean;
  passwordForm: any;
  emailVerifycode: any;
  emailVerifyEmail: any;
  emailVerifyPassword: any;
  isAdmin: any;
  isAdminURL: boolean = false;
  constructor(
    private formService: CustomFormHandlerService,
    private observableHelperService: ObservableHelperService,
    private router: Router,
    private httpLocal: HttpService,
    private route: ActivatedRoute,
    private cognitoService: CognitoService,
    private adminCognito: AdminCogntioService,
    private meta: Meta,
    private titleService: Title
  ) {
    this.isAdminURL = router.url.includes('admin');
  }

  ngOnInit() {
    const canonicalLink = document.querySelector('link[rel="canonical"]');
    if (canonicalLink) {
      canonicalLink.setAttribute('href', 'https://newteledoc.com/login');
      this.router.events.subscribe((event) => {
        if (event instanceof NavigationEnd) {
          canonicalLink.setAttribute('href', 'https://newteledoc.com/login');
        }
      });
    }
    this.meta.addTag({ name: 'description', content: "New or returning patients utilize this page to access their account for various purposes including appointment creation, personal information updates, etc."});
    this.meta.updateTag({ name: 'title', content: 'Hello! Welcome Back- New TeleDoc Washington DC Psychiatrist' });
    this.titleService.setTitle('Hello! Welcome Back- New TeleDoc Washington DC Psychiatrist');
    this.previousUrl = this.observableHelperService.getPreviousUrl();
    console.log(this.previousUrl, 'previous Url');
    // this.getUsersList()
    this.route.queryParams.subscribe(queryParams => {
      if (queryParams.code && queryParams.username) {
        this.observableHelperService.toggleThrobber(true);

        this.cognitoService.confirmSignUp(queryParams, () => {
          this.observableHelperService.toggleThrobber(false);

          this.router.navigate([`/login`]);
        });
      }
      if (queryParams.forgotPwdCode && queryParams.username) {
        this.forgotPasswordClicked('fp');
      }
      if (queryParams.emailVerifycode && queryParams.username) {
        this.askPassword = true;
        this.passwordForm = this.formService.initForm(this.formService.formTypes.ONLYEXISTINGPASSWORD);
        this.emailVerifyEmail = queryParams.username.toLowerCase();
        this.emailVerifycode = queryParams.emailVerifycode;

      }


    });
    this.route.params.subscribe(params => {
      console.log(params, 'params in login');
      if (params.tempPassword && params.username) {
        this.askPassword = true;
        this.passwordForm = this.formService.initForm(this.formService.formTypes.ONLYPASSWORD);
        this.emailVerifyEmail = params.username.toLowerCase();
        this.emailVerifyPassword = params.tempPassword;
      } else if (params.username && !params.tempPassword) {
        let code = (location.href.includes('?') && location.href.includes('code')) ? location.href.split('?')[1].split('=')[1] : '';
        this.askPassword = true;
        this.passwordForm = this.formService.initForm(this.formService.formTypes.ONLYPASSWORD);
        this.emailVerifyEmail = params.username.toLowerCase();
        this.emailVerifyPassword = code;
        if (this.emailVerifyPassword === '') {
          let message = 'Verification code is not provided. Please add that';
          this.observableHelperService.showToast(TOAST_STATUSES.ERROR, message);
        }
      }
    });
    console.log(this.listOfUsers);
    this.formService.loginFormInputsConfig.forEach(each => {
      if (each.isShowpassword) {
        each.isShowpassword = false;
        each.type = 'password';
        each.value = '';
      } else if (each.isShowpassword === false) {
        each.type = 'password';
      }
    });
    this.form = this.formService.initForm(this.formService.formTypes.LOGIN);
  }


  submitPassword(event) {
    if (this.emailVerifyPassword) {
      this.observableHelperService.toggleThrobber(true);
      this.cognitoService.SignIn({ emailId: this.emailVerifyEmail, password: this.emailVerifyPassword }, (user) => {

        this.adminCognito.completeNewPassword({ user, password: event.value.password }, () => {
          let payload = {
            type: 'POST',
            url: 'createUser',
            isDeveloper: true,
            body: user.challengeParam.userAttributes
          };
          payload.body.sub = user.username;
          this.httpLocal.makeHttpRequest(payload).subscribe((res) => {
            this.observableHelperService.toggleThrobber(false);
            this.router.navigate([`/login`]);
          });
        });
      }, true);
    } else {

      this.cognitoService.SignIn({ emailId: this.emailVerifyEmail, password: event.value.password }, (user) => {
        this.cognitoService.confirmEmailChange(this.emailVerifycode, () => {
          this.emailVerifycode = '';
          this.emailVerifyEmail = '';
          localStorage.setItem('isLogedIn', JSON.stringify(true));
          let beforeLoginData = JSON.parse(window.localStorage.getItem('beforLogin'));
          let beforeUrl = window.localStorage.getItem('previousUrl');
          // console.log('loginData', beforeLoginData);
          if (beforeLoginData != null) {
            this.router.navigate([`${ROUTE_PATHS.APPOINTMENT}`]);
          } else if (beforeUrl != null) {
            this.router.navigateByUrl(`${beforeUrl}`);
          } else {
            this.router.navigate([`/myaccount`]);
          }

        });
      });
    }

  }




  /**
   * userList Data from local
   */
  getUsersList() {
    let payload = {
      type: 'GET',
      url: 'usersList',
      isLocal: true
    };
    this.httpLocal.makeHttpRequest(payload).subscribe((res) => {
      this.listOfUsers = res;
    });
  }

  registerNewUser() {
    this.form.formGroup.reset();
    this.state.emit({name: 'register'});

  }

  forgotPasswordClicked(event) {
    if (this.form) {
      this.form.formGroup.reset();
    }
    this.state.emit({name: 'forgot_password', path: event});
  }

  btnClickEvent(event) {
    var elem = event.value;
    console.log(elem);
    if (elem) {
      this.observableHelperService.toggleThrobber(true);
      this.cognitoService.signOut(() => {
        window.localStorage.setItem('isLogedIn', JSON.stringify(false));
        this.cognitoService.SignIn(elem, (user) => {
          if (user.attributes['email_verified'] === false) {
            this.observableHelperService.showToast(TOAST_STATUSES.ERROR, TOAST_MESSAGES.VERIFY_EMAIL_MSG);
            this.cognitoService.resendEmaiVerification( (data, errMessage) => {
              if (data) {
              } else {
                this.observableHelperService.showToast(TOAST_STATUSES.ERROR, errMessage);
              }
              this.cognitoService.signOut(() => {
                this.observableHelperService.toggleThrobber(false);
                window.localStorage.clear();
                if (user.attributes['custom:admin'] && Number(user.attributes['custom:admin'])) {
                  this.router.navigate([`${ROUTE_PATHS.ADMIN}/${ROUTE_PATHS.ONBOARD}`]);
                } else {
                  this.router.navigate([`${ROUTE_PATHS.ONBOARD}`]);
                }
              });
            });
          } else {
          this.isAdmin = Number(user.attributes['custom:admin']);
          console.log('User Details', user);
          this.observableHelperService.toggleThrobber(false);
          this.form.formGroup.reset();
          this.form.formInputs.forEach(x => x.blur = false);
          this.toggleScroll();
          let beforeLoginData = JSON.parse(window.localStorage.getItem('beforLogin'));
          let beforeUrl = window.localStorage.getItem('previousUrl');
          console.log('loginData', beforeLoginData);
          if (this.isAdmin) {
            this.router.navigate([`${ROUTE_PATHS.ADMIN}`]);
            this.lastActivityAPIcall();
          } else if (!this.isAdmin && ((beforeLoginData != null && beforeLoginData === true))) {
            this.router.navigate([`${ROUTE_PATHS.APPOINTMENT}`]);
          } else if (beforeUrl != null) {
            this.router.navigateByUrl(`${beforeUrl}`);
          } else {
            this.router.navigate([`/myaccount`]);
          }
          window.localStorage.setItem('isLogedIn', JSON.stringify(true));
          this.observableHelperService.setLogin(true);
          event.reset();
          window.scroll({
            top: 0,
            left: 0,
            behavior: 'smooth'
          });
        }
        });
      });
    }
  }
  toggleScroll() {
    document.body.style.overflow = 'scroll';
  }

  lastActivityAPIcall() {
    let payload = {
      type: 'GET',
      url: 'lastActivity',
      isDeveloper: true,
    };
    this.httpLocal.makeHttpRequest(payload).subscribe((res) => {
      if (this.httpLocal.isSuccessfulResponse(res)) {
        console.log(res);
      } else {
        let message = 'Fetching last activity data got failed . Please try again';
        message = (res.error) ? res.error.message : message;
        this.observableHelperService.showToast(TOAST_STATUSES.ERROR, message);
      }
    });
  }
}
