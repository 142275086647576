import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-ketamine-card',
  templateUrl: './ketamine-card.component.html',
  styleUrls: ['./ketamine-card.component.scss']
})
export class KetamineCardComponent implements OnInit {

  @Input() ketamineData = {
    ketamineClass: 'ketamine__card-block',
    ketamineHeading: 'Ketamine Treatment',
    ketamineDescription: 'A new groundbreaking therapy, found to be a rapid-acting antidepressant in depression & helps in decreasing suicidal ideation.',
    ketamineIcon: 'ketamine'
  };

  constructor() { }

  ngOnInit() {
  }

}
