import { Component, OnDestroy, OnInit } from '@angular/core'
import { ActivatedRoute } from '@angular/router'

@Component({
  selector: 'app-generate-pdf',
  templateUrl: './generate-pdf.component.html',
  styleUrls: ['./generate-pdf.component.scss'],
})
export class GeneratePdfComponent implements OnInit, OnDestroy {

  patientName: string = '';
  constructor(
    private route: ActivatedRoute
  ) {
    console.log('1')
    route.queryParams.subscribe((res)=>{
      console.log('params', res)
      this.patientName = res.name;
    })

    // let data = JSON.parse(localStorage.getItem('patientData'));
    // console.log(data)
  }

  ngOnInit() {}

  ngOnDestroy() {
    localStorage.removeItem('patientData');
  }
}
