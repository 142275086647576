import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-know-more',
  templateUrl: './know-more.component.html',
  styleUrls: ['./know-more.component.scss']
})
export class KnowMoreComponent implements OnInit {

  @Input() moreText;
  @Input() link;
  @Input() show: boolean = true;
  @Input() actionBased: boolean = false;

  @Output() actionTriggered: EventEmitter<any> = new EventEmitter();
  @Output() navigateBackPage: EventEmitter<any> = new EventEmitter();
  urlLink: any;
  constructor() { }

  ngOnInit() {
  }

  actionClicked() {
   this.actionTriggered.emit(true);
  }
  navigatePage() {
    this.actionTriggered.emit(true);
  }
  navigateBack() {
    this.navigateBackPage.emit(true);
  }
}
