import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { setTimeout } from 'timers';
import { ObservableHelperService } from '@app/common/services/observable-helper.service';
import { DataService } from '@app/common/services/data.service';
import { HttpService } from '@app/common/services/http.service';
import { CognitoService } from '@app/common/services/cognito.service';
import { Router } from '@angular/router';
import { convertDateToSpecifiedOffset, DEFAULT_TIME_ZONE_DIFF, TOAST_STATUSES } from '@app/common/constants/util.constant';
import { debounceTime, distinctUntilChanged, filter } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-appointment-detail',
  templateUrl: './appointment-detail.component.html',
  styleUrls: ['./appointment-detail.component.scss']
})
export class AppointmentDetailComponent implements OnInit {
  @Input() patientsList;
  @Input() patientTotalLocations;
  isLanding: boolean = true;
  cardData: any;
  pastIndex: any;
  activeIndex: any;
  userIndex: string;
  rowGroupMetadata: {};
  setTime: boolean = false;
  toastData: any = {
    visibility: false,
    svg: ''
  };
  apptStatus: string;
  view = 'View More';
  displayListCount = 2;
  appointmentList: any;
  userDetails: any = {};
  currentDate: any;
  appointmentTime: string;
  upComingAppointments: any = [];
  pastAppointments: any = [];
  isViewMore: boolean = false;
  isShow: boolean = false;
  // change the value subject
  scroll: Subject<any> = new Subject<any>();
  noteMessage:string = 'Any Pharmacy, demographic, insurance updates should be made online only. Any email updates will not be taken care.'
  @Output() callPatientApi: EventEmitter<any> = new EventEmitter();
  constructor(
    private observable: ObservableHelperService,
    private data: DataService,
    private http: HttpService,
    private cognitoService: CognitoService,
    private router: Router
  ) {
    this.cardData = data.cardData;
    this.scroll
      .pipe(debounceTime(2000))
      .pipe(distinctUntilChanged())
      .subscribe(newValue => {
        let commonHeight = $('.common__header--section').outerHeight();
        let upcomingApp = $('.upcoming').offset().top - (commonHeight + 40);
        $('html,body').animate({ scrollTop: upcomingApp }, 2000, () => $('html,body').stop(true, false));
      });
  }

  ngAfterViewChecked(){
    let message = document.getElementById("message");
    let messageM = document.getElementById("message-m");
    if(message){
      window.setTimeout(() => {
        message.classList.add('expand')
        messageM.classList.add('expand-m')
      }, 10);
    }
  }

  ngOnInit() {
    this.currentDate = new Date(convertDateToSpecifiedOffset(DEFAULT_TIME_ZONE_DIFF));
    this.cognitoService.getUser().then(user => {
      this.userDetails = user;
      this.getAppoinmnetsList(user.userId);
      this.observable.getConfirmationShown().subscribe((data: any = { visibility: false }) => {
        this.toastData = this.addSvgs(data);
        // console.log('toastData', this.toastData);
      });
    });
  }
  viewMore(list) {
    this.isViewMore = !this.isViewMore;
    if (this.isViewMore) {
      this.view = 'View Less';
      this.displayListCount = list.length;
    } else {
      this.view = 'View More';
      this.displayListCount = 2;
    }
  }
  getAppoinmnetsList(userId) {
    let payload = {
      type: 'GET',
      url: 'getAppointment',
      isDeveloper: true,
      pathVariables: [userId]
    };
    this.http.makeHttpRequest(payload).subscribe((res) => {
      if (this.http.isSuccessfulResponse(res) && res) {
        // console.log(res.data);
        // this.appointmentList = res.data;
        this.appointmentList = this.convertPastAppointments(res.data);
        this.findUpcomingAppointments(this.appointmentList);
        this.sortUpcomingAppointments();
        this.sortRecentAppointments();
        console.log(this.upComingAppointments);
      } else {
        let message = 'Failed to fetch an appointment data. Please try again';
        message = (res.error) ? res.error.message : message;
        this.observable.showToast(TOAST_STATUSES.ERROR, message);
      }
      this.isShow = true;
      if (this.toastData.status === 'rescheduledConfirm') {
          this.scroll.next(payload);
        }
    });
  }


  // this function is used for ascending order of past appointments
  sortRecentAppointments() {
    this.pastAppointments = this.pastAppointments.sort((a, b) => {
      var aDate = a.appointmentDate;
      var bDate = b.appointmentDate;
      var aTime = a.appointmentTime;
      var bTime = b.appointmentTime;
      // console.log(aDate, bDate, aTime, bTime);
      if (aDate === bDate) {
        return (aTime > bTime) ? -1 : (aTime < bTime) ? 1 : 0;
      } else {
        return (aDate > bDate) ? -1 : 1;
      }
    });
  }

  // this function is used for descending order of upcoming appointments
  sortUpcomingAppointments() {
    this.upComingAppointments = this.upComingAppointments.sort((a, b) => {
      var aDate = a.appointmentDate;
      var bDate = b.appointmentDate;
      var aTime = a.appointmentTime;
      var bTime = b.appointmentTime;
      console.log(aTime + ' | ' + bTime);
      if (aDate === bDate) {
        return (aTime < bTime) ? -1 : (aTime > bTime) ? 1 : 0;
      } else {
        return (aDate < bDate) ? -1 : 1;
      }
    });
  }
  redirectingToBookAppointment() {
    this.router.navigate(['/appointment']);
  }
  findUpcomingAppointments(list) {
    this.upComingAppointments = list.filter(each => (each.appointmentStatus === 'A' || each.appointmentStatus === 'P' || each.appointmentStatus === 'N')); // A: active appointment P: Pending appointments N:Pending Payment
    this.pastAppointments = list.filter(each => (each.appointmentStatus === 'R' || each.appointmentStatus === 'C')); // R:Recent appointments C:completed appointments
  }
  convertPastAppointments(appointments) {
    if (!appointments && !Array.isArray(appointments)) { return []; }
    return appointments.map(each => {
      let appointmentDate = `${each.appointmentDate}`.split('-').join('/');
      // console.log(appointmentDate)
      let appointmentTime: any = new Date(`${appointmentDate} ${each.appointmentTime}`);
      appointmentTime = new Date(appointmentTime.getTime());
      let currentDate = new Date(convertDateToSpecifiedOffset(DEFAULT_TIME_ZONE_DIFF, each.timestamp));
      // console.log(appointmentTime ,'-', this.currentDate);
      if (each.appointmentStatus === 'A' ||  each.appointmentStatus === 'P') {
        if (appointmentTime < currentDate) {
          return {
            ...each,
            appointmentStatus: 'R' // Recent appointments
          };
        } else {
          return {
            ...each,
          };
        }
      } else {
        return {
          ...each,
        };
      }
    });
  }
  addSvgs(e) {
    let svgIcon;
    if (e.status === 'booked') {
      svgIcon = 'appointmet-confirmed';
    } else if (e.status === 'rescheduled' || e.status === 'rescheduledConfirm') {
      svgIcon = 'reschedule';
      // tslint:disable-next-line:no-unused-expression
      e.status === 'rescheduledConfirm' ? this.apptStatus = 'rescheduledConfirm' : null;
    } else if (e.status === 'cancelled') {
      svgIcon = 'appoint-cancelled';
    }
    return {
      ...e, svg: svgIcon
    };
  }

  redirectingToAddPatientForm() {
    this.observable.setAddPatientClicked(true);
    this.router.navigate(['/new-patient'], { queryParams: { newForm: true } });
  }

  cancelAppointment(event) {
    if (event) {
      let status = event.status;
      let appointmentId = event.appointmentId;
      if (status.removeUser) {
        this.getAppoinmnetsList(this.userDetails.userId);
        this.observable.showConfirmation('cancelled', 'Your appointment has been cancelled!');
        this.updateRowGroupMetaData();
        this.callPatientApi.emit({ status:true, patId: this.userDetails.userId });
      }
    }
  }

  // Row Grouping funcitionality by status
  updateRowGroupMetaData() {
    this.rowGroupMetadata = {};
    if (this.appointmentList) {
      for (let i = 0; i < this.appointmentList.length; i++) {
        let rowData = this.appointmentList[i];
        let appointmentStatus = rowData.appointmentStatus;
        if (i === 0) {
          this.rowGroupMetadata[appointmentStatus] = { index: 0, size: 1, appointmentStatus };
        } else {
          let previousRowData = this.appointmentList[i - 1];
          let previousRowGroup = previousRowData.appointmentStatus;
          if (appointmentStatus === previousRowGroup) {
            this.rowGroupMetadata[appointmentStatus].size++;
          } else {
            this.rowGroupMetadata[appointmentStatus] = { index: i, size: 1, appointmentStatus };
          }
        }
      }
    }
    console.log(this.rowGroupMetadata);
  }
}
