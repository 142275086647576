import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { CustomFormHandlerService } from '@app/common/services/custom-form-handler.service';

@Component({
  selector: 'app-common-popup',
  templateUrl: './common-popup.component.html',
  styleUrls: ['./common-popup.component.scss']
})
export class CommonPopupComponent implements OnInit {

  @Output() showHideCancelPopup = new EventEmitter();
  showHideCancelPopupStatus: boolean = true;


  constructor(private formService: CustomFormHandlerService) { }

  ngOnInit() {
  }

  showHideCancelPopupStatusChange() {
    this.showHideCancelPopupStatus = !this.showHideCancelPopupStatus;
    this.showHideCancelPopup.emit({cancelpopup: this.showHideCancelPopupStatus});
}

}
