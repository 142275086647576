import { Component, OnInit } from '@angular/core';
import { CustomFormHandlerService } from '@app/common/services/custom-form-handler.service';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpService } from '@app/common/services/http.service';
import { ObservableHelperService } from '@app/common/services/observable-helper.service';
import { TOAST_STATUSES, HTTPMethods } from '@app/common/constants/util.constant';
import { MEDICAL_SUMMARY_FORM } from '@app/common/constants/form-keys.constant';
import { CustomFormComponent } from '../custom-form/custom-form.component';
import { FormBuilder } from '@angular/forms';
import { CognitoService } from '@app/common/services/cognito.service';
import { API_URLS } from '@app/common/constants/api-urls.constant';
import { TOAST_MESSAGES } from '@app/common/constants/toast-messages.constant';
import { ROUTE_PATHS } from '@app/common/constants/routing.constant';
import { cloneDeep } from 'lodash';

@Component({
  selector: 'app-medical-summary-form',
  templateUrl: './medical-summary-form.component.html',
  styleUrls: ['./medical-summary-form.component.scss']
})
export class MedicalSummaryFormComponent implements OnInit {

  formData: any;
  saveFormData: boolean = false;
  patientId: any = ''
  userdata: any;
  childComponent = new CustomFormComponent(this.formService, this.formBuilder, this.http, this.cognitoService, this.observable, this.route, this.router);
  backUpForm : any = [];

  constructor(private formService: CustomFormHandlerService, private route: ActivatedRoute, private http: HttpService, private observable: ObservableHelperService,
    private formBuilder: FormBuilder, private cognitoService: CognitoService, private router: Router
    ) {
    route.queryParams.subscribe((res)=>{
      this.patientId = res.id;
    })
   }

  ngOnInit() {
    this.backUpForm = []
    this.formData = this.formService.initForm(this.formService.formTypes.MEDICAL_SUMMARY);
    this.formData.formInputs.forEach(ele => {
      /* following code makes deep copy of the formInputs and formArrays to backup */
      let obj = cloneDeep(ele);
      for (let key in ele) {
        if (Array.isArray(ele[key])) {
          obj[key] = [...ele[key]];
        }
      }
      this.backUpForm.push(cloneDeep(obj));
    });
    this.getPatientDetails();
  }

  getFormData(data: any){
    let patSurvey = []
    this.formService.medicalSummaryFormConfig.forEach((element: any, id: any) => {
      if(element.key && element.key.includes(MEDICAL_SUMMARY_FORM.QUERY)){
        patSurvey.push({
          "question": element.label ? element.label : element.checkboxlabel,
          "response": element.controlType == 'checkbox-slider' ? (!element.value).toString() : element.value
        })
      }
    })
    let body = {
      "patientId": this.patientId,
      "gender": data.value.gender,
      "pcp": data.value.pcp,
      "address": data.value.address,
      "activeMedications": this.getMedications(data.value.medication),
      "officeVisit": {
        "subjective": data.value.subjective.split('\n').filter((ele: any) => ele.trim() != ''),
        "medicalHistory": {
          "allergies": data.value.allergies,
        },
        "healthBackground": {
          "socialData": {
            "occupation": data.value.occupation,
            "tobacco": data.value.tobacco,
            "alcohol": data.value.alcohol
          },
          "exam": {
            "general": data.value.general,
            "ms": data.value.ms,
            "psych": data.value.psych
          },
          "assessmentAndPlan": data.value.assessmentAndPlan
        }
      },
      "patientSurvey": patSurvey
    }
    if(data.valid){
      this.updateMedicalSummary(body)
    }
  }

  /**
   * Update medical summary.
   */
  updateMedicalSummary(data) {
    let payload: any = {
      type: HTTPMethods.POST,
      url: API_URLS.MEDICAL_SUMMARY,
      isDeveloper: true,
      body: data,
    }
    this.http.makeHttpRequest(payload).subscribe(res => {
      if (this.http.isSuccessfulResponse(res)) {
        this.formData.formGroup.reset();
        this.formService.medicalSummaryFormConfig = this.backUpForm;
        this.observable.showToast(TOAST_STATUSES.SUCCESS, TOAST_MESSAGES.PAYMENT_RECIEPTS_SUC)
        this.back();
      } else {
        let message = TOAST_MESSAGES.PAYMENT_RECIEPTS_FAIL
        message = res.error ? res.error.message : message
        this.observable.showToast(TOAST_STATUSES.ERROR, message)
      }
    })
  }

  getMedications(list: any){
    let medications = [];
    if(!list.length) return [];
    list.forEach((element: any) => {
      medications.push({
          "medication": element.medicine,
          "description": element.dosage
        })
    });
    return medications;
  }
  resetSaveFormParity() {
    window.setTimeout(() => {
      this.saveFormData = false;
    }, 0);
    return true;
  }

  back(){
    this.formData.formGroup.reset();
    this.formService.medicalSummaryFormConfig = this.backUpForm;
    this.router.navigate([ROUTE_PATHS.ADMIN, ROUTE_PATHS.PATIENTS]);
  }

  saveForm() {
    setTimeout(() => {
      if (document.getElementsByClassName('invalid-feedback')[0]) {
        document.getElementsByClassName('invalid-feedback')[0].scrollIntoView({ block: 'center' });
      }
    });
    this.saveFormData = true;
  }

  getPatientDetails() {
    const payloadData = {
      type: 'GET',
      url: 'patientsDetails',
      isDeveloper: true,
      pathVariables: [this.patientId],
    };
    this.http.makeHttpRequest(payloadData).subscribe((res) => {
      if (this.http.isSuccessfulResponse(res)) {
        this.userdata = res.data;
        this.assignData();
      } else {
        let message = 'Failed to fetch patient details. Please try again';
        message = (res.error) ? res.error.message : message;
        this.observable.showToast(TOAST_STATUSES.ERROR, message);
      }
    });
  }

  /**
   * Populate data.
   */
  assignData(){
    this.formService.medicalSummaryFormConfig[0].value = this.userdata.details.firstName + ' ' + this.userdata.details.lastName
    this.formService.medicalSummaryFormConfig[1].value = this.userdata.details.dob
    this.formService.medicalSummaryFormConfig[2].value = this.userdata.details.gender == 'm' ? 'Male' : 'Female';
    this.formService.medicalSummaryFormConfig[4].value = this.userdata.details.patientBasicInfo.streetAddress.city + '\n' + this.userdata.details.patientBasicInfo.streetAddress.phoneNumber + '\n' +
    this.userdata.details.patientBasicInfo.streetAddress.state + '\n' + this.userdata.details.patientBasicInfo.streetAddress.streetAddress1 + '\n' + this.userdata.details.patientBasicInfo.streetAddress.zipCode
    this.formService.medicalSummaryFormConfig.forEach((element: any, id: any) => {
      if(element.key == MEDICAL_SUMMARY_FORM.ALLERGIES){
        element.value = this.userdata.details.medicalHistory.knownAllergies
      }
      let inputs = this.formData.formInputs;
      if (element.controlType === 'formArray') {
        if (this.userdata.details.medicalHistory.psychiatric.medications && Array.isArray(this.userdata.details.medicalHistory.psychiatric.medications)) {
          let data = this.userdata.details.medicalHistory.psychiatric.medications;
          if (data.length > 1) {
            for (let i = 0; i < data.length - 1; i++) {
              this.childComponent.addNew(inputs[id + 1], inputs[id + 1].formtag);
            }
          }
          let formInputs = element.formInputs;
          formInputs.forEach((innerElement, ind) => {
            innerElement.forEach((innerObj) => {
              innerObj.value = data[ind][innerObj.key];
              innerObj.editable = true;
            });
          });
          element.formInputs = formInputs;
        }
      }
    })
    this.formData = {...this.formData}
  }
}
